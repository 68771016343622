import React from 'react';
import * as Logger from 'loglevel';
import { Navbar, Dropdown, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCaretDown, faClock, faInbox } from '@fortawesome/free-solid-svg-icons';
import authService from '../../api-authorization/AuthorizeService';
import { ApplicationPaths } from '../../api-authorization/ApiAuthorizationConstants';
import { UtilConstant } from 'hub-utilities'
import 'styles/sections/TopNavigation.scss';
import MyClock from '../libraries/MyClock';
import * as HubConstant from 'util/HubConstant';

//import FPSStats from 'react-fps-stats';

class TopNav extends React.Component {
    constructor() {
        super();
        this.state = {
            showPopup: false,
            isDropdownOpen: false,
            isClockHourOneDigit: false, //to avoid the clock from trembling as diff text has diff size
            //bouncing ball anim:
            isBouncing: false,
            isSmallScreenSize: false
        };
    }

    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
    }

    resize() {
        if (window.innerWidth <= 1007 && !this.state.isSmallScreenSize) {
            this.setState({ isSmallScreenSize: true });
        } else if (window.innerWidth > 1007 && this.state.isSmallScreenSize) {
            this.setState({ isSmallScreenSize: false });
        }
    }

    componentWillReceiveProps(newProps) {
        if (this.props.bounceNotifier !== newProps.bounceNotifier) {
            this.setState({ isBouncing: true });
        }
    }

    toggleDropdown = (isOpen) => {
        this.setState({ isDropdownOpen: isOpen });
    }

    setIsClockHourOneDigit = (isOneDigit) => {
        this.setState({ isClockHourOneDigit: isOneDigit });
    }

    onLogOut = () => {
        Logger.debug('TopNavigation.onlogout, this.props=', this.props);
        const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
        this.props.history.push(logoutPath);
    }

    displayUserAuthority() {
        switch (authService.getUserType()) {
            case UtilConstant.HUB_USER_TYPE.HUB_USER:
                return "Normal User";
            case UtilConstant.HUB_USER_TYPE.HUB_NODE_ADMIN:
                return `${authService.getHubNodeName(authService.getNodeIdByUserGuid(authService.getUserGuid()))} Admin`;
            case UtilConstant.HUB_USER_TYPE.HUB_ADMIN:
                return "System Admin";
            default:
                return "";
        }
    }

    isTouchDevice = () => {
        try {
            document.createEvent("TouchEvent");
            return true;
        } catch (e) {
            return false;
        }
    }

    render() {
        return (
            <div className="TopNav">
                <Navbar bg="dark" variant="dark">
                    <div className="activeMainContent">{!this.state.isSmallScreenSize ? this.props.activePageName.plural :
                        <OverlayTrigger key="timeDisplay" placement="bottom" overlay={<Tooltip>{this.props.activePageName.plural}</Tooltip>}>
                            <FontAwesomeIcon icon={this.props.activePageName.icon} />
                        </OverlayTrigger>
                    }</div>
                    {/* <FPSStats /> */}
                    {
                        !!this.props.arrOfNewData && this.props.arrOfNewData.length > 0 ?
                            <OverlayTrigger key={`NewContentBadge`} placement="bottom" overlay={<Tooltip className="rowActionTooltip">
                                Load {!!this.props.arrOfNewData ? this.props.arrOfNewData.length : ""} More Recent {
                                    !!this.props.arrOfNewData && this.props.arrOfNewData.length === 1 ? this.props.activePageName.singular : this.props.activePageName.plural
                                }
                            </Tooltip>}>
                                <div
                                    className={`NewContentBadge`}
                                    onClick={this.props.toggleClickNotifier}
                                    onAnimationEnd={() => this.setState({ isBouncing: false })}>
                                    <FontAwesomeIcon className="fa" icon={faInbox}></FontAwesomeIcon>
                                    <div className={`NewContentNum ${this.state.isBouncing ? 'Bounce' : ""}`}>
                                        {this.props.arrOfNewData ? this.props.arrOfNewData.length : ""}
                                    </div>
                                </div>
                            </OverlayTrigger>
                            :
                            <div className="NewContentBadge disabled">
                                <FontAwesomeIcon className="fa disabled" icon={faInbox}></FontAwesomeIcon>
                            </div>
                    }
                    <div className={`topRight  ${this.state.isClockHourOneDigit ? "oneDigit" : "twoDigits"}`}>
                        <div className="fa-container">
                            <Dropdown onPointerEnter={() => { if (!this.isTouchDevice()) this.toggleDropdown(true) }}
                                onPointerLeave={() => { if (!this.isTouchDevice()) this.toggleDropdown(false) }}
                                onClick={() => { if (this.isTouchDevice()) this.toggleDropdown(!this.state.isDropdownOpen) }}
                                show={this.state.isDropdownOpen}>
                                <div className={`userDropdownToggle ${this.state.isClockHourOneDigit ? "oneDigit" : "twoDigits"}`}>
                                    <FontAwesomeIcon className="fa" icon={faUser}></FontAwesomeIcon>
                                    <div className="userText">
                                        <div className="hubUsernameDisplay">{authService.getCurrentHubUsername()}</div>
                                        <div className="authorityDisplay">{this.displayUserAuthority()}</div>
                                    </div>
                                    <FontAwesomeIcon className="fa" icon={faCaretDown}></FontAwesomeIcon>
                                </div>
                                <Dropdown.Menu className="userDropdown">
                                    <Dropdown.Item onClick={this.onLogOut}>Logout</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <OverlayTrigger key="timeDisplay" placement="bottom" overlay={<Tooltip>Your local time ({
                             <MyClock shouldDisplayClock={false} shouldDisplayText isFullDateFormat setIsClockHourOneDigit={this.setIsClockHourOneDigit}></MyClock>} {new Date().toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2]}
                        )</Tooltip>}>
                            <div className="ClockText"><MyClock shouldDisplayClock withSeconds setIsClockHourOneDigit={this.setIsClockHourOneDigit} shouldDisplayText={!this.state.isSmallScreenSize}></MyClock></div>
                        </OverlayTrigger>
                    </div>
                </Navbar>
            </div>
        );
    }
}

export default TopNav;
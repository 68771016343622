import * as Logger from 'loglevel';
import hubApi from 'util/HubApi';
import * as ActionType from '../ActionType'
import authService from 'components/api-authorization/AuthorizeService';
import * as HubConstant from "util/HubConstant"
import { DataTableConstant } from 'hub-dashboard-framework'
import { UtilConstant } from 'hub-utilities'

//dispatcher and actions here:
//To sync logo between About and Side Navbar


const FetchType = Object.freeze({
    NODES: 0,
    NODE_GROUP: 1,
    NODE_GROUP_NODES: 2,
    NEWSITEM_METADATA: 3,
    NEWSITEM_SUBOBJECT: 4
})

const apiFetch = (method, fetchType, isTable, ids, body, canAffectLoadingScreen) => ({
    type: ActionType.API_FETCH,
    payload: {
        url: fetchType === FetchType.NODES ? "api/node/1/search/newsitem" :
             fetchType === FetchType.NODE_GROUP ? `/api/NodeGroup${method === "PUT" || method === "DELETE" ? `/${ids.nodeGroupId}` : ""}` :
             fetchType === FetchType.NODE_GROUP_NODES ? `/api/NodeGroup/${ids.nodeGroupId}/node${method !== "GET" ? `/${ids.nodeId}` : ""}` :
             fetchType === FetchType.NEWSITEM_METADATA ? `/api/NewsItem/${ids.newsItemId}/metadata` :  
             fetchType === FetchType.NEWSITEM_SUBOBJECT ? `/api/NewsItem/${ids.newsItemId}` :  
             '',

        method: method,
        body: body,
        token: authService.getAccessToken(),
        responseType: UtilConstant.HUB_API_RESPONSE_TYPE.JSON,

        begin: fetchType === FetchType.NODES ? setNodeFilterBegin :
               fetchType === FetchType.NODE_GROUP ? method === "GET" ? getNodeGroupBegin : method === "POST" ? addNodeGroupBegin : method === "PUT" ? setNodeGroupBegin : deleteNodeGroupBegin :
               fetchType === FetchType.NODE_GROUP_NODES ? method === "GET" ? getNodeGroupNodesBegin : method === "POST" ? addNodeGroupNodesBegin : deleteNodeGroupNodesBegin :
               fetchType === FetchType.NEWSITEM_METADATA ? getMetadataToNewsItemBegin : 
               fetchType === FetchType.NEWSITEM_SUBOBJECT ? getNewsItemSubObjectBegin : 
               null,
        success: fetchType === FetchType.NODES ? setNodeFilterSuccess :
                 fetchType === FetchType.NODE_GROUP ? method === "GET" ? getNodeGroupSuccess : method === "POST" ? addNodeGroupSuccess : method === "PUT" ? setNodeGroupSuccess : deleteNodeGroupSuccess :
                 fetchType === FetchType.NODE_GROUP_NODES ? method === "GET" ? getNodeGroupNodesSuccess : method === "POST" ? addNodeGroupNodesSuccess : addNodeGroupNodesFailure :
                 fetchType === FetchType.NEWSITEM_METADATA ? getMetadataToNewsItemSuccess : 
                 fetchType === FetchType.NEWSITEM_SUBOBJECT ? getNewsItemSubObjectSuccess : 
                 null,
        error: fetchType === FetchType.NODES ? setNodeFilterFailure :
               fetchType === FetchType.NODE_GROUP ? method === "GET" ? getNodeGroupFailure : method === "POST" ? addNodeGroupFailure : method === "PUT" ? setNodeGroupFailure : deleteNodeGroupFailure :
               fetchType === FetchType.NODE_GROUP_NODES ? method === "GET" ? getNodeGroupNodesFailure : method === "POST" ? deleteNodeGroupNodesSuccess : deleteNodeGroupNodesFailure :
               fetchType === FetchType.NEWSITEM_METADATA ? getMetadataToNewsItemFailure : 
               fetchType === FetchType.NEWSITEM_SUBOBJECT ? getNewsItemSubObjectFailure : 
               null,
        additionalParams: { ...(!!ids && !!ids.nodeGroupId && { id: ids.nodeGroupId }), 
                            ...(!!ids && !!ids.newsItemId && { newsItemId: ids.newsItemId }),
                            ...(isTable !== undefined && { isTable: isTable }),
                            ...(canAffectLoadingScreen !== undefined && { canAffectLoadingScreen: canAffectLoadingScreen })}
    }
});

const fetchNodeContentsBegin = (objectType) => ({
    type: ActionType.FETCH_NODE_CONTENTS_BEGIN,
    payload: objectType
});

const fetchNodeContentsSuccess = payload => ({
    type: ActionType.FETCH_NODE_CONTENTS_SUCCESS,
    payload: payload
});

const fetchNodeContentsFailure = error => ({
    type: ActionType.FETCH_NODE_CONTENTS_FAILURE,
    payload: error
});

const setNodeFilterBegin = (objectType) => ({
    type: ActionType.SET_HUB_NODE_FILTER_BEGIN,
    payload: objectType
});

const setNodeFilterSuccess = payload => ({
    type: ActionType.SET_HUB_NODE_FILTER_SUCCESS,
    payload: payload
});

const setNodeFilterFailure = error => ({
    type: ActionType.SET_HUB_NODE_FILTER_FAILURE,
    payload: error
});

const getNodeGroupBegin = (additionalParams) => ({
    type: ActionType.GET_HUB_NODE_GROUP_BEGIN,
    isTable: additionalParams.isTable
});

const getNodeGroupSuccess = (payload, additionalParams) => ({
    type: ActionType.GET_HUB_NODE_GROUP_SUCCESS,
    payload: payload,
    canAffectLoadingScreen: additionalParams.canAffectLoadingScreen
});

const getNodeGroupFailure = (error, additionalParams) => ({
    type: ActionType.GET_HUB_NODE_GROUP_FAILURE,
    payload: error,
    canAffectLoadingScreen: additionalParams.canAffectLoadingScreen
});

const addNodeGroupBegin = () => ({
    type: ActionType.ADD_HUB_NODE_GROUP_BEGIN
});

const addNodeGroupSuccess = (payload) => ({
    type: ActionType.ADD_HUB_NODE_GROUP_SUCCESS,
    payload: payload
});

const addNodeGroupFailure = error => ({
    type: ActionType.ADD_HUB_NODE_GROUP_FAILURE,
    payload: error
});

const setNodeGroupBegin = () => ({
    type: ActionType.SET_HUB_NODE_GROUP_BEGIN
});

const setNodeGroupSuccess = (payload) => ({
    type: ActionType.SET_HUB_NODE_GROUP_SUCCESS,
    payload: payload
});

const setNodeGroupFailure = error => ({
    type: ActionType.SET_HUB_NODE_GROUP_FAILURE,
    payload: error
});

const deleteNodeGroupBegin = () => ({
    type: ActionType.DELETE_HUB_NODE_GROUP_BEGIN
});

const deleteNodeGroupSuccess = (payload) => ({
    type: ActionType.DELETE_HUB_NODE_GROUP_SUCCESS,
    payload: payload
});

const deleteNodeGroupFailure = (error) => ({
    type: ActionType.DELETE_HUB_NODE_GROUP_FAILURE,
    payload: error,
});

const getNodeGroupNodesBegin = (objectType) => ({
    type: ActionType.GET_HUB_NODE_GROUP_NODES_BEGIN,
    payload: objectType,
});

const getNodeGroupNodesSuccess = (payload, additionalParams) => ({
    type: ActionType.GET_HUB_NODE_GROUP_NODES_SUCCESS,
    payload: payload,
    nodeGroupId: additionalParams.id,
    isTable: additionalParams.isTable,
    canAffectLoadingScreen: additionalParams.canAffectLoadingScreen
});

const getNodeGroupNodesFailure = (error, additionalParams) => ({
    type: ActionType.GET_HUB_NODE_GROUP_NODES_FAILURE,
    payload: error,
    isTable: additionalParams.isTable,
    canAffectLoadingScreen: additionalParams.canAffectLoadingScreen
});

const addNodeGroupNodesBegin = () => ({
    type: ActionType.ADD_HUB_NODE_GROUP_NODES_BEGIN
});

const addNodeGroupNodesSuccess = (payload) => ({
    type: ActionType.ADD_HUB_NODE_GROUP_NODES_SUCCESS,
    payload: payload
});

const addNodeGroupNodesFailure = (error) => ({
    type: ActionType.ADD_HUB_NODE_GROUP_NODES_FAILURE,
    payload: error
});

const deleteNodeGroupNodesBegin = () => ({
    type: ActionType.DELETE_HUB_NODE_GROUP_NODES_BEGIN
});

const deleteNodeGroupNodesSuccess = (payload) => ({
    type: ActionType.DELETE_HUB_NODE_GROUP_NODES_SUCCESS,
    payload: payload
});

const deleteNodeGroupNodesFailure = (error) => ({
    type: ActionType.DELETE_HUB_NODE_GROUP_NODES_FAILURE,
    payload: error,
});

const editNodeGroup = () => ({
    type: ActionType.EDIT_NODE_GROUP
});

const getMetadataToNewsItemBegin = () => ({
    type: ActionType.FETCH_METADATA_TO_NEWSITEM_BEGIN
})
const getMetadataToNewsItemSuccess = (payload, additionalParams) => ({
    type: ActionType.FETCH_METADATA_TO_NEWSITEM_SUCCESS,
    payload: payload,
    newsItemId: additionalParams.newsItemId
})
const getMetadataToNewsItemFailure = error => ({
    type: ActionType.FETCH_METADATA_TO_NEWSITEM_FAILURE,
    payload: error
})

const getNewsItemSubObjectBegin = (additionalParams) => ({
    type: ActionType.GET_NEWSITEM_SUBOBJECT_BEGINS,
    newsItemId: additionalParams.newsItemId
})
const getNewsItemSubObjectSuccess = (payload, additionalParams) => ({
    type: ActionType.GET_NEWSITEM_SUBOBJECT_SUCCESS,
    payload: payload,
    newsItemId: additionalParams.newsItemId
})
const getNewsItemSubObjectFailure = (error, additionalParams) => ({
    type: ActionType.GET_NEWSITEM_SUBOBJECT_FAILURE,
    payload: error,
    newsItemId: additionalParams.newsItemId
})
const setUpdateTrigger = (updateTrigger) => ({
    type: ActionType.SET_UPDATE_TRIGGER,
    bUpdateTrigger: updateTrigger,
})

//Helpers:
const fetchNodeGroups = async (isTable, canAffectLoadingScreen, dispatch, getState) => {
    await dispatch(apiFetch("GET", FetchType.NODE_GROUP, isTable, undefined, undefined, canAffectLoadingScreen));

    const state = getState().hubObjectContent;
    for (var i = 0; i < state.hubNodeGroups.length; i++) {
        await dispatch(apiFetch("GET", FetchType.NODE_GROUP_NODES, isTable, { nodeGroupId: state.hubNodeGroups[i].groupId }, undefined, canAffectLoadingScreen));
    }
}

export const mapStateToHubObjectBoardProps = state => {
    return state.hubObjectContent;
}

export const mapDispatchToHubObjectBoardProps = {
    onDeleteObject: (objectType, objectId) => async (dispatch, getState) => {
        //Logger.debug(`deleting (${objectId}) of type ${objectType}`);
        if (objectType === HubConstant.HUB_OBJECT_STORY) {
            await hubApi.deleteStory(objectId)
        } else if (objectType === HubConstant.HUB_OBJECT_ELEMENT) {
            await hubApi.deleteElement(objectId)
        }
    },

    loadNodeContents: (nodeId, objectType, searchText, action) => async (dispatch, getState) => {
        Logger.debug("Loadingnodecontent")
        const state = getState().hubObjectContent;
        if (state.action === DataTableConstant.HUB_ACTION_STATE.LOADING || state.error) {
            // Don't issue a duplicate request (we are loading or already have or are loading the requested data)
            Logger.debug(`NodeContents(${nodeId}): already loaded or loading`);
            return;
        }

        dispatch(fetchNodeContentsBegin(objectType));
        let token = await authService.getAccessTokenAsync();
        let user = await authService._user;
        Logger.debug('loading stories, current user:');
        Logger.debug(user);
        let reverse = true;
        //let endPointName = (!!objectType && objectType !== 0) ? (objectType === HubConstant.HUB_OBJECT_STORY ? 'story' : 'element') : 'content';
        //let url = `api/node/1/${endPointName}`;
        let queryString = (!!objectType && objectType !== 0) ? (objectType === HubConstant.HUB_OBJECT_STORY ? `?objectType=${HubConstant.HUB_OBJECT_STORY}` : `?objectType=${HubConstant.HUB_OBJECT_ELEMENT}`) : null;
        Logger.debug(`objectType is ${objectType}`);
        let url = `api/node/1/newsitem`;
        let paramToken = '?';
        if (!!queryString) {
            //Logger.debug("QueryString is: " + queryString);
            url = url + queryString;
            paramToken = '&';
        }
        if (nodeId > 0) {
            //Logger.debug("nodeId is: " + nodeId);
            url = url + `${paramToken}sourceNodeId=${nodeId}`;
            paramToken = '&';
        }
        if (searchText != null) {
            Logger.debug("searchText is: " + searchText);
            searchText = searchText.trim();
            if (searchText.length > 0) {
                url = url + `${paramToken}searchText=${searchText}`;
                reverse = false;
            }
        }
        Logger.debug(`loading from ${url}`);
        const response = await fetch(url, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });

        if (response.ok) {
            // Logger.debug(`stories loaded`);
            let resp = await response.json();
            let payload = reverse ? resp.hubObjects.reverse() : resp.hubObjects;

            var newPayload = {};
            newPayload.hubObjects = payload;
            newPayload.action = action;

            dispatch(fetchNodeContentsSuccess(newPayload));
        }
        else {
            //Logger.debug(`loading stories failed, error=${response.error}`);
            dispatch(fetchNodeContentsFailure(response.error));
        }
    },

    setNodeFilter: (nodeIds, objectType, metadata, searchText) => async (dispatch, getState) => {

        var jsonToSend = {
            nodeFilter: {
                groups: [0],
                nodes: nodeIds
            },
            objectTypes: [objectType],
            ...!!metadata && metadata.name.length > 0 && {metadataFilters: [
                {
                  fieldId: metadata.id,
                  values: metadata.name
                }
              ]
            }
        }
        //Fetch later to prevent animation lag from filter bar:
        setTimeout(function(){ dispatch(apiFetch("POST", FetchType.NODES, undefined, undefined, jsonToSend)); }, 150);
    },

    getNodeGroups: (isTable, canAffectLoadingScreen) => async (dispatch, getState) => {
        await fetchNodeGroups(isTable, canAffectLoadingScreen, dispatch, getState);
    },

    createNodeGroup: (body, nodesToAdd) => async (dispatch, getState) => {
        //Logger.debug("isTable", isTable)
        //await dispatch(apiFetch("GET", FetchType.NODE_GROUP, isTable, undefined, undefined));
        await dispatch(apiFetch("POST", FetchType.NODE_GROUP, undefined, undefined, body));

        nodesToAdd.forEach(async node => {
            await dispatch(apiFetch("POST", FetchType.NODE_GROUP_NODES, undefined, { nodeGroupId: getState().hubObjectContent.nodeGroupIdJustAdded, nodeId: node.id }, undefined));
        });

        //await HubConfigActions.fetchNodes(dispatch, getState);
        await fetchNodeGroups(true, true, dispatch, getState);
    },

    updateNodeGroups: (nodeGroupId, body, nodesToAdd, nodesToDelete) => async (dispatch, getState) => {
        //Logger.debug("isTable", isTable)
        //await dispatch(apiFetch("GET", FetchType.NODE_GROUP, isTable, undefined, undefined));
        await dispatch(apiFetch("PUT", FetchType.NODE_GROUP, undefined, { nodeGroupId: nodeGroupId }, body));

        nodesToAdd.forEach(async node => {
            await dispatch(apiFetch("POST", FetchType.NODE_GROUP_NODES, undefined, { nodeGroupId: nodeGroupId, nodeId: node.id }, undefined));
        });

        nodesToDelete.forEach(async node => {
            await dispatch(apiFetch("DELETE", FetchType.NODE_GROUP_NODES, undefined, { nodeGroupId: nodeGroupId, nodeId: node.id }, undefined));
        });

        //await HubConfigActions.fetchNodes(dispatch, getState);
        await fetchNodeGroups(true, true, dispatch, getState);
    },

    deleteNodeGroup: (nodeGroupId, nodesToDelete) => async (dispatch, getState) => {

        //Bryan: BUG 356 commented out, concurrent issue, there is no guarantee that the api calls to remove node finishes 
        //       before the api call to remove nodegroup. We get the delete voilation error if the api all to remove the nodegroup 
        //       is executed first
        /*
        nodesToDelete.forEach(async node => {
            await dispatch(apiFetch("DELETE", FetchType.NODE_GROUP_NODES, undefined, { nodeGroupId: nodeGroupId, nodeId: node.id }, undefined));
        });
        */
        await dispatch(apiFetch("DELETE", FetchType.NODE_GROUP, undefined, { nodeGroupId: nodeGroupId }, undefined));

        await fetchNodeGroups(true, true, dispatch, getState);
    },

    editNodeGroup: () => async (dispatch, getState) => {
        dispatch(editNodeGroup());
    },

    getNewsItemMetadata: (newsItemId) => async (dispatch, getState) => {
        dispatch(apiFetch("GET", FetchType.NEWSITEM_METADATA, true, { newsItemId: newsItemId }, undefined));
    },
    getNewsItemSubObject: (newsItemId) => async (dispatch, getState) => {
        await dispatch(apiFetch("GET", FetchType.NEWSITEM_SUBOBJECT, true, { newsItemId: newsItemId }, undefined));
    },
    onFinishStoryUpdate: () => async (dispatch, getState) => {
        dispatch(setUpdateTrigger(false));
    }
}

// The integrated version combining both the OG + Metadata Form

import React from "react";
import * as Logger from 'loglevel';
import { OverlayTrigger, Tooltip, Tabs, Tab, Row, Col } from "react-bootstrap";
import MetadataPerNewsItemForm from '../Metadata/MetadataPerNewsItemForm';
import authService from 'components/api-authorization/AuthorizeService';
import AnimatingArrows from 'components/post-authorization/libraries/AnimatingArrows';

import { WIDGET_ANIMATION_STATE } from '../HubForm';
import * as HubConstant from 'util/HubConstant';

//Redux:
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { mapDispatchToMetadataProps } from 'store/actions/HubMetadataActions';
import { mapDispatchToHubObjectBoardProps } from 'store/actions/HubObjectBoardActions';

import 'styles/widgets/NewsItem.scss'

class IntegratedNewsItemForm extends React.Component {

    constructor(props) {
        super(props);
        Logger.debug(props);
        const { payload: newsItemId } = props;

        this.newsItemId = !!newsItemId ? newsItemId : -1;

        this.maxWidthConstraint = 1570; //magically-hardcoded value with inspector

        this.state = {
            isHoveringExpand: false,
            width: Math.min(window.innerWidth, this.maxWidthConstraint),
            height: window.innerHeight,
            addCounter: 1, //increment this val so each added val has a new and unique index

            //metadata:
            metadata: null, //initialize in componentWillMount
            changeNotifier: false,
            isSmallScreenSize: false
        }
    }

    componentWillMount() {
        this.onScreenSizeRestructure();
    }

    //re-do initializations as of the child:
    initializeNewsItemMetadata = async () => {
        Logger.debug(this.props)
        //fetch newsobject metadata if it's null:
        await this.props.getMetadata();
        //if (!(!!this.props.objectList.find(newsObj => newsObj.id === this.newsItemId).metadata)) {
        if (this.newsItemId > -1) await this.props.getNewsItemMetadata(this.newsItemId);
        //}

        var newMetadataList = [];
        this.props.metadata.forEach(metadata => {
            var metadataFieldInNewsObj = null;
            var newsItem = this.props.objectList.find(newsObj => newsObj.id === this.newsItemId);
            if (!!newsItem) metadataFieldInNewsObj = newsItem.metadata.find(newsObjMetadata => newsObjMetadata.fieldId === metadata.fieldId);

            if (!!metadataFieldInNewsObj) {
                //if metadataField is found in objectList, use metadataFieldInNewsObj
                Logger.debug(this.props.metadata);
                var metadataFieldInNewsObjWithCompleteMetadata = JSON.parse(JSON.stringify(metadataFieldInNewsObj)); //remove object ref
                if (metadata.isControlledVocabulary) metadataFieldInNewsObjWithCompleteMetadata["availableControlledVocabularies"] = metadata.vocabulary;
                metadataFieldInNewsObjWithCompleteMetadata["isControlledVocabulary"] = metadata.isControlledVocabulary;
                metadataFieldInNewsObjWithCompleteMetadata.values = metadataFieldInNewsObjWithCompleteMetadata.values.map(vocab => { return { ...vocab, isChecked: false } }); //for delete selection
                newMetadataList.push(metadataFieldInNewsObjWithCompleteMetadata);
            } else {
                newMetadataList.push({
                    fieldId: metadata.fieldId,
                    dataType: metadata.dataType,
                    fieldName: metadata.fieldName,
                    values: [],
                    ...metadata.isControlledVocabulary && { availableControlledVocabularies: metadata.vocabulary },
                    isControlledVocabulary: metadata.isControlledVocabulary
                });
            }
        });
        Logger.debug(newMetadataList);
        var newChangeNotifier = !this.state.changeNotifier;
        this.setState({ metadata: newMetadataList, changeNotifier: newChangeNotifier });
    }

    onSelectValue = (fieldId, valIndex, isSelected) => {
        Logger.debug(fieldId); Logger.debug(valIndex);
        var newMetadata = null;
        if (isSelected !== undefined) {
            newMetadata = this.state.metadata;
            Logger.debug(newMetadata);
            newMetadata.find(dataField => dataField.fieldId === fieldId).values.find(val => val.index === valIndex).isChecked = isSelected;
            //newMetadata.forEach(dataField => dataField.values.forEach(val => {Logger.debug(val.isChecked);val.isChecked = isSelected}));
        } else {
            //Toggle on/off:
            var isChecked = this.state.metadata.find(dataField => dataField.fieldId === fieldId).values.find(val => val.index === valIndex).isChecked;
            Logger.debug(isChecked);
            isChecked = !isChecked;
            Logger.debug(this.state.metadata);

            newMetadata = this.state.metadata;
            newMetadata.find(dataField => dataField.fieldId === fieldId).values.find(val => val.index === valIndex).isChecked = isChecked;
        }

        var newChangeNotifier = !this.state.changeNotifier;
        this.setState({ changeNotifier: newChangeNotifier });
    }

    onDeleteSelectedValues = () => {
        this.state.metadata.forEach(dataField => {
            dataField.values = dataField.values.filter(val => !val.isChecked);
        });
        var newChangeNotifier = !this.state.changeNotifier;
        this.setState({ changeNotifier: newChangeNotifier });
    }

    onDeleteSelectedVal = (fieldId, valIndex) => {
        Logger.debug(fieldId);
        Logger.debug(valIndex);

        var foundVals = this.state.metadata.find(dataField => dataField.fieldId === fieldId).values;
        foundVals = foundVals.filter(val => val.index !== valIndex);

        //Assign to state:
        this.state.metadata.find(dataField => dataField.fieldId === fieldId).values = foundVals;

        Logger.debug(foundVals);

        var newChangeNotifier = !this.state.changeNotifier;
        this.setState({ changeNotifier: newChangeNotifier });
    }

    onChange = (val, index) => {
        Logger.debug(val);
        Logger.debug(index);
        var newMetadata = JSON.parse(JSON.stringify(this.state.metadata));
        newMetadata[index].fieldVal = val;
        Logger.debug(newMetadata);
        this.setState({ metadata: newMetadata });
    }

    /*async onUpdateNewsItemMetadata() {
        var body = {
            metadata: this.state.metadata.filter(data => data.values.length > 0).map(data => {
                return {
                    fieldId: data.fieldId,
                    values: data.values.map(value => value.value)
                }
            })
        };
        await this.props.updateNewsItemMetadata(this.newsItemId, body);
        await this.props.getNewsItemMetadata(this.newsItemId);

        Logger.debug(this.props.error);
        if (!!this.props.success) {
            this.setState({ isStatusEnabled: true }, () => this.props.onFormClosed());
        } else if (!!this.props.error) {
            this.setState({ isStatusEnabled: true });
        }
    }*/

    onLocalAddNewsItemMetadata = (id, value) => {
        Logger.debug(value);
        Logger.debug(id);
        var newMetadata = this.state.metadata;
        newMetadata.forEach(dataVal => {
            Logger.debug(dataVal)
            if (id === dataVal.fieldId)
                dataVal.values.push({ index: -this.state.addCounter, value: value, isChecked: false });
        });
        Logger.debug(newMetadata)
        var newAddCounter = this.state.addCounter + 1;
        this.setState({ metadata: newMetadata, addCounter: newAddCounter }, () => this.setState({ changeNotifier: !this.state.changeNotifier }));
    }

    onLocalResetNewsItemMetadata = (id, arrOfMetadata) => {
        var newMetadata = this.state.metadata;
        Logger.debug(newMetadata);
        newMetadata.forEach(dataVal => {
            Logger.debug(dataVal)
            if (id === dataVal.fieldId)
                dataVal.values = arrOfMetadata.map(mdata => { return { index: mdata.index, value: mdata.value, isChecked: false } });
        });
        var newAddCounter = this.state.addCounter + 1;
        this.setState({ metadata: newMetadata, addCounter: newAddCounter }, () => this.setState({ changeNotifier: !this.state.changeNotifier }));
        // newMetadata.forEach(dataVal => {
        //     newMetadata.values.push({ index: -this.state.addCounter, value: value, isChecked: false });
        // });
        // Logger.debug(newMetadata)
        // var newAddCounter = this.state.addCounter + 1;
        // this.setState({ metadata: newMetadata, addCounter: newAddCounter }, () => this.setState({ changeNotifier: !this.state.changeNotifier }));
    }



    //==============end of metadata news form functions===============================================

    updateDimensions = () => {
        this.setState({ width: Math.min(window.innerWidth, this.maxWidthConstraint), height: window.innerHeight });
        this.onScreenSizeRestructure();
    };

    onScreenSizeRestructure() {
        if (window.innerWidth <= 1007 && !this.state.isSmallScreenSize) {
            this.setState({ isSmallScreenSize: true });
        } else if (window.innerWidth > 1007 && this.state.isSmallScreenSize) {
            this.setState({ isSmallScreenSize: false });
        }
    }


    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    //the submit button of storyform is called
    createNewsItemWithMetadata = (newsItemWithoutMetadata, elementType, file) => {

        //construct the story object, by combining it with metadata:
        //this.metadataPerNewsItemForm.onCreateStoryWithMetadata()

        var metadataWithVals = this.state.metadata.filter(data => data.values.length > 0).map(data => {
            return {
                fieldId: data.fieldId,
                values: data.values.map(value => value.value)
            }
        })

        let formData = new FormData();
        if (elementType === HubConstant.HUB_OBJECT_STORY) {
            newsItemWithoutMetadata["metadata"] = metadataWithVals
        } else {
            newsItemWithoutMetadata["metadata"] = metadataWithVals;
            formData.append("elementDescriptor", JSON.stringify(newsItemWithoutMetadata));
            formData.append("file", file);
        }
        Logger.debug(newsItemWithoutMetadata);
        Logger.debug(JSON.stringify(newsItemWithoutMetadata))

        return new Promise(async (resolve, reject) => {
            let token = await authService.getAccessTokenAsync();
            Logger.debug("!")
            resolve(await fetch(`/api/CloudQ/1/${elementType === HubConstant.HUB_OBJECT_STORY ? "story" : "element"}`, {
                method: "POST",
                headers: {
                    ...elementType === HubConstant.HUB_OBJECT_STORY && {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    'Authorization': `Bearer ${token}`,
                },
                body: elementType === HubConstant.HUB_OBJECT_STORY ? JSON.stringify(newsItemWithoutMetadata) : formData
            }));
        });
    }

    getCategory = () => {
        if (!!this.props.metadata) {
            return this.props.metadata.find(mdata => mdata.fieldId === 1);
        }
    }

    renderMetadataPerNewsItemForm() {
        return (
            <MetadataPerNewsItemForm
                isIntegratedForm={true}
                payload={this.props.payload}
                //all the duplicated functions... all-hail react top-down architecture:
                initializeNewsItemMetadata={this.initializeNewsItemMetadata}
                onSelectValue={this.onSelectValue}
                onDeleteSelectedValues={this.onDeleteSelectedValues}
                onDeleteSelectedVal={this.onDeleteSelectedVal}
                onChange={this.onChange}
                onUpdateNewsItemMetadata={this.onUpdateNewsItemMetadata}
                onLocalAddNewsItemMetadata={this.onLocalAddNewsItemMetadata}

                integratedMetadata={this.state.metadata}
                integratedChangeNotifier={this.state.changeNotifier}
                {... this.props} />
        )
    }

    renderNewsItemForm() {
        /* isIntegratedForm consists of both MetadataPerNewsItemForm and StoryForm */
        return (
            <this.props.form
                createNewsItemWithMetadata={this.createNewsItemWithMetadata}
                isIntegratedForm={true}
                submitHandler={this.submitHandler}
                getCategory={this.getCategory}
                storyMetadata={this.state.metadata}
                onDeleteSelectedVal={this.onDeleteSelectedVal}
                onSubmitRowEditor={this.onLocalAddNewsItemMetadata}
                onLocalResetNewsItemMetadata={this.onLocalResetNewsItemMetadata}
                {... this.props} />
        )
    }

    render() {
        if (!!this.props.form) {
            return (
                true === false ? // !this.state.isSmallScreenSize ? BUG 439: Use tab view in New Story form
                    <>
                        {this.renderNewsItemForm()}

                        <div className="collapsableDiv">
                            {
                                !!this.props.metadata ?
                                    <OverlayTrigger key="ExpandTooltip" placement="left" overlay={
                                        <Tooltip className="additionalSettingsTooltip rowActionTooltip">
                                            {(this.props.metadata.find(mdata => mdata.fieldId !== 1) === undefined) //1 means category; if can't find a metadata field other than category
                                                ?
                                                <>
                                                    Data Fields< br />Need to be Added< br />For Additional Settings
                                    </>
                                                :
                                                <>
                                                    Additional < br /> Settings
                                </>
                                            }
                                        </Tooltip>}>
                                        <div
                                            className={`${this.props.widgetAnimState === WIDGET_ANIMATION_STATE.NONE && !this.props.isHorizontallyExpanded ? `ExpandDiv` : `UnexpandDiv`} ${
                                                (this.props.metadata.find(mdata => mdata.fieldId !== 1) === undefined) ? "disabledWithTooltip" : ""
                                                }`}
                                            onClick={() => { if (!!this.props.metadata.find(mdata => mdata.fieldId !== 1)) this.props.horizontalCollapse() }}
                                            onMouseEnter={() => { if (!!this.props.metadata.find(mdata => mdata.fieldId !== 1)) this.setState({ isHoveringExpand: true }) }}
                                            onMouseLeave={() => { if (!!this.props.metadata.find(mdata => mdata.fieldId !== 1)) this.setState({ isHoveringExpand: false }) }}>
                                            <AnimatingArrows
                                                isHorizontallyExpanded={this.props.isHorizontallyExpanded}
                                                shouldAnimate={this.state.isHoveringExpand} />
                                        </div>
                                    </OverlayTrigger>
                                    : <></>
                            }

                            <div className="HorizontalMetadataCol" style={{ width: this.state.width * 0.9 - 500 }}>
                                {this.renderMetadataPerNewsItemForm()}
                            </div>
                        </div>
                    </>
                    :
                    <Row className="HubTabRow">
                        <Col xs="12">
                            <Tabs
                                id="controlled-tab-example"
                                defaultActiveKey="general"
                            //mountOnEnter //need these ones to stop tabs from rendering other pages... or else very laggy and bad performance...
                            //unmountOnExit
                            >
                                <Tab eventKey="general" title="General">
                                    {this.renderNewsItemForm()}
                                </Tab>
                                <Tab eventKey="extraSettings" title={`Data Settings`}>
                                    {this.renderMetadataPerNewsItemForm()}
                                </Tab>
                            </Tabs>
                        </Col>
                    </Row>

            )
        } else { return <></> }
    }

}

//React expects me to do the same thing as i did in the child due to it's top-down architecture.... sad life

const mapMetadataAndObjectBoardStatesToProps = (state) => {
    return {
        objectList: state.hubObjectContent.objectList,
        metadata: state.hubConfig.hubMetadata.metaData,
        success: state.hubConfig.hubMetadata.success,
        error: state.hubConfig.hubMetadata.error
    }
}

export default withRouter(connect(
    mapMetadataAndObjectBoardStatesToProps,
    dispatch => bindActionCreators({ ...mapDispatchToMetadataProps, ...mapDispatchToHubObjectBoardProps }, dispatch)
)(IntegratedNewsItemForm));
import React from "react";
import { Button, Form, Row, Col } from 'react-bootstrap'
import { TextField } from '@material-ui/core';
import * as Logger from 'loglevel';
import authService from '../../api-authorization/AuthorizeService';
import Utilities from 'hub-utilities'
import Cookies from 'js-cookie';
import * as HubConstant from 'util/HubConstant';

import { faListAlt } from '@fortawesome/free-solid-svg-icons'
import { MultiValsSelector } from 'hub-dashboard-framework';

import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";


class StoryFrom extends React.Component {
    constructor(props) {
        super(props);
        this.form = React.createRef();

        this.state = {
            title: "",
            text: "",
            keywords: "",
            fileName: null,
            formError: null, //was fileUploadStatus
            isStatusEnabled: false,
            uploading: false,
            attachments: [],
            isCreatingStory: false,
            isBrowseWindowOpen: false,
            isUrgent: false,
            editorActiveIndex: -1
        };
    }

    async componentWillMount() {
        var cachedTitle = await Cookies.get('CreateStoryTitle');

        var numOfBodyCookies = await Cookies.get('NumOfBodyCookies');
        var cachedText = "";
        if (!!numOfBodyCookies) {
            for (var i = 0; i < parseInt(numOfBodyCookies); i++) {
                cachedText += await Cookies.get(`CreateStoryBody${i}`);
            }
        }

        this.setState({
            title: !!cachedTitle ? cachedTitle : "",
            text: !!cachedText ? cachedText : ""
        })
    }

    componentDidMount() {
        //Add a timeout here
        setTimeout(function () {
            // this.initialInputFocusRef.current.focus();
            document.getElementById("storyTitle").focus();
        }, 1)
    }

    setEditorActiveIndex = (rowIndex) => {
        if (rowIndex === this.state.editorActiveIndex) {
            //Set editing row to none
            this.setState({ editorActiveIndex: -1 });
        } else {
            this.setState({ editorActiveIndex: rowIndex });
        }
    }

    //Each cookie has 4096 byte limit (around 3900 characters)
    cacheCreateStoryInfo(stateName, val) {
        if (stateName === 'title') {
            Cookies.set(`CreateStoryTitle`, val);
        }
        else if (stateName === 'text') {
            var infoArrs = Utilities.chunkInfoOnExceedByteLimit(val, 4096);
            for (var i = 0; i < infoArrs.length; i++) {
                Cookies.set(`CreateStoryBody${i}`, infoArrs[i]);
            }
            Cookies.set(`NumOfBodyCookies`, infoArrs.length);
        }
    }

    async clearCookies() {
        var numOfBodyCookies = await Cookies.get('NumOfBodyCookies');
        if (!!numOfBodyCookies) {
            for (var i = 0; i < parseInt(numOfBodyCookies); i++) {
                Cookies.set(`CreateStoryBody${i}`, "");
            }
        }

        Cookies.set(`CreateStoryTitle`, "");
        Cookies.set('NumOfBodyCookies', "");
    }

    validate = () => (this.form.current.reportValidity());

    createStory = async () => {
        //To prevent story from being double-created: 
        this.setState({ isCreatingStory: true }, async () => {
            const response = await this.props.createNewsItemWithMetadata({
                name: this.state.title,
                text: this.state.text,
                newsObjectDesc: {
                    keyword: this.state.keywords,
                    priorityLevel: this.state.isUrgent ? 5 : 7
                },
                elements: this.state.attachments.map(a => (a.elementId)),
            }, HubConstant.HUB_OBJECT_STORY);

            if (response.ok) {
                let respObj = await response.json()
                Logger.debug(respObj)
                let storyId = respObj.hubObject != null ? respObj.hubObject.id : -1;
                if (!!this.props.onStoryCreated) {
                    this.props.onStoryCreated(storyId);
                }
                if (!!this.props.onFormSubmitted) {
                    this.props.onFormSubmitted();
                }
                this.clearCookies();
            }
            else {
                let respText = await response.text();
                let newSts = ({
                    formError: `Creating story failed: ${respText}`,
                });
                this.setState(newSts);
                this.setState({ isCreatingStory: false });
            }
        });
    }

    submitHandler = event => {
        event.preventDefault();
        event.target.className += " was-validated";
        if (this.validate() && !this.state.isCreatingStory) {
            this.createStory();
        }
    };

    toggleStatus = (e) => {
        e.preventDefault();
        this.setState({ isStatusEnabled: !this.state.isStatusEnabled });
    }

    changeHandler = event => {
        this.cacheCreateStoryInfo(event.target.name, event.target.value);
        this.setState({ [event.target.name]: event.target.value });
    };

    fileUploadHandler = async event => {
        this.props.setIsBrowseWindowOpen(false);

        Logger.debug(`Uploading file ${event.target.value} started`);
        let selectedFilename = Utilities.getFileNameFromPath(event.target.value);
        this.setState({ fileName: selectedFilename, formStatus: null, uploading: true });
        let formData = new FormData();
        formData.append("file", event.target.files[0]);
        let token = await authService.getAccessTokenAsync();
        const response = await fetch(`/api/CloudQ/1/file`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            body: formData
        });
        Logger.debug('fileUploadHandler response = ', response);
        this.setState({ uploading: false });
        let respObject = await response.json();
        let newSts;
        if (response.ok) {

            let elementId = respObject.hubObject.id;
            Logger.debug(`new media element ${elementId} created.`);
            let existingAttachments = this.state.attachments;
            newSts = ({
                formError: null,
                attachments: existingAttachments.concat({ elementId: elementId, fileName: selectedFilename }),
                fileName: null
            });
        }
        else {
            newSts = ({
                formError: `Uploading ${selectedFilename} failed: ${respObject.error}`,
                hasError: false,
                fileName: null
            });
        }
        this.setState(newSts);

    };

    setCategoriesToSelect = (newArr) => {
        var categories = this.props.getCategory();
        if (!!categories) {
            this.props.onLocalResetNewsItemMetadata(categories.fieldId, newArr.map(val => {
                return { index: val.id, value: val.name }
            }));
        }
    }

    renderCategory() {
        var availableCategories = this.props.getCategory();
        var chosenCategoriesState = !!this.props.storyMetadata ? this.props.storyMetadata.find(mdata => mdata.fieldId === 1) : null;
        //Logger.debug(availableCategories.vocabulary.map(vocab => vocab.keyword));
        //Logger.debug(chosenCategoriesState);
        return (
            !!availableCategories && !!chosenCategoriesState ?
                <>
                    {/* Change to set to remove duplicates */}
                    <div className="multiValsSelectorWrapper">
                        <MultiValsSelector
                            id={0}
                            title={availableCategories.fieldName}
                            toggleIcon={faListAlt}
                            availableVals={availableCategories.vocabulary.map(vocab => { return { id: vocab.index, name: vocab.keyword } })}
                            selectedVals={chosenCategoriesState.values.map(value => { return { id: value.index, name: value.value } })}
                            setSelectedVals={this.setCategoriesToSelect}
                        />
                    </div>

                    {/* {chosenCategoriesState.values.map(val => {
                        Logger.debug(val);
                        return (
                            <div name="" className="categoryBadge">
                                {val.value}<FontAwesomeIcon className="deleteIcon" icon={faTrash} onClick={() => this.props.onDeleteSelectedVal(chosenCategoriesState.fieldId, val.index)} />
                            </div>
                        );
                    })}
                    <RowEditor
                        title="Add Category"
                        icon={faPlusCircle}
                        mediaEditInfo={[
                            availableCategories.isControlledVocabulary ? { type: DataEditorType.DROP_DOWN, dataType: availableCategories.dataType, dropdownVal: availableCategories.vocabulary, key: "keyword" } :
                            availableCategories.dataType == UtilConstant.HUB_DATA_TYPE.TEXT || availableCategories.dataType == UtilConstant.HUB_DATA_TYPE.KEYWORD ?
                                    { placeholder: availableCategories.fieldName, type: DataEditorType.TEXT_FIELD, dataType: "text" } :
                                    availableCategories.dataType == UtilConstant.HUB_DATA_TYPE.DECIMAL ?
                                        { placeholder: availableCategories.fieldName, type: DataEditorType.TEXT_FIELD, dataType: "number" } :
                                        { placeholder: availableCategories.fieldName, type: DataEditorType.DATE_TIME_FIELD }
                        ]}
                        editorId={availableCategories.fieldId}
                        objectType={this.props.objectType}
                        editingRowIndex={this.state.editorActiveIndex}
                        setEditingRow={this.setEditorActiveIndex}
                        onSubmitRowEditor={this.props.onSubmitRowEditor}
                    /> */}
                </>
                : <></>
        );
    }

    render() {
        const collapsibleLoaderOverride = css`
        position: relative;
        margin-top: 5px !important;
        margin-bottom: 0px !important;
    `;
        const uploadMediaButtonOverride = css`
    position: relative;
    margin-top: 0 !important;
`;
        let fileInputLabel = this.state.fileName ? this.state.fileName : "Add Media";
        let uploadedAttachments = null;
        if (this.state.attachments.length > 0) {
            let fileList = this.state.attachments.map(a => (<div key={a.elementId}>{a.fileName}</div>));
            uploadedAttachments = <div><div>Uploaded files:</div>{fileList}</div>;
        }
        return (
            <div>
                <Form ref={this.form} className="needs-validation StoryDetailsForm" onSubmit={this.submitHandler} >
                    <Row className="ImportantInfoRow">
                        <Col xs="10">
                            <TextField
                                value={this.state.title}
                                name="title"
                                onChange={this.changeHandler}
                                type="text"
                                id="storyTitle"
                                className="form-control"
                                label="Story Title"
                                required
                            />
                        </Col>
                        <Col xs="2" className="LargeCheckbox">
                            <div className="formTitle">Urgent?</div>
                            <label
                                className='checkboxContainer'
                            >
                                <input
                                    id={`UrgencyCheckbox`}
                                    name={`UrgencyCheckbox`}
                                    className="filterGroupChild"
                                    label=""
                                    type="checkbox"
                                    onChange={() => {
                                        var newUrgency = !this.state.isUrgent;
                                        this.setState({ isUrgent: newUrgency });
                                    }}
                                    // onChange={(e) => this.handleCheckboxChange(e, availableFilterObjs, objsToFilter, null)}
                                    checked={this.state.isUrgent}
                                />
                                <span className='checkmark large' />
                            </label>
                        </Col>
                    </Row>
                    <div className="invalid-feedback">
                        Please provide a title for the story.
                    </div>
                    <div className="valid-feedback">Looks good!</div>
                    <textarea
                        value={this.state.text}
                        name="text"
                        onChange={this.changeHandler}
                        id="storyText"
                        className="form-control"
                        placeholder="Story Text"
                        rows="6"
                    />
                    {this.renderCategory()}
                    <TextField
                        value={this.state.keywords}
                        name="keywords"
                        onChange={this.changeHandler}
                        type="text"
                        id="storyKeyword"
                        className="form-control storyKeyword"
                        label="Tags"
                    />
                    <div className="input-group">
                        {/* <div className="input-group-prepend"> {uploadPrepend}</div> */}
                        <div className="custom-file">
                            <input
                                type="file"
                                style={{display:"none"}}
                                onChange={this.fileUploadHandler}
                                onClick={() => this.props.setIsBrowseWindowOpen(true)}
                                className="custom-file-input"
                                id="inputGroupFile01"
                                aria-describedby="inputGroupFileAddon01" />
                            <label 
                                className={`custom-file-label ${this.state.uploading ? "uploading" : ""}`} 
                                htmlFor="inputGroupFile01">{this.state.uploading ? "Uploading..." : fileInputLabel}</label>
                            <Button
                                className="uploadMediaButton"
                                onClick={() => { }}>
                                {this.state.uploading ?
                                    <ClipLoader
                                        size={20}
                                        color={"#f69b1d"}
                                        css={uploadMediaButtonOverride}
                                    />
                                    :
                                    "Browse"}
                            </Button>
                        </div>
                    </div>
                    <div className="uploadedFileDisplay">
                        {uploadedAttachments}
                    </div>
                    {
                        !!this.state.formError ?
                            <div className="errorMsg">
                                {this.state.formError}
                            </div>
                            :
                            <></>
                    }
                    <Button className={this.state.uploading ? "disabled" : ""} type="submit" size="sm">
                        {this.state.uploading ?
                            <ClipLoader
                                size={20}
                                color={"#f69b1d"}
                                css={collapsibleLoaderOverride}
                            />
                            :
                            "Create"}
                    </Button>

                </Form >
            </div >
        );
    }
}

export default StoryFrom;
import React from "react";
import { TextField } from '@material-ui/core'
import * as Logger from 'loglevel';
import hubApi from 'util/HubApi';
import authorizeService from 'components/api-authorization/AuthorizeService';
import { UtilConstant } from 'hub-utilities'
import { HubUserFormEx } from 'hub-dashboard-framework'

import 'styles/widgets/User.scss'

export default class HubUserForm extends React.Component {
    constructor(props) {
        const { payload: user } = props;
        super(props);
        this.form = React.createRef();
        Logger.debug(user)
        this.state = !!user.FirstName
            ? {
                username: user.LoginName.val,
                firstname: user.FirstName.val,
                lastname: user.LastName.val,
                password1: "",
                password2: "",
                email: user.Email.val,
                phoneNumber: user.phoneNumber,
                adminLevel: user.adminLevel,
                description: user.Description.val,
                formError: undefined,
                isStatusEnabled: false,
                isCreatingUser: false,
                hubNodeId: user.ProductionCentre.id,

                spaceWarningShow: false
            }
            : {
                username: undefined,
                firstname: undefined,
                lastname: undefined,
                password1: "",
                password2: "",
                email: undefined,
                phoneNumber: undefined,
                adminLevel: UtilConstant.HUB_USER_TYPE.HUB_USER,
                description: undefined,
                formError: undefined,
                isStatusEnabled: false,
                isCreatingUser: false,
                hubNodeId: authorizeService.getNodeIdByUserGuid(authorizeService.getUserGuid()),

                spaceWarningShow: false
            };
        this.userNodeId = !!user.FirstName ? user.hubNodeId : -1;
        Logger.debug(this.state.hubNodeId);
        this.spaceWarningTarget = React.createRef();
    }
    onChange = (event, isPassword) => {
        if (isPassword && event.target.name.includes('password') && event.target.value.includes(' ')) {
            this.setState({ spaceWarningShow: true });
        } else {
            this.setState({ [event.target.name]: event.target.value, spaceWarningShow: false });
        }
    }

    onSelectAdminLevel = (adminLevel) => {
        //Logger.debug('onSelectAdminLevel', this);
        this.setState({ adminLevel: adminLevel });
    }


    onHubNodeSelected = (node) => {
        Logger.debug(node)
        this.userNodeId = node.id;
        this.setState({ hubNodeId: node.id });
    };

    submitHandler = (event) => {
        event.preventDefault();
        if (!this.state.isCreatingUser) {
            this.saveHubUser();
        }
    };

    createHubUser = async () => {
        //To prevent double-create:
        this.setState({ isCreatingUser: true }, async () => {
            var createUserRequest = {
                userName: this.state.username,
                firstName: this.state.firstname,
                lastName: this.state.lastname,
                password: this.state.password1,
                email: this.state.email,
                phoneNumber: this.state.phoneNumber,
                description: this.state.description,
                isReader: false,
                defaultCollectionId: null,
                defaultAccessMask: null,
                readRate: null,
                featureCode: null,
                identityGroup: null,
                adminLevel: this.state.adminLevel
            }
            //createUserRequest.identityGroup = this.convertUserRoleIdToStr();
            try {
                var response = await hubApi.createHubUser(this.state.hubNodeId, createUserRequest);
                if (response.ok) {
                    Logger.debug(`user created.`);
                    this.props.onFormSubmitted();
                }
                else {
                    Logger.error(`Create new hub user failed, response=.`, response);
                    let error = await response.json();
                    this.setState({
                        ...this.state,
                        formError: `Failed: ${error.message}`,
                        isStatusEnabled: true,
                        isCreatingUser: false
                    });
                }
            }
            catch (error) {
                this.setState({
                    ...this.state,
                    formError: `Failed: ${error.message}`,
                    isStatusEnabled: true,
                    isCreatingUser: false
                });
            }
        });
    }

    onError = async (response) => {
    }


    updateHubUser = async (user) => {
        //To prevent double-create:
        this.setState({ isCreatingUser: true }, async () => {
            var request = {
                userName: this.state.username,
                firstName: this.state.firstname,
                lastName: this.state.lastname,
                email: this.state.email,
                phoneNumber: this.state.phoneNumber,
                description: this.state.description,
                hubNodeId: this.state.hubNodeId
            }
            try {
                var updateUserInfoResponse = await hubApi.updateHubUser(user.id, request);
                var updateUserRoleResponse = await hubApi.updateHubUserRole(user.userGuid, this.state.adminLevel);

                //reload user authority:
                let sub = authorizeService._user.profile.sub;
                const token = authorizeService._user.access_token;
                await authorizeService.loadAllUsers(token, sub);

                var errorAry = [];
                if (updateUserInfoResponse.ok && updateUserRoleResponse.ok) {
                    Logger.debug(`user updated.`);
                    this.props.onFormSubmitted();
                }

                if (!updateUserInfoResponse.ok) {
                    Logger.error(`Updating hub user failed, response=.`, updateUserInfoResponse);
                    errorAry.push(await updateUserInfoResponse.json());
                }
                if (!updateUserRoleResponse.ok) {
                    Logger.error(`Updating hub user role failed, response=.`, updateUserRoleResponse);
                    errorAry.push(await updateUserRoleResponse.json());
                }
                if (errorAry.length > 0) {
                    this.setState({
                        ...this.state,
                        formError: `Failed: ${errorAry.map((error, index) => {
                            return error.message + (index < errorAry.length - 1) ? ' and ' : ''
                        })}`,
                        isStatusEnabled: true,
                        isCreatingUser: false
                    });
                }
            }
            catch (error) {
                this.setState({
                    ...this.state,
                    formError: `Failed: ${error.message}`,
                    isStatusEnabled: true,
                    isCreatingUser: false
                });
            }
        });
    }

    saveHubUser = async () => {
        const { payload: user } = this.props;
        if (!!user.FirstName) {
            await this.updateHubUser(user);
        }
        else {
            await this.createHubUser();
        }
    }

    render() {
        const { payload: user } = this.props;
        let isUpdateUser = !!user.FirstName;
        return (
            <HubUserFormEx
                nodeId={this.state.hubNodeId}
                username={this.state.username}
                nodes={user.nodes}
                isUpdateUser={isUpdateUser}
                password1={this.state.password1}
                password2={this.state.password2}
                firstName={this.state.firstname}
                lastName={this.state.lastname}
                email={this.state.email}
                phoneNumber={this.state.phoneNumber}
                description={this.state.description}
                formError={this.state.formError}
                adminLevel={this.state.adminLevel}
                userType={user.userType}
                onChange={this.onChange}
                onHubNodeSelected={this.onHubNodeSelected}
                onSelectAdminLevel={this.onSelectAdminLevel}
                onSubmit={this.submitHandler}
            />
        )
    }
}
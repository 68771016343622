import React, { Fragment } from "react";
import { Row, Col, Button, Form } from 'react-bootstrap'
import { TextField } from '@material-ui/core'
import * as Logger from 'loglevel';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { mapDispatchToProps, mapStateToProps } from 'store/actions/HubConfigActions';

class HubNodeForm extends React.Component {
    constructor(props) {
        const { payload: nodeData } = props;
        super(props);
        this.form = React.createRef();
        this.state = {
            name: nodeData.name,
            description: nodeData.description
        };
        this.nodeId = nodeData.id
    }

    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }

    submitHandler = async (event) => {
        Logger.debug('this.props: ', this.props)
        Logger.debug('this.newsItemId: ', this.newsItemId)
        event.preventDefault()
        var updateNodeRequest = {
            name: this.state.name,
            description: this.state.description,
        }

        await this.props.updateNode(this.nodeId, updateNodeRequest)

        if (this.props.success) {
            Logger.debug(`Updated Node Success.`);
            this.props.onFormSubmitted();
        }
    }

    render() {
        return (
            <Form
                onSubmit={this.submitHandler}
            >
                <Row>
                    <Col xs={12}>
                        <TextField
                            value={this.state.name}
                            name='name'
                            onChange={this.onChange}
                            type='text'
                            id='name'
                            label='Name'
                            required
                        >
                        </TextField>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <TextField
                            value={this.state.description}
                            name='description'
                            onChange={this.onChange}
                            type='text'
                            id='description'
                            label='Description'
                        >
                        </TextField>
                    </Col>
                </Row>
                {this.props.error ? (
                    <div className='errorMsg' style={{ textAlign: "center" }}>{this.props.error}</div>
                ) : (
                        <Fragment />
                    )}
                <Button
                    color='light'
                    type='submit'
                    size='sm'
                    className='float-right mr-0'
                >
                    Save
                </Button>
            </Form>
        );
    }
}

export default connect(
    mapStateToProps,
    dispatch => bindActionCreators(mapDispatchToProps, dispatch)
)(HubNodeForm);
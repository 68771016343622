import React, { Fragment } from "react";
import { Row, Col, Button, Form } from 'react-bootstrap'
import { TextField } from '@material-ui/core'
import hubApi from 'util/HubApi';
import * as Logger from 'loglevel';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { mapDispatchToHubObjectBoardProps } from 'store/actions/HubObjectBoardActions';

class OutQForm extends React.Component {
    constructor(props) {
        const { payload: outQData } = props;
        super(props);
        this.form = React.createRef();
        this.state = {
            outQ: outQData.outQ,
            verbatim: outQData.verbatim
        };
        this.mediaId = outQData.elementId
        this.newsItemId = outQData.newsItemId
    }

    onChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }

    submitHandler = async (event) => {
        Logger.debug('this.props: ', this.props)
        Logger.debug('this.newsItemId: ', this.newsItemId)
        event.preventDefault()
        var updateMediaObjPropertiesRequest = {
            outQ: this.state.outQ,
            verbatim: this.state.verbatim,
        }

        var response = await hubApi.updateMediaObjProperties(this.mediaId, updateMediaObjPropertiesRequest);
        if (response.ok) {
            Logger.debug(`Updated Media Object Properties.`);
            //await hubApi.fetchMediaObj(this.mediaId);
            this.props.getNewsItemSubObject(this.newsItemId) // grab newsitem subobjects of the NEWSITEM id
            this.props.onFormSubmitted();
        }
        else {
            Logger.error(`Update Media Object Properties failed, response=.`, response);
            this.setState({ formError: `Error ${response.status}: ${response.statusText}` });
        }
    }

    render() {
        return (
            <Form
                onSubmit={this.submitHandler}
                noValidate
            >
                <Row>
                    <Col xs={12}>
                        <TextField
                            value={this.state.outQ}
                            name='outQ'
                            onChange={this.onChange}
                            type='text'
                            id='outQ'
                            label='Out-Queue'
                        >
                        </TextField>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <TextField
                            value={this.state.verbatim}
                            name='verbatim'
                            onChange={this.onChange}
                            type='email'
                            id='verbatim'
                            label='Verbatim'
                        >
                        </TextField>
                    </Col>
                </Row>
                {this.state.formError ? (
                    <div className='errorMsg' style={{ textAlign: "center" }}>{this.state.formError}</div>
                ) : (
                        <Fragment />
                    )}
                <Button
                    color='light'
                    type='submit'
                    size='sm'
                    className='float-right mr-0'
                >
                    Save
                </Button>
            </Form>
        );
    }
}

export default connect(
    null,
    dispatch => bindActionCreators(mapDispatchToHubObjectBoardProps, dispatch)
)(OutQForm);
import React, { Fragment } from 'react';
import * as Logger from 'loglevel';
import { Row, Col, Card, Overlay, Form, Button, Tab, Tabs, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlusSquare, faMinusSquare, faCamera } from '@fortawesome/free-solid-svg-icons';
import { faChromecast } from '@fortawesome/free-brands-svg-icons';
import AboutSettings from '../../../../widgets/Settings/AboutSettings';

//redux: 
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as HubLogoActions from 'store/actions/HubLogoActions';
import * as HubSettingsActions from 'store/actions/HubSettingsActions';
import * as HubGeoActions from 'store/actions/HubGeoActions';

import * as HubConstant from 'util/HubConstant';
import Utilities, { UtilConstant } from 'hub-utilities';
import * as _ from 'lodash';

import 'styles/main-content/About.scss';
import 'styles/widgets/HubForm.scss';
import ProductionGroup from './ProductionGroup';
import MetaData from './Metadata';
import * as DataRenderHelper from 'util/DataRenderHelper'

class Hub extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            actualData: {},
            showAboutSettings: false,
            aboutSettingsTarget: null,
            cleanUpSettings: [],
            schedulesSettings: [],
            isEditingInput: false,

            //Gep fields:
            currentGeoTab: HubConstant.HUB_GEO_FIELD_STATE.ADD,
            geoList: [],
            currentGeo: {
                name: "",
                loc: [{ lat: "", lon: "" }, { lat: "", lon: "" }, { lat: "", lon: "" }],
                description: ""
            },
            imgErrorStatus: null
        };

        this.sampleData = {
            "hubInfo": {
                "hubId": "Unknown",
                "name": "Unknown",
                "description": "Unknown",
                "version": "Unknown",
                "createTime": "Unknown",
                "expireTime": "Unknown",
                "nodes": [
                    { "type": -1, "count": -1 }
                ],
                "users": -1
            },
            "environment": {
                "hostEnvironment": "Unknown",
                "identityServer": "Unknown",
                "connectionString": "Unknown",
            },
            "runtime": {
                "stories": -1,
                "elememnts": -1,
                "mediaSize": -1
            }
        };
        this.aboutSettingsContinerRef = React.createRef();

    }

    //Geo: =================================================

    setGeoName = (name) => {
        this.props.editGeoInput();
        this.setState({
            currentGeo: {
                name: name,
                loc: (!!this.state.currentGeo.loc) ? this.state.currentGeo.loc : undefined,
                description: (this.state.currentGeo.description !== undefined) ? this.state.currentGeo.description : undefined
            }
        });
    }

    addLoc = () => {
        this.props.editGeoInput();
        var newCurrentGeoLoc = this.state.currentGeo.loc;
        newCurrentGeoLoc.push({ lat: "", lon: "" });
        this.setState({
            currentGeo: {
                name: (this.state.currentGeo.name !== undefined) ? this.state.currentGeo.name : undefined,
                loc: newCurrentGeoLoc,
                description: (this.state.currentGeo.description !== undefined) ? this.state.currentGeo.description : undefined
            }
        });
    }

    removeLoc = (index) => {
        this.props.editGeoInput();
        var newCurrentGeoLoc = this.state.currentGeo.loc;
        newCurrentGeoLoc.splice(index, 1);
        this.setState({
            currentGeo: {
                name: (this.state.currentGeo.name !== undefined) ? this.state.currentGeo.name : undefined,
                loc: newCurrentGeoLoc,
                description: (this.state.currentGeo.description !== undefined) ? this.state.currentGeo.description : undefined
            }
        });
    }

    setGeoLoc = (isLat, loc, pointIndex) => {
        this.props.editGeoInput();
        if (/^\d*\.?\d*$/.test(loc)) {
            var newCurrentGeoLoc = this.state.currentGeo.loc;
            isLat ? newCurrentGeoLoc[pointIndex].lat = loc : newCurrentGeoLoc[pointIndex].lon = loc;
            this.setState({
                currentGeo: {
                    name: (this.state.currentGeo.name !== undefined) ? this.state.currentGeo.name : undefined,
                    loc: newCurrentGeoLoc,
                    description: (this.state.currentGeo.description !== undefined) ? this.state.currentGeo.description : undefined
                }
            });
        }
    }

    setGeoDescription = (description) => {
        this.props.editGeoInput();
        this.setState({
            currentGeo: {
                name: (this.state.currentGeo.name !== undefined) ? this.state.currentGeo.name : undefined,
                loc: (!!this.state.currentGeo.loc) ? this.state.currentGeo.loc : undefined,
                description: description
            }
        });
    }

    //======================================================

    Refresh = async (isUpdatingLicense) => {
        //Fetch schedule and purge settings here:
        await this.props.startSettingsFetch(isUpdatingLicense);
        this.setState({
            cleanUpSettings: [
                { key: "objectKeepTime", preTitle: "Send Stories to Recycle Bin after ", exTitle: " hours", type: HubConstant.HUB_SETTINGS_TYPE.PURGE, value: this.props.settings.purge.objectKeepTime, isAdvanced: false },
                { key: "gcKeepTime", preTitle: "Permanently delete items from Recycle Bin after ", exTitle: " hours", type: HubConstant.HUB_SETTINGS_TYPE.PURGE, value: this.props.settings.purge.gcKeepTime, isAdvanced: false },
                { key: "batchCount", preTitle: "Delete stories in batches of ", exTitle: "", type: HubConstant.HUB_SETTINGS_TYPE.PURGE, value: this.props.settings.purge.batchCount, isAdvanced: true },
                { key: "expiryExt", preTitle: "Extend expiry time by ", exTitle: " hours", type: HubConstant.HUB_SETTINGS_TYPE.PURGE, value: this.props.settings.purge.expiryExt, isAdvanced: true }
            ],

            schedulesSettings: [
                { key: "normal", preTitle: "Standard maintenance checks occur every ", exTitle: " seconds", type: HubConstant.HUB_SETTINGS_TYPE.SCHEDULE, value: this.props.settings.schedule.normal, isAdvanced: false },
                { key: "fast", preTitle: "Incomplete maintenance resumes after ", exTitle: " seconds", type: HubConstant.HUB_SETTINGS_TYPE.SCHEDULE, value: this.props.settings.schedule.fast, isAdvanced: false }
            ]
        });
    }

    async componentWillMount() {
        this.Refresh(false);
    }

    componentDidMount() {
        this.props.setActivePageName({ singular: "Hub", plural: "Hub", icon: faChromecast });
        if (!!this.props.hubInfo) {
            this.setState({ actualData: this.props.hubInfo });
        } else {
            this.setState({ actualData: this.sampleData });
        }
    }

    componentWillReceiveProps(newProps) {
        if (!_.isEqual(newProps.settings, this.props.settings) || !_.isEqual(newProps.hubInfo, this.props.hubInfo)) {
            var newCleanUpSettings = this.state.cleanUpSettings;
            var newSchedulesSettings = this.state.schedulesSettings;
            newCleanUpSettings.forEach(cleanUpSetting => cleanUpSetting.value = newProps.settings.purge[cleanUpSetting.key])
            newSchedulesSettings.forEach(scheduleSetting => scheduleSetting.value = newProps.settings.schedule[scheduleSetting.key])
            this.setState({
                cleanUpSettings: newCleanUpSettings,
                schedulesSettings: newSchedulesSettings,
                actualData: newProps.hubInfo
            });
        }
    }

    flipShowSettingState = (e) => {
        var newShowSetting = !this.state.showAboutSettings;
        this.setState({
            showAboutSettings: newShowSetting,
            aboutSettingsTarget: e.target
        });
    }

    setHubName = (val) => {
        var newHubData = this.state.actualData
        if (!!newHubData && newHubData["hubInfo"]) {
            newHubData["hubInfo"].customerName = val;
        }
        this.setState({ actualData: newHubData }, () => this.props.startEditingInput());
    }

    setHubSettingsInputVal(hubSettingKey, val, stateName) {
        this.props.startEditingInput(); //remove messages about PUT

        let keyIndex = this.state[stateName].findIndex(setting => setting.key === hubSettingKey)
        var newAboutSetting = Object.assign({}, this.state[stateName][keyIndex]);
        newAboutSetting.value = val;

        var newAboutSettingsArr = this.state[stateName];
        newAboutSettingsArr.splice(keyIndex, 1, newAboutSetting);

        this.setState({
            [stateName]: newAboutSettingsArr
        })
    }

    renderHubSettingsOnAdvanced(isAdvanced, stateName) {
        return this.state[stateName].map((settingInfo, index) =>
            (settingInfo.isAdvanced === isAdvanced) ?
                <p key={`hubSettingsAdvanced_${index}`} className="AboutInfo">
                    <span className="settingsName">{settingInfo.preTitle}
                        <Form.Control
                            key={settingInfo.key}
                            name={settingInfo.key}
                            type="text"
                            className="settingsInput"
                            placeholder={settingInfo.key}
                            value={settingInfo.value}
                            onChange={e => this.setHubSettingsInputVal(settingInfo.key, e.target.value, stateName)} />
                        {settingInfo.exTitle}</span>
                </p> : <Fragment key={`hubSettingsAdvanced_${index}`}></Fragment>
        )
    }

    renderHubSettings() {
        return (
            <Fragment>
                <div className="subSettingContainer">
                    <h5>DATA PURGE</h5>
                    <p className="purgeDesc"><i>Configure when old stories and media are automatically removed from this Hub</i></p>
                    {this.renderHubSettingsOnAdvanced(false, "cleanUpSettings")}
                </div>
                <div className="subSettingContainer">
                    <h5>ADVANCED</h5>
                    {this.renderHubSettingsOnAdvanced(true, "schedulesSettings")}
                </div>
            </Fragment>
        )
    }

    updateSettings = (isReset) => {
        if (!!this.props.settings) {
            var newScheduleObj = {};
            var newPurgeObj = {};

            if (!isReset) {
                let aboutSettings = this.state.cleanUpSettings.concat(this.state.schedulesSettings);

                for (var i = 0; i < aboutSettings.length; i++) {
                    switch (aboutSettings[i].type) {
                        case HubConstant.HUB_SETTINGS_TYPE.SCHEDULE:
                            newScheduleObj[aboutSettings[i].key] = parseInt(aboutSettings[i].value);
                            break;
                        case HubConstant.HUB_SETTINGS_TYPE.PURGE:
                            newPurgeObj[aboutSettings[i].key] = parseInt(aboutSettings[i].value);
                            break;
                        default:
                            break;
                    }
                }
            }
            this.props.startUpdateSettings(newScheduleObj, newPurgeObj, !isReset);
        }
    }

    onGeoButtonClick = async (isDelete) => {

        var locPolygon = this.state.currentGeo.loc;

        switch (parseInt(this.state.currentGeoTab)) {
            case HubConstant.HUB_GEO_FIELD_STATE.ADD:
                locPolygon.push(this.state.currentGeo.loc[0]);
                await this.props.startGeoAdd(this.state.currentGeo.name, locPolygon, this.state.currentGeo.description);
                locPolygon.pop();
                break;
            case HubConstant.HUB_GEO_FIELD_STATE.MODIFY:
                if (!isDelete) {
                    await this.props.startGeoUpdate(this.state.currentGeo.name, locPolygon, this.state.currentGeo.description);
                } else {
                    await this.props.startGeoDelete(this.state.currentGeo.name);

                    if (!!this.props.geoObj && this.props.geoObj.geoAreaList.length > 0) {
                        await this.setState({
                            currentGeo: {
                                name: this.props.geoObj.geoAreaList[0].geoDesc.areaName,
                                loc: this.props.geoObj.geoAreaList[0].geoDesc.geoData,
                                description: this.props.geoObj.geoAreaList[0].geoDesc.description
                            }
                        })
                    } else {
                        await this.setState({
                            currentGeo: {
                                name: undefined,
                                loc: undefined,
                                description: undefined
                            }
                        })
                    }
                }
                break;
            default:
                break;
        }
    }

    renderUpdateStatus(success, error) {
        if (!!error) {
            return <Row><Col xs="12"><p className="UpdateError">Error {error.status}: {error.statusText}</p></Col></Row>
        } else if (!!success) {
            return <Row><Col xs="12"><p style={{color: "blue"}} className="Success">{success}</p></Col></Row>
        }
    }

    renderGeoStatus() {
        if (!!this.props.geoError) {
            return <p className="UpdateError">Error {this.props.geoError.status}: {this.props.geoError.statusText}</p>
        } else if (!!this.props.geoSuccess) {
            return <p className="Success">{this.props.geoSuccess}</p>
        }
    }

    renderGeoDropdown(geoList) {
        return geoList.map((geoElem, index) => {
            return (<Dropdown.Item eventKey={index} onSelect={() =>
                this.setState({
                    currentGeo: {
                        name: geoElem.geoDesc.areaName,
                        loc: geoElem.geoDesc.geoData,
                        description: geoElem.geoDesc.description
                    }
                })
            }>{geoElem.geoDesc.areaName}</Dropdown.Item>)
        })
    }


    renderGeoFields(hasGeoDropdown, hasGeoName, hasGeoLoc, hasGeoDescription, hasDeleteButton) {
        return (
            <Fragment>
                {
                    hasGeoDropdown ?
                        <div>
                            <span><b>Location Chosen:</b>&nbsp;</span>
                            <Dropdown className="pageDropdown">
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    {this.state.currentGeo.name === undefined ? "No Available Location" : this.state.currentGeo.name}
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="pageDropdownItems">
                                    {this.renderGeoDropdown(!!this.props.geoObj ? this.props.geoObj.geoAreaList : [])}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div> : <Fragment></Fragment>
                }
                {hasGeoName && this.state.currentGeo.description !== undefined ?
                    <div>
                        <span><b>Name:</b>&nbsp;</span>
                        <span className="settingsInputSpan"><Form.Control
                            key="geoName"
                            type="text"
                            className="settingsInput"
                            value={this.state.currentGeo.name}
                            onChange={e => this.setGeoName(e.target.value)} /></span>
                    </div> : <Fragment></Fragment>}
                {hasGeoLoc && !!this.state.currentGeo.loc ?
                    <div>
                        <span><b>Location:</b>&nbsp;</span>
                        <span onClick={this.addLoc}>
                            <OverlayTrigger
                                key="bottom"
                                placement="bottom"
                                overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                        Add Location
                                </Tooltip>
                                }
                            >
                                <FontAwesomeIcon className="addLocButton" icon={faPlusSquare} />
                            </OverlayTrigger>
                        </span>
                        {this.state.currentGeo.loc.map((loc, index) => {
                            return (
                                <div>
                                    <span className="settingsInputSpan">
                                        <Form.Control
                                            key={`Latitude${index}`}
                                            placeholder="Latitude"
                                            type="text"
                                            className="settingsInput geoLatInput"
                                            value={loc.lat}
                                            onChange={e => this.setGeoLoc(true, e.target.value, index)} />
                                        <Form.Control
                                            key={`Longitude${index}`}
                                            placeholder="Longitude"
                                            type="text"
                                            className="settingsInput geoLonInput"
                                            value={loc.lon}
                                            onChange={e => this.setGeoLoc(false, e.target.value, index)} />
                                        <OverlayTrigger
                                            key="bottom"
                                            placement="bottom"
                                            overlay={
                                                <Tooltip id={`tooltip-bottom`}>
                                                    Remove Location
                                                </Tooltip>
                                            }
                                        >
                                            <FontAwesomeIcon onClick={() => this.removeLoc(index)} className={`addLocButton remove ${(this.state.currentGeo.loc.length <= 3) ? "disabled" : ""}`} icon={faMinusSquare} />
                                        </OverlayTrigger>
                                    </span>
                                </div>
                            )
                        })
                        }
                    </div> : <Fragment></Fragment>}
                {hasGeoDescription && this.state.currentGeo.description !== undefined ?
                    <div>
                        <span><b>Description:</b>&nbsp;</span>
                        <span className="settingsInputSpan"><Form.Control
                            key="geoName"
                            type="text"
                            className="settingsInput geoInputs"
                            value={this.state.currentGeo.description}
                            onChange={e => this.setGeoDescription(e.target.value)} /></span>
                    </div> : <Fragment></Fragment>}

                {this.renderGeoStatus()}

                {this.state.currentGeo.name !== undefined ?
                    <Button onClick={() => this.onGeoButtonClick(false)}>
                        {this.state.currentGeoTab === HubConstant.HUB_GEO_FIELD_STATE.ADD ? "Add Location"
                            : this.state.currentGeoTab === HubConstant.HUB_GEO_FIELD_STATE.MODIFY ? "Modify Location"
                                : "Big Goof!"}
                    </Button> : <Fragment></Fragment>
                }

                {hasDeleteButton && this.state.currentGeo.name !== undefined ?
                    <Button className="deleteBtn" onClick={() => this.onGeoButtonClick(true)}>
                        <FontAwesomeIcon icon={faTrash} />
                    </Button> : <Fragment></Fragment>}
            </Fragment>
        )
    }

    onDropdownSelectRenderGraphics = async (tab) => {
        if (tab !== this.state.currentGeoTab) {

            this.props.editGeoInput();

            await this.props.startGeoFetch();
            this.setState({ currentGeoTab: tab }, () => {
                if (tab === HubConstant.HUB_GEO_FIELD_STATE.ADD) {
                    this.setState({
                        currentGeo: {
                            name: "",
                            loc: [{ lat: "", lon: "" }, { lat: "", lon: "" }, { lat: "", lon: "" }],
                            description: ""
                        }
                    })
                } else if ((!!this.props.geoObj && this.props.geoObj.geoAreaList.length > 0)) {
                    this.setState({
                        currentGeo: {
                            name: this.props.geoObj.geoAreaList[0].geoDesc.areaName,
                            loc: this.props.geoObj.geoAreaList[0].geoDesc.geoData,
                            description: this.props.geoObj.geoAreaList[0].geoDesc.description
                        }
                    })
                } else {
                    this.setState({
                        currentGeo: {
                            name: undefined,
                            loc: undefined,
                            description: undefined
                        }
                    })
                }

            })
        }

    }

    renderGeographics() {
        return (
            <Row>
                <Col xs="8">
                    <Card.Title>Geographics:</Card.Title>
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={this.state.currentGeoTab}
                        onSelect={this.onDropdownSelectRenderGraphics}
                    >
                        <Tab eventKey={HubConstant.HUB_GEO_FIELD_STATE.ADD} title="Add">
                            {this.renderGeoFields(false, true, true, true, false)}
                        </Tab>
                        <Tab eventKey={HubConstant.HUB_GEO_FIELD_STATE.MODIFY} title="Modify">
                            {this.renderGeoFields(true, true, true, true, true)}
                        </Tab>
                    </Tabs>

                </Col>
                <Col className="globeCol" xs="4">
                    <img className="AboutStatsLogo globe" src="/images/orange-earth.svg" alt="Geographics" />
                </Col>
            </Row>)
    }

    verifyFileSize(event) {
        return new Promise((resolve, reject) => {
            if (event.target.files && event.target.files[0]) {
                var img = document.createElement("img");
                this.setState({ backgroundImageFile: event.target.files[0] });

                img.onload = function () {
                    Logger.debug(this.width + " " + this.height);
                    resolve(this.width = 480 && this.height === 130);
                }

                var reader = new FileReader();
                reader.onloadend = function (ended) {
                    img.src = ended.target.result;
                }
                reader.readAsDataURL(event.target.files[0]);
            }
        })
    }

    fileUploadHandler = async (event) => {
        //Restrict file size to be 480px * 130px before accepting:
        var file = event.target.files[0];
        // Logger.debug(`Uploading file ${event.target.value} started`);
        let selectedFilename = Utilities.getFileNameFromPath(event.target.value);

        if (await this.verifyFileSize(event)) {
            this.props.startUpdateLogo(selectedFilename, true, file);
            this.setState({ imgErrorStatus: null });
        } else {
            this.setState({ imgErrorStatus: "Image size must be 480 * 130 px!!" });
        }
    };

    renderGeneralSettings() {
        Logger.debug(this.state.actualData);
        return (
            <Fragment>
                <Row>
                    <Col xs="4" ref={this.aboutSettingsContinerRef} className="LogoOuterContainer" onClick={() => this.refs.logoUploader.click()}>
                        <div className="LogoContainer"
                            ref={this.aboutSettingsContinerRef}
                            onClick={this.flipShowSettingState}>
                            {
                                (!!this.props.logoObj) ?
                                    <img className="AboutLogo" src={this.props.logoObj} alt="Logo" />
                                    :
                                    <img className="AboutLogo" src="/images/HubBrandingDefault-1.2-Grey.png" alt="Logo" />
                            }
                            <FontAwesomeIcon icon={faCamera} className="cam"></FontAwesomeIcon>
                            <Form.Control
                                type="file"
                                accept="image/png"
                                ref="logoUploader"
                                className="custom-file-input rowEditorMediaBrowser"
                                id="inputGroupFile01"
                                aria-describedby="inputGroupFileAddon01"
                                style={{ display: "none" }}
                                onChange={this.fileUploadHandler} />
                            {/* <div className="faContainer">
                                <FontAwesomeIcon icon={faCogs}></FontAwesomeIcon>
                                <FontAwesomeIcon icon={faCaretDown}></FontAwesomeIcon>
                            </div> */}
                        </div>
                        <Overlay
                            show={this.state.showAboutSettings}
                            target={this.state.target}
                            placement="bottom"
                            container={this.aboutSettingsContinerRef}>
                            <AboutSettings></AboutSettings>
                        </Overlay>
                    </Col>
                    <Col xs="8" className="LogoDescription">
                        <h5>HUB LOGO</h5>
                        <p>Click image to replace it</p>
                        <p style={!!this.state.imgErrorStatus ? { color: 'red' } : {}}><i>Image must be exactly 480px wide and 130 high in .png format</i></p>
                    </Col>
                </Row>
                <Row className="HubNameRow">
                    <Col xs="12">
                        <h5>HUB NAME</h5>
                    </Col>
                </Row>
                <Row className="HubNameRow">
                    <Col xs="8">
                        <Form.Control
                            key="hubInfoName"
                            type="text"
                            placeholder="Hub Name"
                            className="settingsInput"
                            value={!!this.state.actualData && !!this.state.actualData["hubInfo"] ? this.state.actualData["hubInfo"].customerName !== undefined ? this.state.actualData["hubInfo"].customerName : this.state.actualData["hubInfo"].name : ""}
                            onChange={e => this.setHubName(e.target.value)} />
                        <Button className="updateButton" onClick={() => {
                            if (!!this.state.actualData["hubInfo"].customerName) {
                                this.props.setHubName(this.state.actualData["hubInfo"].customerName)
                            } else if (!!this.state.actualData["hubInfo"].name) {
                                //reset it back to the OG name
                                this.props.setHubName(this.state.actualData["hubInfo"].name);
                                var newState = this.state.actualData;
                                newState["hubInfo"].customerName = this.state.actualData["hubInfo"].name;
                                this.setState({ actualData: newState });
                            } else {
                                //reset it back to the BurliNewsHub AppName
                                this.props.setHubName(this.state.actualData["appName"]);
                                var newState = this.state.actualData;
                                newState["hubInfo"].customerName = this.state.actualData["appName"];
                                this.setState({ actualData: newState });
                            }
                        }}>Update</Button>
                    </Col>
                    <Col xs="4">
                        <Button 
                            className="updateButton updateLicensingButton" 
                            onClick={() => this.Refresh(true)}>Update Licensing</Button>
                    </Col>
                </Row>
                {this.renderUpdateStatus(
                    !!this.props.updateLicensingSuccess ? this.props.updateLicensingSuccess : this.props.setNameSuccess, 
                    !!this.props.updateLicensingError ? this.props.updateLicensingError : this.props.setNameError)}
                <Row>
                    <Col xs="12" className="CardCol">
                        <Card className="HubInfoCard">
                            <Card.Header>
                                <h5>HUB DETAILS</h5>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col xs="6" className="HubCol">
                                        {DataRenderHelper.renderHubInfo([
                                            { key: "hubId", title: "GUID", type: HubConstant.HUB_INFO_TYPE.HUB_INFO },
                                            { key: "createTime", title: "Created", type: HubConstant.HUB_INFO_TYPE.HUB_INFO },
                                            { key: "appVersion", title: "Client Version", type: HubConstant.HUB_INFO_TYPE.GENERAL },
                                            { key: "appVersion", title: "API Version", type: HubConstant.HUB_INFO_TYPE.GENERAL }
                                        ], this.state.actualData)}
                                    </Col>
                                    <Col xs="6">
                                        {DataRenderHelper.renderHubInfo([
                                            { key: "description", title: "Hub Description", type: HubConstant.HUB_INFO_TYPE.HUB_INFO },
                                            { key: "users", title: "Number of Active Users", type: HubConstant.HUB_INFO_TYPE.HUB_INFO },
                                            { key: "nodes", title: "Number of Production Centres", type: HubConstant.HUB_INFO_TYPE.NODES },
                                            { key: "expireTime", title: "Licensed Until End of", type: HubConstant.HUB_INFO_TYPE.HUB_INFO }
                                        ], this.state.actualData)}
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs="12" className="CardCol">
                        <Card className="HubInfoCard">
                            <Card.Header>
                                <h5>CURRENT DATA</h5>
                                <p><i>Summed data from across this Hub</i></p>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col xs="12">
                                        {DataRenderHelper.renderHubInfo([
                                            { key: "stories", title: "Total Stories", type: HubConstant.HUB_INFO_TYPE.RUNTIME },
                                            { key: "elememnts", title: "Total Media Elements", type: HubConstant.HUB_INFO_TYPE.RUNTIME },
                                            { key: "mediaSize", title: "Total Media File Storage", type: HubConstant.HUB_INFO_TYPE.RUNTIME, isStorageSize: true }
                                        ], this.state.actualData)}
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Fragment>
        )
    }

    renderCleanUpSettings() {
        return (
            <Fragment>
                <Row>
                    <Col xs="12" className="CardCol">
                        <Card className="HubInfoCard">
                            <Card.Header>
                                <h5>DATA PURGE</h5>
                                <p className="purgeDesc"><i>Configure when old stories and media are automatically removed from this Hub</i></p>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col xs="12">
                                        {this.renderHubSettingsOnAdvanced(false, "cleanUpSettings")}
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" className="CardCol">
                        <Card className="HubInfoCard">
                            <Card.Header>
                                <h5>ADVANCED</h5>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col xs="12">
                                        {this.renderHubSettingsOnAdvanced(true, "cleanUpSettings")}
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {this.renderUpdateStatus(this.props.updateSettingsSuccess, this.props.updateSettingsError)}
                <Row>
                    <Button className="updateButton" onClick={() => this.updateSettings(false)}>Save Changes</Button>
                    <Button className="resetButton" onClick={() => this.updateSettings(true)} disabled={this.props.justFinishedReset}>Reset to Default</Button>
                </Row>
            </Fragment>
        )
    }

    renderScheduleSettings() {
        return (
            <Fragment>
                <Row>
                    <Col xs="12" className="CardCol">
                        <Card className="HubInfoCard">
                            <Card.Header>
                                <h5>SCHEDULED EVENTS</h5>
                                <p className="purgeDesc"><i>Wake-up intervals for scheduled maintenance events</i></p>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col xs="12">
                                        {this.renderHubSettingsOnAdvanced(false, "schedulesSettings")}
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {this.renderUpdateStatus(this.props.updateSettingsSuccess, this.props.updateSettingsError)}
                <Row>
                    <Button className="updateButton" onClick={() => this.updateSettings(false)}>Save Changes</Button>
                    <Button className="resetButton" onClick={() => this.updateSettings(true)} disabled={this.props.justFinishedReset}>Reset to Default</Button>
                </Row>
            </Fragment>
        )
    }

    renderNodeGroupsSettings() {
        return <ProductionGroup {...this.props}></ProductionGroup>
    }

    renderMetadatatSettings() {
        return <MetaData {...this.props}></MetaData>
    }

    render() {
        Logger.debug(this.props.activePageName);
        return (
            <div className="Hub">
                <Row className="HubTabRow">
                    <Col xs="12">
                        <Tabs
                            id="controlled-tab-example"
                            defaultActiveKey="general"
                            mountOnEnter //need these ones to stop tabs from rendering other pages... or else very laggy and bad performance...
                            unmountOnExit
                        >
                            <Tab eventKey="general" title="General">
                                {this.renderGeneralSettings()}
                            </Tab>
                            <Tab eventKey="cleanUp" title="Clean-up">
                                {this.renderCleanUpSettings()}
                            </Tab>
                            <Tab eventKey="schedules" title="Schedules">
                                {this.renderScheduleSettings()}
                            </Tab>
                            {/* <Tab eventKey="regions" title="Regions">
                                Coming Soon!
                            </Tab> */}
                            <Tab eventKey="nodeGroups" title="Production Groups">
                                {this.renderNodeGroupsSettings()}
                            </Tab>
                            <Tab eventKey="metadata" title="Data Fields">
                                {this.renderMetadatatSettings()}
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
            </div>
        );
    }
}

const mapLogoAndSettingStatesToProps = (state) => {
    return {
        settings: state.hubConfig.hubSettings.settings,
        hubInfo: state.hubConfig.hubSettings.hubInfo,

        setNameSuccess: state.hubConfig.hubSettings.setNameSuccess,
        updateSettingsSuccess: state.hubConfig.hubSettings.updateSettingsSuccess,
        setNameError: state.hubConfig.hubSettings.setNameError,
        updateSettingsError: state.hubConfig.hubSettings.updateSettingsError,

        // bascially just fetching data from hub as 'update licensing'
        updateLicensingSuccess: state.hubConfig.hubSettings.updateLicensingSuccess,
        updateLicensingError: state.hubConfig.hubSettings.updateLicensingError,

        justFinishedReset: state.hubConfig.hubSettings.justFinishedReset,

        logoObj: state.hubConfig.hubLogo.logoObj,
        geoObj: state.hubConfig.hubGeo.geoObj,
        geoError: state.hubConfig.hubGeo.error,
        geoSuccess: state.hubConfig.hubGeo.success,
    }
}

export default withRouter(connect(
    mapLogoAndSettingStatesToProps,
    dispatch => bindActionCreators({
        ...HubSettingsActions.mapSettingsDispatcherToProps,
        ...HubLogoActions.mapLogoDispatcherToProps,
        ...HubGeoActions.mapGeoDispatcherToProps
    }, dispatch)
)(Hub));

import React, { Fragment } from 'react';
import * as Logger from 'loglevel';
import { DataTable, TableToolType, TableRowAction } from 'hub-dashboard-framework'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

import { mapMetadataStateToProps, mapDispatchToMetadataProps } from 'store/actions/HubMetadataActions';
import * as HubConstant from 'util/HubConstant'
import Utilities, { UtilConstant } from 'hub-utilities';
import authService from 'components/api-authorization/AuthorizeService';

class Metadata extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fetchedData: null
        }
        this.headerConfig = {
            id: -1,
            description: "",
            FieldName: { val: "", canSort: true },
            DataType: { val: "", canSort: true },
            'Controlled?': { val: "", canSort: false },
            Values: { val: "", canSort: false },
            rowActions: [
                { action: TableRowAction.EDIT_METADATA, minAuthorityRequired: UtilConstant.HUB_USER_TYPE.HUB_ADMIN },
                { action: TableRowAction.DELETE_ROW, minAuthorityRequired: UtilConstant.HUB_USER_TYPE.HUB_ADMIN }
            ]
        };
    }

    async componentWillMount() {
        //flush success/error msg:
        this.props.editMetadata();

        //need to fetch nodes, for the ones without group
        await this.props.getMetadata();
        //objectList is the redux state
        this.setState({ fetchedData: await this.fetchData(this.props.metaData) });
    }

    //To properly map the fetchedData variable to redux:
    componentWillReceiveProps(nextPropsFromRedux) {
        //Don't requestNodes here, or else datatable would fetch twice in the beginning, and 
        //mistreat it as new data added

        //Can't compare here, or else there will be some weird async issue
        //Where Logger.debug renders later than data is fetched and o.hubNodes 'appears' to be there
        Logger.debug(nextPropsFromRedux)
        this.setState({ fetchedData: this.fetchData(nextPropsFromRedux.metaData) });
    }

    onDeleteRow = (id) => {
        Logger.debug(id);
        this.props.deleteMetadata(id);
    }

    formatVocab(keyword) {
        if (Utilities.verifyDataHasCorrectDataType(keyword, UtilConstant.HUB_DATA_TYPE.DATETIME)) {
            return Utilities.formatObjectDate(keyword + ".000Z", UtilConstant.HUB_TIME_FORMAT.CANADIAN, true)
        } else {
            return keyword;
        }
    }

    fetchData(objects) {
        if (objects !== undefined && objects != null && objects.length > 0) {
            return objects.map(o => {
                Logger.debug("o: ", JSON.parse(JSON.stringify(o)));
                var convertedDataType = Object.keys(UtilConstant.HUB_DATA_TYPE).find(key => UtilConstant.HUB_DATA_TYPE[key] === o.dataType);
                if (!!convertedDataType) {
                    convertedDataType = convertedDataType.charAt(0) + convertedDataType.slice(1).toLowerCase()
                    return (
                        {
                            //Lower case keys won't get posted to table
                            id: o.fieldId,
                            type: HubConstant.HUB_METADATA,
                            description: o.description,
                            canDelete: o.fieldId >= 10000,
                            FieldName: { val: o.fieldName, canSort: true },
                            DataType: { val: convertedDataType, actualVal: o.dataType, canSort: true },
                            //formVal is for the values being passed to popup Forms:
                            'Controlled?': { val: o.isControlledVocabulary ? "✓" : "✗", canSort: false, color: o.isControlledVocabulary ? 'green' : 'red' },
                            Values: {
                                val: !!o.vocabulary ?
                                    o.vocabulary.map(vocab => this.formatVocab(vocab.keyword)).join("; ") :
                                    "Loading..."

                                , formVal: o.vocabulary/*o.vocabulary.map(vocab => vocab.keyword)*/, canSort: false
                            },
                            rowActions: [
                                { action: TableRowAction.EDIT_METADATA, form: HubConstant.HUB_UPDATE_METADATA_FORM, minAuthorityRequired: UtilConstant.HUB_USER_TYPE.HUB_ADMIN },
                                { action: TableRowAction.DELETE_ROW, minAuthorityRequired: UtilConstant.HUB_USER_TYPE.HUB_ADMIN }
                            ]
                        }
                    );
                } else {
                    /// console.error("Converted Data Type fetched is null, unable to fetch data type! Might be a caching problem!");
                    return [];
                }
            })
        } else {
            return [];
        }
    }

    displayDatatable() {
        return (
            <Row className="tableRow">
                <Col xs="12">
                    <DataTable canDeleteRow={false}
                        headerConfig={this.headerConfig}
                        data={this.state.fetchedData}
                        tools={[
                            { action: TableToolType.ADD, form: HubConstant.HUB_CREATE_METADATA_FORM },
                            { action: TableToolType.SHOW_NUM_ROWS_DROPDOWN },
                            { action: TableToolType.PAGE_DISPLAY },
                            { action: TableToolType.PAGINATION },
                            { action: TableToolType.MESSAGE }]}
                        dataAction={this.props.action}
                        activeTableName={{ singular: "Data Field", plural: "Data Fields" }}
                        secondaryObjectType={HubConstant.HUB_METADATA}
                        onDeleteRow={this.onDeleteRow}
                        message={
                            this.props.success ? { type: UtilConstant.HUB_MESSAGE_TYPE.SUCCESS, message: this.props.success } :
                                this.props.error ? { type: UtilConstant.HUB_MESSAGE_TYPE.ERROR, message: this.props.error } :
                                    { type: UtilConstant.HUB_MESSAGE_TYPE.NONE, message: "" }
                        }
                        authorizeService={authService}
                        {...this.props}></DataTable>
                </Col>
            </Row>
        )
    }

    render() {
        //DataTable happens during component will mount, make sure that data is fetched before table is rendered:
        return (
            <div className="Metadata">
                <Row className="structureRow">
                    <Col xs="12">
                        {(!!this.state.fetchedData && this.state.fetchedData != null) ? this.displayDatatable() : <Fragment key="0"></Fragment>}
                    </Col>
                </Row>
            </div>
        )
    }
}


export default withRouter(connect(
    mapMetadataStateToProps,
    dispatch => bindActionCreators(mapDispatchToMetadataProps, dispatch),
    null,
    { pure: false } //Need to ensure pure is false, such that it will do a deep compare instead of shallow compare with changed props
)(Metadata));
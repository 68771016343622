import React, { Fragment } from 'react';
import * as Logger from 'loglevel';
import { DataTable, TableToolType, TableRowAction } from 'hub-dashboard-framework'

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

import { mapMetadataStateToProps, mapDispatchToMetadataProps } from 'store/actions/HubMetadataActions';

import { EditorConstant } from 'hub-dashboard-framework';

import * as HubConstant from 'util/HubConstant'
import Utilities, { UtilConstant } from 'hub-utilities';
import authService from 'components/api-authorization/AuthorizeService';

class MetadataPerNewsItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fetchedData: null
        }
        this.headerConfig = {
            id: -1,
            FieldName: { val: "", canSort: true },
            DataType: { val: "", canSort: true },
            Values: { val: "", canSort: false, shouldShowCheckbox: true },
            rowActions: [
                { action: TableRowAction.DELETE_ROW_VALUE, minAuthorityRequired: UtilConstant.HUB_USER_TYPE.HUB_USER },
                { action: TableRowAction.ADD_ROW_EDITOR, minAuthorityRequired: UtilConstant.HUB_USER_TYPE.HUB_USER }
            ]
        };
    }

    async componentWillMount() {
        //objectList is the redux state
        this.setState({ fetchedData: this.fetchData(this.props.formData) });
    }

    //To properly map the fetchedData variable to redux:
    componentWillReceiveProps(nextPropsFromRedux) {
        //Don't requestNodes here, or else datatable would fetch twice in the beginning, and 
        //mistreat it as new data added
        Logger.debug(nextPropsFromRedux.formData)
        this.setState({ fetchedData: this.fetchData(nextPropsFromRedux.formData) });
    }

    formatValue(keyword) {
        if (Utilities.verifyDataHasCorrectDataType(keyword, UtilConstant.HUB_DATA_TYPE.DATETIME)) {
            return Utilities.formatObjectDate(keyword + ".000Z", UtilConstant.HUB_TIME_FORMAT.CANADIAN, true)
        } else {
            return keyword;
        }
    }

    fetchData(objects) {
        if (objects !== undefined && objects != null && objects.length > 0) {
            //need to check for valid dataType, a dataType 0 polluted the data...
            return objects.filter(o => o.dataType >= 1 && o.dataType <= 4).map(o => {
                var convertedDataType = Object.keys(UtilConstant.HUB_DATA_TYPE).find(key => UtilConstant.HUB_DATA_TYPE[key] === o.dataType);
                if (!!convertedDataType) {
                    convertedDataType = convertedDataType.charAt(0) + convertedDataType.slice(1).toLowerCase()
                    return (
                        {
                            //Lower case keys won't get posted to table
                            id: o.fieldId,
                            type: HubConstant.HUB_METADATA_PER_NEWSITEM,

                            rowEditorData: [
                                o.isControlledVocabulary ? { type: EditorConstant.DataEditorType.DROP_DOWN, dataType: o.dataType, dropdownVals: o.availableControlledVocabularies, dropdownValKey: "keyword" } :
                                    o.dataType === UtilConstant.HUB_DATA_TYPE.TEXT || o.dataType === UtilConstant.HUB_DATA_TYPE.KEYWORD ?
                                        { placeholder: o.fieldName, type: EditorConstant.DataEditorType.TEXT_FIELD, dataType: UtilConstant.HUB_DATA_TYPE.TEXT } :
                                        o.dataType === UtilConstant.HUB_DATA_TYPE.DECIMAL ?
                                            { placeholder: o.fieldName, type: EditorConstant.DataEditorType.TEXT_FIELD, dataType: UtilConstant.HUB_DATA_TYPE.DECIMAL } :
                                            { placeholder: o.fieldName, type: EditorConstant.DataEditorType.DATE_TIME_FIELD, dataType: UtilConstant.HUB_DATA_TYPE.DATETIME }
                            ],

                            FieldName: { val: o.fieldName, canSort: true },
                            DataType: { val: convertedDataType, actualVal: o.dataType, canSort: true },
                            //formVal is for the values being passed to popup Forms:
                            Values: {
                                val: !!o.values ?
                                    o.values.map(value => this.formatValue(value.value)).join("  ; ") :
                                    "Loading..."

                                , formVal: o.values, canSort: false, shouldShowCheckbox: true
                            },
                            rowActions: [
                                { action: TableRowAction.DELETE_ROW_VALUE, minAuthorityRequired: UtilConstant.HUB_USER_TYPE.HUB_USER },
                                { action: TableRowAction.ADD_ROW_EDITOR, minAuthorityRequired: UtilConstant.HUB_USER_TYPE.HUB_USER }
                            ]
                        }
                    );
                } else return [];
            })
        } else {
            return [];
        }
    }

    displayDatatable() {
        return (
            <Row className="tableRow">
                <Col xs="12">
                    <DataTable canDeleteRow={false}
                        headerConfig={this.headerConfig}
                        data={this.state.fetchedData}
                        tools={[
                            { action: TableToolType.FILTER_SEARCH },
                            { action: TableToolType.SHOW_NUM_ROWS_DROPDOWN },
                            { action: TableToolType.PAGE_DISPLAY },
                            { action: TableToolType.PAGINATION },
                            { action: TableToolType.DELETE_SELECTED }]}
                        rowEditorConfig={{ hasInitialVal: false, isMultiColVal: true }}
                        dataAction={this.props.action}
                        activeTableName={
                            {
                                singular: `${this.props.newsItemType === HubConstant.HUB_OBJECT_ELEMENT ? "Media" : "Story"} Data`,
                                plural: `${this.props.newsItemType === HubConstant.HUB_OBJECT_ELEMENT ? "Media" : "Story"} Data`
                            }
                        }
                        secondaryObjectType={HubConstant.HUB_METADATA}
                        onSubmitRowEditor={this.props.onLocalAddNewsItemMetadata}
                        authorizeService={authService}
                        {...this.props}></DataTable>
                </Col>
            </Row>
        )
    }

    render() {
        //DataTable happens during component will mount, make sure that data is fetched before table is rendered:
        return (
            <div className="Metadata">
                <Row className="structureRow">
                    <Col xs="12">
                        {(!!this.state.fetchedData && this.state.fetchedData != null) ? this.displayDatatable() : <Fragment key="0"></Fragment>}
                    </Col>
                </Row>
            </div>
        )
    }
}


export default withRouter(connect(
    mapMetadataStateToProps,
    dispatch => bindActionCreators(mapDispatchToMetadataProps, dispatch)
)(MetadataPerNewsItem));
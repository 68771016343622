import React from 'react';
import * as Logger from 'loglevel';
import * as HubConstant from "util/HubConstant"
//import StoryFrom from './Story/StoryForm';
import IntegratedNewsItemForm from './Story/IntegratedNewsItemForm';
import StoryForm from './Story/StoryForm';
import ElementForm from './Element/ElementForm';
import HubUserForm from './User/HubUserForm'
import NewPasswordForm from './User/ResetPasswordForm'
import ProductionGroupForm from './Hub/ProductionGroupForm'
import MetadataForm from './Metadata/MetadataForm';
import MetadataPerNewsItemForm from './Metadata/MetadataPerNewsItemForm';
import OutQForm from './Media/OutQForm'
import HubNodeForm from './Node/HubNodeForm'

export class FormSelector {
    selectUserProfileForm = () => {
        return null;
    }
    selectObjectForm = (objectType, formObject) => {
        let formDescriptor = null;
        switch (objectType) {
            case HubConstant.HUB_OBJECT_STORY:
                formDescriptor = {
                    formTitle: !!formObject ? 'Story Properties' : 'Create New Story',
                    component: (props) => (<IntegratedNewsItemForm form={StoryForm} {...props} payload={{ type: HubConstant.HUB_OBJECT_STORY }} />)
                };
                break;
            case HubConstant.HUB_OBJECT_ELEMENT:
                formDescriptor = {
                    formTitle: !!formObject ? 'Media Element Properties' : 'Upload Media File',
                    component: (props) => (<IntegratedNewsItemForm form={ElementForm} {...props} payload={{ type: HubConstant.HUB_OBJECT_ELEMENT }} />)
                };
                break;
            default:
                break;
        }
        return formDescriptor;
    }

    selectUserForm = (user, formType) => {
        let formDescriptor = null;
        Logger.debug(user);
        Logger.debug(formType);
        switch (formType) {
            case HubConstant.HUB_CREATE_NEW_USER_FORM:
                formDescriptor = { formTitle: `Create User`, component: HubUserForm, payload: user }
                break;
            case HubConstant.HUB_UPDATE_USER_PASSWORD_FORM:
                formDescriptor = { formTitle: `Reset Password - ${user.LoginName.val}`, component: NewPasswordForm, payload: user }
                break;
            case HubConstant.HUB_UPDATE_USER:
                formDescriptor = { formTitle: `Update User`, component: HubUserForm, payload: user }
                break;
            default:
                break;
        }
        return formDescriptor;
    }

    selectHubForm = (hubObj, formType) => {
        let formDescriptor = null;
        Logger.debug(hubObj);
        Logger.debug(formType);
        switch (formType) {
            case HubConstant.HUB_UPDATE_PRODUCTION_GROUP:
                formDescriptor = { formTitle: `Edit Group "${hubObj.data.Group.val}"`, component: ProductionGroupForm, payload: hubObj }
                break;
            case HubConstant.HUB_CREATE_PRODUCTION_GROUP_FORM:
                formDescriptor = { formTitle: `Create Group`, component: ProductionGroupForm, payload: null }
                break;
            default:
                break;
        }
        return formDescriptor;
    }

    selectMetadataForm = (hubObj, formType) => {
        let formDescriptor = null;
        Logger.debug(hubObj);
        Logger.debug(formType);
        switch (formType) {
            case HubConstant.HUB_UPDATE_METADATA_FORM:
                formDescriptor = { formTitle: `Edit Data Field "${hubObj.FieldName.val}"`, component: MetadataForm, payload: hubObj }
                break;
            case HubConstant.HUB_CREATE_METADATA_FORM:
                formDescriptor = { formTitle: `Create Data Field`, component: MetadataForm, payload: null }
                break;
            case HubConstant.HUB_UPDATE_NEWSITEM_METADATA_FORM:
                formDescriptor = { formTitle: `Edit ${hubObj.type === HubConstant.HUB_OBJECT_ELEMENT ? "Media" : "Story"} Data`, component: MetadataPerNewsItemForm, payload: hubObj }
                break;
            default:
                break;
        }
        return formDescriptor;
    }

    selectForm = (formType, formObject) => {
        Logger.debug(formObject);
        let formDescriptor = null;
        switch (formType) {
            case HubConstant.HUB_USER_PROFILE_FORM:
                formDescriptor = this.selectUserProfileForm()
                break;
            //case HubConstant.HUB_NEWS_OBJECT_FORM: deprecated
            case HubConstant.HUB_STORY_FORM:
                formDescriptor = this.selectObjectForm(HubConstant.HUB_OBJECT_STORY, formObject);
                break;
            case HubConstant.HUB_ELEMENT_FORM:
                formDescriptor = this.selectObjectForm(HubConstant.HUB_OBJECT_ELEMENT, formObject);
                break;
            case HubConstant.HUB_CREATE_NEW_USER_FORM:
            case HubConstant.HUB_UPDATE_USER_PASSWORD_FORM:
            case HubConstant.HUB_UPDATE_USER:
                formDescriptor = this.selectUserForm(formObject, formType);
                break;
            case HubConstant.HUB_CREATE_PRODUCTION_GROUP_FORM:
            case HubConstant.HUB_UPDATE_PRODUCTION_GROUP:
                formDescriptor = this.selectHubForm(formObject, formType);
                break;
            case HubConstant.HUB_CREATE_METADATA_FORM:
            case HubConstant.HUB_UPDATE_METADATA_FORM:
            case HubConstant.HUB_UPDATE_NEWSITEM_METADATA_FORM:
                formDescriptor = this.selectMetadataForm(formObject, formType);
                break;
            case HubConstant.HUB_OUTQ_FORM:
                formDescriptor = { formTitle: `Set ${formObject.elementName} Out-Queue`, component: OutQForm, payload: formObject }
                break;
            case HubConstant.HUB_UPDATE_NODE_FORM:
                formDescriptor = { formTitle: `Update Production Centre - ${formObject.name}`, component: HubNodeForm, payload: formObject }
                break;
            default:
                break;
        }
        Logger.debug(formDescriptor);
        return formDescriptor;
    }
}

const selector = new FormSelector();
export default selector;

import React, { Fragment } from 'react';
import { DataTable, DataTableConstant, TableToolType, TableRowAction, CollapsibleRowType } from 'hub-dashboard-framework'
import authService from '../../../api-authorization/AuthorizeService';
import Utilities, { UtilConstant } from 'hub-utilities'
import PropTypes from "prop-types";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import HubApi from 'util/HubApi'

import { mapDispatchToHubObjectBoardProps } from 'store/actions/HubObjectBoardActions';
import { mapDispatchToMetadataProps } from 'store/actions/HubMetadataActions';
import { mapDispatchToProps } from 'store/actions/HubConfigActions';
import { Row, Col } from 'react-bootstrap';

import * as Logger from 'loglevel';
import * as _ from 'lodash'

import * as HubConstant from 'util/HubConstant';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCertificate, faPlaneArrival, faUserEdit, faCity, faStopwatch, faTags, faBook, faListAlt, faFileAudio, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import { extractNodesFromData, extractNodeGroups, extractCategories, onReceivePropsParseMetadata, navigateDataObj } from '../../libraries/DataHandler'

import 'styles/tables/NewsItem.scss'
import memoize from 'memoize-one'
import update from 'immutability-helper'

class Story extends React.Component {
    _isMounted = false

    constructor(props) {
        super(props);
        this.state = {
            activeNodeId: props.nodeId,
            fetchedData: [],
            activeNodeName: "All",
            urgentCheckboxState: DataTableConstant.HUB_CHECKBOX_STATE.EMPTY,
            hasMediaCheckboxState: DataTableConstant.HUB_CHECKBOX_STATE.EMPTY
        };
        this.headerConfig = {
            id: -1,
            type: HubConstant.HUB_OBJECT_STORY,
            Title: { val: "null", canSort: true },
            Arrived: { val: "null", canSort: true, date: "", isInitiallySorted: true, headerIcon: faPlaneArrival, hasRowIcon: true },
            CreatedBy: { val: "null", canSort: false, headerIcon: faUserEdit },
            ProductionCentre: { val: "null", canSort: true, headerIcon: faCity },
            Duration: { val: "null", canSort: true, headerIcon: faStopwatch },
            Tags: { val: "null", canSort: false, headerIcon: faTags },
            Collapsible: null,
            rowActions: [
                { action: TableRowAction.EDIT_ROW, minAuthorityRequired: UtilConstant.HUB_USER_TYPE.HUB_NODE_ADMIN },
                { action: TableRowAction.DELETE_ROW, minAuthorityRequired: UtilConstant.HUB_USER_TYPE.HUB_NODE_ADMIN },
                { action: TableRowAction.DOWNLOAD_ALL_MEDIA, minAuthorityRequired: UtilConstant.HUB_USER_TYPE.HUB_USER },
            ]
        }
    }

    async componentWillMount() {
        await authService.getAccessTokenAsync()
        await this.props.loadNodeContents(this.state.activeNodeId, this.props.objectType, "", DataTableConstant.HUB_ACTION_STATE.INITIALIZE);
        await this.fetchFilterBarData();
    }

    async fetchFilterBarData() {
        await this.props.getNodeGroups(false, false);
        await this.props.getMetadata();
        await this.props.requestNodes();
    }

    componentDidMount() {
        this._isMounted = true;
        this.props.setActivePageName({ singular: "Story", plural: "Stories", icon: faBook });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getAryVal = function (x, y) {
        return _.differenceBy(x, y, 'id').concat(_.differenceBy(x, y, 'newsContent').concat(_.differenceBy(x, y, 'subObjectState'))); 
    }

    isArrayEqual = function (x, y) {
        //return _(x).xorWith(y, _.isEqual).isEmpty();
        //TODO: Implement scn detection for updates:
        //var aDiff = _.differenceBy(x, y, 'id').concat(_.differenceBy(x, y, 'newsContent').concat(_.differenceBy(x, y, 'subObjectState'))); 
        var isArrayEqual_Memoized = memoize(this.getAryVal)
        var aDiff = isArrayEqual_Memoized(x, y)
        return aDiff.length <= 0;
    };

    // //To properly map the fetchedData variable to redux:
    componentWillReceiveProps(nextPropsFromRedux) {
        //issue: looping through all the 3000 data w/ lodash or anything else causes tremendous lag
        //Logger.debug('nextPropsFromRedux.ObjectList: ', nextPropsFromRedux.objectList)
        //Logger.debug('nextPropsFromRedux.bTriggerUpdate: ', nextPropsFromRedux.bTriggerUpdate)
        // if (!!nextPropsFromRedux.objectList && this.props.objectList)
        //     for (let i = 0; i < nextPropsFromRedux.objectList.length; i++) {
        //         Logger.debug('!_.isEqual(nextPropsFromRedux.objectList, this.props.objectList)', !_.isEqual(nextPropsFromRedux.objectList[i], this.props.objectList[i]))
        //         if (i === 3) {
        //             Logger.debug('this.props.objectList3: ', JSON.stringify(this.props.objectList[i]))
        //             Logger.debug('nextPropsFromRedux.objectList3: ', JSON.stringify(nextPropsFromRedux.objectList[i]))
        //         }
        //     }
        if (this._isMounted 
            && (//!this.isArrayEqual(nextPropsFromRedux.objectList, this.props.objectList)
            nextPropsFromRedux.bTriggerUpdate
            || nextPropsFromRedux.clickNotifier != this.props.clickNotifier
            || nextPropsFromRedux.action != this.props.action)) {
            Logger.debug('nextPropsFromRedux: ', nextPropsFromRedux)
            //Logger.debug('nextPropsFromRedux.ObjectList: ', nextPropsFromRedux.objectList)
            //var fetcheData_memoized = memoize(this.fetchData);
            var transformedTableData;
            if (!!nextPropsFromRedux.objToUpdate) {
                transformedTableData = this.fetchData(nextPropsFromRedux.objectList, nextPropsFromRedux.objToUpdate)
            } else {
                transformedTableData = this.fetchData(nextPropsFromRedux.objectList)
            }
            this.setState({ fetchedData: transformedTableData })
            this.props.onFinishStoryUpdate()
        }
    }

    parseCollapsibleData(dataObj) {
        var collapsedData = [
            { rowType: CollapsibleRowType.REGULAR, val: "Story Number: ", colsOccupied: 1, hideIfNull: dataObj.id },
            { rowType: CollapsibleRowType.REGULAR, val: dataObj.id, colsOccupied: 1, hideIfNull: dataObj.id },
            { rowType: CollapsibleRowType.REGULAR, val: "Story Runtime: ", colsOccupied: 1, hideIfNull: dataObj.runTime },
            { rowType: CollapsibleRowType.REGULAR, val: Utilities.formatRunTime(dataObj.runTime), colsOccupied: 1, hideIfNull: dataObj.runTime },
            { rowType: CollapsibleRowType.REGULAR, val: "Text Read Time: ", colsOccupied: 1, hideIfNull: dataObj.readTime },
            { rowType: CollapsibleRowType.REGULAR, val: Utilities.formatRunTime(dataObj.readTime), colsOccupied: 1, hideIfNull: dataObj.readTime },

            { rowType: CollapsibleRowType.REGULAR, val: "Word Count: ", colsOccupied: 1, hideIfNull: dataObj.wordCount },
            { rowType: CollapsibleRowType.REGULAR, val: dataObj.wordCount, colsOccupied: 1, hideIfNull: dataObj.wordCount },
            { rowType: CollapsibleRowType.REGULAR, val: "Urgency: ", colsOccupied: 1, hideIfNull: dataObj.priorityLevel, isUrgency: true },
            { rowType: CollapsibleRowType.REGULAR, val: Utilities.formatUrgency(dataObj.priorityLevel), colsOccupied: 1, hideIfNull: dataObj.priorityLevel, isUrgency: true, isUrgencyVal: true },
            { rowType: CollapsibleRowType.REGULAR, metadataId: 1, isMetadataName: true, colsOccupied: 1, hideIfNull: dataObj.id },
            { rowType: CollapsibleRowType.REGULAR, metadataId: 1, isMetadataVals: true, colsOccupied: 1, hideIfNull: dataObj.id },

            { rowType: CollapsibleRowType.PARAGRAPH, key: "Text", val: navigateDataObj(dataObj, ["newsContent", "script"]), hideIfNull: dataObj.id }
        ]

        for (var i = 0; i < dataObj.subObjectCount; i++) {
           // console.log(`/api/element/${navigateDataObj(dataObj, ["newsContent", "elements", i, "id"])}/mediaobject?oauth_token=${authService._accessToken}`)
            collapsedData.push(
                {
                    rowType: CollapsibleRowType.WHOLE_COL_RIGHT,
                    fetchId: dataObj.id,
                    elementId: navigateDataObj(dataObj, ["newsContent", "elements", i, "id"]),
                    contentType: navigateDataObj(dataObj, ["newsContent", "elements", i, "newsContent", "type", "id"]),
                    mediaFetchUrl: `/api/element/${navigateDataObj(dataObj, ["newsContent", "elements", i, "id"])}/mediaobject?oauth_token=${authService._accessToken}`,
                    elementName: navigateDataObj(dataObj, ["newsContent", "elements", i, "name"]),
                    mimeType: navigateDataObj(dataObj, ["newsContent", "elements", i, "newsContent", "type", "mimeType"]),
                    outQ: navigateDataObj(dataObj, ["newsContent", "elements", i, "newsContent", "outQ"]),
                    verbatim: navigateDataObj(dataObj, ["newsContent", "elements", i, "newsContent", "verbatim"]),
                    hideIfNull: (dataObj.subObjectCount > 0) ? dataObj.id : null
                }

                // right now, all the collapsibleData is being fetched by DataTable.  When the api call is done to attempt to refresh a specific collapsible row
                // it never gets updated, unless I update the fetchedCollapsibleData state...
                // therefore, I have to let the Context (i.e., Story) handle the data, then pass all the data/collapsible row data to the collapsible row...
                // Context: convert collapsible data of each newsItem from redux into data passable to datatable
                // redux: stores collapsible data of each newsItem
                // dataTable: if (!!dataObj.collapsibledata) render collapsible row, or else parseCollapsibleData() from redux by Context
            )
        }


        return collapsedData;
    }

    filterStoryData(obj) {
        return obj.objectType.type === "Story" && (
            this.state.hasMediaCheckboxState === DataTableConstant.HUB_CHECKBOX_STATE.EMPTY ||
            (this.state.hasMediaCheckboxState === DataTableConstant.HUB_CHECKBOX_STATE.TICK && obj.subObjectCount > 0) ||
            (this.state.hasMediaCheckboxState === DataTableConstant.HUB_CHECKBOX_STATE.CROSS && obj.subObjectCount === 0)) && (
                this.state.urgentCheckboxState === DataTableConstant.HUB_CHECKBOX_STATE.EMPTY ||
                (this.state.urgentCheckboxState === DataTableConstant.HUB_CHECKBOX_STATE.TICK && Utilities.formatUrgency(obj.priorityLevel) === 'Urgent') ||
                (this.state.urgentCheckboxState === DataTableConstant.HUB_CHECKBOX_STATE.CROSS && Utilities.formatUrgency(obj.priorityLevel) !== 'Urgent'))
    }

    onEditOutQ = (outQData, newsItemId) => {
        //open out-Q editing form:
        Logger.debug("OBJECT: ", { ...outQData, ...{newsItemId: newsItemId}})
        this.props.onOpenForm(HubConstant.HUB_OUTQ_FORM, null, { ...outQData, ...{newsItemId: newsItemId}})
    }

    onParseCollapsibleData = async (newsItemId) => {
        Logger.debug("CALLED1")
        var t0 = performance.now()
        await this.props.getNewsItemSubObject(newsItemId)
        var t1 = performance.now()
        Logger.debug("Call to onParseCollapsibleData took " + (t1 - t0) + " milliseconds.")
       // this.props.getNewsItemMetadata(newsItemId)
    }

    fetchData(objects, objToUpdate) {
        Logger.debug("call to fetch? ", objToUpdate)
        if (!!objToUpdate) {
                    var t0 = performance.now()
                    var objIndex = this.state.fetchedData.findIndex(dataObj => dataObj.id === objToUpdate.id)
                    if (objIndex !== -1) {
                        const dataObj = objects.find(dataObj => dataObj.id === objToUpdate.id)
                        var updatedObj = update(this.state.fetchedData, {
                            [objIndex]: {
                                Collapsible: { 
                                    ... objToUpdate.type === HubConstant.HUB_NEWSITEM_UPDATE_TYPE.SUBOBJECT && 
                                    { 
                                        data: { $set: this.parseCollapsibleData(dataObj) },
                                        state: { $set: !!dataObj.subObjectState ? dataObj.subObjectState : HubConstant.HUB_SUBOBJECT_STATE.IDLE }
                                    }, // just so you know, HUB_SUBOBJECT_STATE === HUB_COLLAPSIBLE_ROW_STATE
                                    ... objToUpdate.type === HubConstant.HUB_NEWSITEM_UPDATE_TYPE.SUBOBJECT_METADATA && {metadata: 
                                        { $set: onReceivePropsParseMetadata(dataObj, this.props.metadata) }
                                    }
                                 }
                            }
                        })
                        Logger.debug('updatedObj:', updatedObj);
                    }
                    var t1 = performance.now()
                    Logger.debug(`Call to ${objToUpdate.type} took ` + (t1 - t0) + " milliseconds.")
                    return updatedObj
        } else if (objects !== undefined && objects != null && objects.length > 0) {
            var t0 = performance.now()
            var newObj = objects.filter((o) => this.filterStoryData(o)).map((o) => {
                return (
                    {
                        //Lower case keys won't get posted to table
                        id: o.id,
                        type: HubConstant.HUB_OBJECT_STORY,
                        subObjectCount: o.subObjectCount,
                        isUrgent: Utilities.formatUrgency(o.priorityLevel) === 'Urgent',
                        Title: { val: o.name, canSort: true },
                        Arrived: {
                            val: Utilities.formatObjectDate(o.createInfo.time, UtilConstant.HUB_TIME_FORMAT.CANADIAN), canSort: true, date: o.createInfo.time, isInitiallySorted: true,
                            rowIcon: Math.round(Math.round((new Date() - new Date(o.createInfo.time)) / 1000) / 60) <= 9 ? <FontAwesomeIcon icon={faCertificate} /> : undefined,
                            hasRowIcon: true, headerIcon: faPlaneArrival
                        },
                        CreatedBy: { val: authService.getCreatorName(o.createInfo.id), canSort: false, headerIcon: faUserEdit },
                        ProductionCentre: { val: o.origin ? authService.getHubNodeName(o.origin.nodeId) : authService.getNodeNameByUserId(o.createInfo.id), canSort: true, headerIcon: faCity },
                        Duration: { val: Utilities.formatRunTime(o.runTime, false), canSort: true, headerIcon: faStopwatch },
                        Tags: { val: o.keyword, canSort: false, headerIcon: faTags },
                        Collapsible: {
                            id: o.id, 
                            numOfColsPerRow: 6,
                            data: this.parseCollapsibleData(o),
                            metadata: onReceivePropsParseMetadata(o, this.props.metadata),
                            //isLoading: false, //for the loading icon
                            state: !!o.subObjectState ? o.subObjectState : HubConstant.HUB_SUBOBJECT_STATE.IDLE, // just so you know, HUB_SUBOBJECT_STATE === HUB_COLLAPSIBLE_ROW_STATE
                            onPreviewMedia: (mediaId) => { Logger.debug("MEDIAID", mediaId); HubApi.previewMediaObj(!!mediaId ? mediaId : o.id) },
                            onDownloadMedia: async (mediaId, mediaName) => await HubApi.downloadMediaObj(!!mediaId ? mediaId : o.id, !!mediaName ? mediaName : o.name),
                            onEditOutQ: (mediaId) => this.onEditOutQ(mediaId, o.id),
                            fetchData: async () => await this.onParseCollapsibleData(o.id),
                            fetchNewsItemMetadata: async () => this.props.getNewsItemMetadata(o.id)
                        },
                        rowActions: [
                            { action: TableRowAction.EDIT_ROW, form: HubConstant.HUB_UPDATE_NEWSITEM_METADATA_FORM, minAuthorityRequired: UtilConstant.HUB_USER_TYPE.HUB_NODE_ADMIN },
                            { action: TableRowAction.DELETE_ROW, minAuthorityRequired: UtilConstant.HUB_USER_TYPE.HUB_NODE_ADMIN },
                            {
                                action: TableRowAction.DOWNLOAD_ALL_MEDIA,
                                minAuthorityRequired: UtilConstant.HUB_USER_TYPE.HUB_USER,
                                onClickDownloadAllMedia: async () => await HubApi.downloadMediaObj(o.id, o.name, true),
                                disableNodeAuthorityCheck: true
                            },
                        ]
                    }
                );
            })
            var t1 = performance.now()
            console.log("Call to fetchData took " + (t1 - t0) + " milliseconds.")
            return newObj
        } else {
            return [];
        }
    }

    onChangeFilterBar = (filters, requireAPIRefresh) => {
        Logger.debug(filters);
        var nodeIds = filters.find(filter => filter.title === "Production Centre").toFilter.map(filteredNode => filteredNode.id);
        var categories = {
            id: 1,
            name: filters.find(filter => filter.title === "Category").toFilter.map(filteredCat => filteredCat.name)
        }
        this.setState({
            urgentCheckboxState: filters.find(filter => filter.title === "Urgent").state,
            hasMediaCheckboxState: filters.find(filter => filter.title === "Has Media").state
        })
        //TODO: (if more time: requireAPIRefresh only for api-required filters, not urgent/hasmedia)
        //if (!!requireAPIRefresh) this.props.setNodeFilter(nodeIds, HubConstant.HUB_OBJECT_STORY, categories);
        this.props.setNodeFilter(nodeIds, HubConstant.HUB_OBJECT_STORY, categories);
    }

    //backup data stub for no val
    displayDatatable() {
        return (
            <Row className="tableRow">
                <Col xs="12">
                    <DataTable canDeleteRow={true}
                        headerConfig={this.headerConfig}
                        data={this.state.fetchedData}
                        tools={[
                            { action: TableToolType.FULL_TEXT_SEARCH },
                            { action: TableToolType.SHOW_NUM_ROWS_DROPDOWN },
                            { action: TableToolType.FILTER_SEARCH },
                            { action: TableToolType.SYNC },
                            { action: TableToolType.ADD, form: HubConstant.HUB_STORY_FORM },
                            { action: TableToolType.PAGE_DISPLAY },
                            { action: TableToolType.PAGINATION },
                            {
                                action: TableToolType.FILTER_BAR,
                                filters: [
                                    {
                                        type: DataTableConstant.HUB_FILTER_TYPE.MULTI_VAL_SELECTOR,
                                        title: "Production Centre",
                                        icon: faCity,
                                        availableFilterObjs: extractNodesFromData(this.props.nodes, this.props.hubNodeGroups),
                                        availableFilterGroups: extractNodeGroups(this.props.hubNodeGroups)
                                    },
                                    {
                                        type: DataTableConstant.HUB_FILTER_TYPE.MULTI_VAL_SELECTOR,
                                        title: "Category",
                                        icon: faListAlt,
                                        availableFilterObjs: extractCategories(this.props.metadata)
                                    },
                                    { type: DataTableConstant.HUB_FILTER_TYPE.CHECKBOX, title: "Urgent", icon: faExclamationTriangle },
                                    { type: DataTableConstant.HUB_FILTER_TYPE.CHECKBOX, title: "Has Media", icon: faFileAudio }
                                ],
                                onChangeFilters: this.onChangeFilterBar
                            }
                        ]}
                        // filterBarConfig={{
                        //     types: [
                        //         HubFilterType.PRODUCTION_CENTRE,
                        //         HubFilterType.CATEGORY,
                        //         HubFilterType.URGENT,
                        //         HubFilterType.HAS_MEDIA
                        //     ]
                        // }}
                        dataAction={this.props.action}
                        isNotificationDisallowed={this.props.isNotificationAllowed}
                        authorizeService={authService}
                        {...this.props}></DataTable>
                </Col>
            </Row>
        )
    }

    render() {
        //DataTable happens during component will mount, make sure that data is fetched before table is rendered:
        return (
            <div className="Story">
                <Row className="structureRow">
                    <Col xs="12">
                        {(!!this.state.fetchedData) ? this.displayDatatable() : <Fragment key="0"></Fragment>}
                    </Col>
                </Row>
            </div>
        )
    }
}

Story.propTypes = {
    objectType: PropTypes.number,
    nodeId: PropTypes.number
};


Story.defaultProps = {
    nodeId: 0
};

const mapHubObjectAndMetadataStatesToProps = (state) => {
    return {
        objectList: state.hubObjectContent.objectList,
        objToUpdate: state.hubObjectContent.objToUpdate,
        action: state.hubObjectContent.action,
        hubNodeGroups: state.hubObjectContent.hubNodeGroups,
        metadata: state.hubConfig.hubMetadata.metaData,
        nodes: state.hubConfig.nodeConfig.nodes,
        bTriggerUpdate: state.hubObjectContent.bTriggerUpdate
    }
}

export default withRouter(connect(
    mapHubObjectAndMetadataStatesToProps,
    dispatch => bindActionCreators({ ...mapDispatchToProps, ...mapDispatchToMetadataProps, ...mapDispatchToHubObjectBoardProps }, dispatch)
)(Story));
import React from "react";
import { TextField } from '@material-ui/core';
import { Dropdown, Form, OverlayTrigger, Tooltip, Overlay, Button, Row, Col } from 'react-bootstrap';
import * as Logger from 'loglevel';
import * as HubConstant from 'util/HubConstant';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { mapMetadataStateToProps, mapDispatchToMetadataProps } from 'store/actions/HubMetadataActions';
import VocabularyPopup from './VocabularyPopup';

import Utilities, { UtilConstant } from 'hub-utilities';
import * as _ from 'lodash';

import 'styles/widgets/Metadata.scss'

class MetadataForm extends React.Component {
    constructor(props) {
        const { payload: metadata } = props;
        super(props);
        this.form = React.createRef();
        Logger.debug(metadata)
        //To prevent reference-type:
        var newMetadata = null
        if (!!metadata) newMetadata = JSON.parse(JSON.stringify(metadata));
        Logger.debug(newMetadata);

        this.state = !!metadata
            ? {
                //For edge case: if user update IsControlled then add vocabs immediately:
                "Controlled?AfterAPI": metadata["Controlled?"].val === "✓",

                fieldName: metadata.FieldName.val,
                dataType: metadata.DataType.actualVal,
                description: metadata.description,
                "Controlled?": metadata["Controlled?"].val === "✓",
                vocabularies: newMetadata.Values.formVal,
                filterSearchText: "",

                formError: undefined,
                isStatusEnabled: false,
                changeNotifier: false,
                isFocusingMembersUI: false,
                isCreatingMetadata: false,

                showVocabForm: false,
                vocabFormTarget: null,
                isRightDataType: true //detect whether the vocab is of wrong datatype for button color
            }
            : {
                //For edge case: if user update IsControlled then add vocabs immediately:
                "Controlled?AfterAPI": true,

                fieldName: "",
                dataType: UtilConstant.HUB_DATA_TYPE.DECIMAL,
                description: "",
                "Controlled?": true,
                vocabularies: [],
                filterSearchText: "",

                formError: undefined,
                isStatusEnabled: false,
                changeNotifier: false,
                isFocusingMembersUI: false,
                isCreatingMetadata: false,

                showVocabForm: false,
                vocabFormTarget: null,
                isRightDataType: true, //detect whether the vocab is of wrong datatype for button color
                //for newly-added keywords
                virtualIndex: -2
            };
        this.vocabFormRef = React.createRef();
        this.metadataId = !!metadata ? metadata.id : -1;
        this.isSysDefinedVar = !!metadata && metadata.id <= 10000;
        Logger.debug(this.isSysDefinedVar);
        Logger.debug(this.metadataId);

        this.oldMetadataState = JSON.parse(JSON.stringify(this.state));
        Logger.debug("oldMetadataState: ", this.oldMetadataState);

        //For edge case: if user update IsControlled from false to true then add vocabs immediately:
        this.initialFieldName = !!metadata ? metadata.FieldName.val : "";
        this.initialDataType = !!metadata ? metadata.DataType.actualVal : -1;
        this.initialDescription = !!metadata ? metadata.description : "";
    }

    setIsFocusingMembersUI = (isFocusing) => {
        this.setState({ isFocusingMembersUI: isFocusing })
    }

    componentWillMount() {
        Logger.debug("flushed");
    }

    componentWillReceiveProps(newProps) {
        Logger.debug(newProps);
        Logger.debug("right Data Type? ", this.state.isRightDataType);
        var newMetadata = newProps.metaData.find(mdata => mdata.fieldId === this.metadataId);
        if (!!newMetadata && this.metadataId !== -1) {
            Logger.debug(newMetadata.vocabulary);
            Logger.debug(this.state.vocabularies);

            var isArrayEqual = _(newMetadata.vocabulary).differenceWith(this.state.vocabularies, _.isEqual).isEmpty()
                && this.state.vocabularies.length == newMetadata.vocabulary.length;
            Logger.debug(isArrayEqual)
            if (!isArrayEqual) {
                var newNotifier = !this.state.changeNotifier;
                this.setState({ vocabularies: newMetadata.vocabulary }, () => this.setState({ changeNotifier: newNotifier }));
            }

            if (newMetadata.isControlledVocabulary !== this.state["Controlled?AfterAPI"]) {
                this.setState({ "Controlled?AfterAPI": newMetadata.isControlledVocabulary });
            }
        } else {
            //metadata is probably deleted... force-quit the editor
        }
    }

    //Filter text:
    setFilterSearchText = (text) => {
        this.setState({
            filterSearchText: text
        }, () => this.setState({ changeNotifier: !this.state.changeNotifier }));
    }

    setAreDataOfRightType = (isRight) => {
        Logger.debug(isRight);
        this.setState({ isRightDataType: isRight });
    }

    onChange = event => {
        this.props.editMetadata();
        this.setState({ [event.target.name]: event.target.value });
    }

    onSelectDataType = value => {
        //New change notifier for dataType detection for popup:
        let newChangeNotifier = !this.state.changeNotifier;
        this.setState({ dataType: typeof value == "string" ? parseInt(value) : value, changeNotifier: newChangeNotifier });
    }

    validateForm = () => (this.form.current.checkValidity());

    submitHandler = (event) => {
        event.preventDefault();
        if (this.state.isRightDataType) {
            Logger.debug('Saving metadata, event=', event);
            if (!event.target.className.includes(" was-validated")) {
                event.target.className += " was-validated";
            }
            if (this.validateForm()) {
                this.saveMetadata();
            }
        }
    };

    //set state is required => async resolve promise
    onConfirmAddInputRow = (addRowField) => {
        var oldVocabularies = this.state.vocabularies;
        oldVocabularies.push(addRowField);
        Logger.debug(oldVocabularies);
        this.setState({ vocabularies: oldVocabularies }, () => this.setState({ changeNotifier: !this.state.changeNotifier }, () => {
            this.setAreDataOfRightType(Utilities.verifyAllVocabsHaveCorrectDataType(this.state.vocabularies, parseInt(this.state.dataType)));
        }));
    }

    onDeleteRow = (deleteObj) => {
        var oldVocabularies = this.state.vocabularies.filter(obj => obj !== deleteObj);
        Logger.debug(oldVocabularies);
        this.setState({ vocabularies: oldVocabularies }, () => this.setState({ changeNotifier: !this.state.changeNotifier }, () => {
            this.setAreDataOfRightType(Utilities.verifyAllVocabsHaveCorrectDataType(this.state.vocabularies, parseInt(this.state.dataType)));
        }));
    }


    propogateMetaData(isCreate, isControlledEdgeCase) {
        var dataType = !!isControlledEdgeCase ? this.initialDataType : this.state.dataType
        return {
            fieldName: !!isControlledEdgeCase ? this.initialFieldName : this.state.fieldName,
            description: !!isControlledEdgeCase ? this.initialFieldName : this.state.description,
            dataType: typeof dataType !== 'number' ? parseInt(dataType) : dataType,
            isControlledVocabulary: this.state["Controlled?"],
            ...this.state["Controlled?"] && { vocabulary: !!isControlledEdgeCase ? [] : this.state.vocabularies.map(vocab => { return { value: vocab.keyword } }) }
        }
    }

    //TODO:
    createMetadata = async () => {
        await this.props.addMetadata(this.propogateMetaData(true));

        if (!!this.props.success) {
            this.setState({ isStatusEnabled: true }, () => this.props.onFormClosed());
        } else if (!!this.props.error) {
            this.setState({ isStatusEnabled: true });
        }
    }

    //TODO:
    updateMetadata = async (isControlledEdgeCase) => {
        Logger.debug("Updating");

        Logger.debug(Utilities.metadataTypeEnumToStr(this.state.dataType));
        await this.props.updateMetadata(this.metadataId, this.propogateMetaData(false, isControlledEdgeCase));

        if (!!this.props.success) {
            this.setState({ isStatusEnabled: true }, () => this.props.onFormClosed());
        } else if (!!this.props.error) {
            this.setState({ isStatusEnabled: true });
        }
    }

    saveMetadata = async () => {
        const { payload: metadata } = this.props;
        if (!!metadata) {
            await this.updateMetadata();
        }
        else {
            await this.createMetadata();
        }
    }

    renderStatus() {
        if (!!this.props.error) {
            return <Row className="status"><Col xs="12"><p className="UpdateError">{this.props.error}</p></Col></Row>
        } else if (!!this.props.success) {
            return <Row className="status"><Col xs="12"><p className="Success">{this.props.success}</p></Col></Row>
        }
    }

    renderFieldName() {
        return (
            <TextField
                group
                value={this.state.fieldName}
                name="fieldName"
                onChange={this.onChange}
                type="text"
                id="userformPassword1"
                label="FIELD NAME"
                required
            >
                <div className="invalid-feedback">This field cannot be empty!</div>
            </TextField>
        )
    }

    verifyDataType = (value) => {
        Logger.debug(value);
        this.setState({
            isRightDataType: Utilities.verifyAllVocabsHaveCorrectDataType(this.state.vocabularies.map(vocab => vocab.keyword), isNaN(value) ? Utilities.metadataTypeStrToEnum(value) : value)
        });
    }

    renderDataType() {
        Logger.debug(Object.keys(UtilConstant.HUB_DATA_TYPE));
        return (
            <div>
                <div className="formTitle">Data Type</div>
                <Dropdown className="formDropdown" onSelect={this.onSelectDataType}>
                    <Dropdown.Toggle id="dropdown-basic" disabled={this.isSysDefinedVar /*|| this.state.showVocabForm*/}>
                        {Utilities.metadataTypeEnumToStr(this.state.dataType)}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {
                            //event key is the val passed as val instead of event.target.value
                            Object.keys(UtilConstant.HUB_DATA_TYPE).map(key =>
                                <Dropdown.Item
                                    onClick={e => { Logger.debug(e.currentTarget.innerHTML); this.verifyDataType(e.currentTarget.innerHTML) }}
                                    eventKey={Utilities.metadataTypeStrToEnum(key)}>
                                    {Utilities.metadataTypeEnumStrToLowerCase(key)}
                                </Dropdown.Item>)
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        )
    }

    renderIsControlled() {
        return (
            <div>
                <div className="formTitle">Is Controlled</div>
                <label className='radioContainer'>
                    <input
                        id="formTitle" key="formTitle"
                        type="radio"
                        label=""
                        checked={this.state["Controlled?"]}
                        disabled={this.isSysDefinedVar || this.state.vocabularies.length > 0 /* || this.state.showVocabForm*/}
                        className={this.isSysDefinedVar || this.state.vocabularies.length > 0 ? "disabled" : ""}
                        onClick={(e) => {
                            var newControlled = !this.state["Controlled?"];
                            this.setState({ "Controlled?": newControlled });
                            if (!newControlled) this.toggleManageVocabulariesForm(e, true);
                        }}
                    />
                    <span className='checkmark' />
                </label>
            </div>
        )
    }

    renderDescription() {
        return (
            <TextField
                group
                value={this.state.description}
                name="description"
                onChange={this.onChange}
                type="textarea"
                id="userformPassword4"
                label="DESCRIPTION"
                required
            />
        )
    }

    // changeNotifier={this.state.changeNotifier}
    // isForm={true}
    // formData={DataHandler.filterAvailableVocabs(this.state.vocabularies, this.state.filterSearchText, null)}
    // onConfirmAddInputRow={this.onConfirmAddInputRow}
    // onDeleteRow={this.onDeleteRow}
    // changeNotifier={this.state.changeNotifier}
    // isInputDisabled={!this.state["Controlled?"]}
    // isDateTime={this.state.dataType === UtilConstant.HUB_DATA_TYPE.DATETIME}
    // requiredDataType={this.state.dataType}
    // setAreDataOfRightType={this.setAreDataOfRightType

    renderManageVocabsButton = () => {
        return (
            <Button
                color="light"
                type="button"
                size="sm"
                onClick={(e) => this.state["Controlled?"] ? this.toggleManageVocabulariesForm(e) : null}
                className={`${!this.state.isRightDataType ? "wrongDataType" : ""} ${!this.state["Controlled?"] ? "disabled disabledWithTooltip" : ""}`}>Manage Values</Button>
        )
    }

    toggleManageVocabulariesForm = (e, shouldClose) => {
        this.props.editMetadata(); //reset error msg
        var newShow = !!shouldClose ? false : !this.state.showVocabForm;
        this.setState({
            vocabFormTarget: e.target,
            showVocabForm: newShow
        }, () => {
            this.verifyDataType(this.state.dataType);
        });
    }

    //For Add/Edit/Delete vocab:

    onAddVocab = async (uselessParam, val) => {
        //check duplicates:
        if (this.state.vocabularies.map(vocab => vocab.keyword).includes(val)) {
            //report error:
            this.props.reportError("Error: Duplicated vocabulary shall not be added");
            Logger.debug("should return false");
            return false;
        } else {

            Logger.debug(val);
            Logger.debug(this.state.vocabularies);
            Logger.debug(this.state.virtualIndex);

            if (this.metadataId === -1) {
                // newly-added:
                var newVocab = this.state.vocabularies;
                var newVirtualIndex = this.state.virtualIndex - 1;
                var newChangeNotifier = !this.state.changeNotifier;

                newVocab.push({ index: this.state.virtualIndex, keyword: val });
                this.setState({ vocabularies: newVocab }, () => this.setState({ virtualIndex: newVirtualIndex, changeNotifier: newChangeNotifier }));
            }
            else {
                //For edge case: if user update IsControlled from false to true then add vocabs immediately:
                if (!this.state["Controlled?AfterAPI"] && this.state["Controlled?"]) { await this.updateMetadata(true); }
                this.props.addMetadataVocab(this.metadataId, { value: val });
            }
            return true;
        }
    }

    onEditVocab = async (vocabId, val) => {
        if (this.metadataId === -1) {
            // newly-edited:
            var aryIndex = this.state.vocabularies.findIndex(vocab => vocab.index === vocabId);
            var newVocab = this.state.vocabularies;
            var newChangeNotifier = !this.state.changeNotifier;

            newVocab[aryIndex] = { index: vocabId, keyword: val };
            this.setState({ vocabularies: newVocab }, () => this.setState({ changeNotifier: newChangeNotifier }));
        }
        else {
            var foundVocab = this.state.vocabularies.find(vocab => vocab.index === vocabId);
            if (!!foundVocab) {
                //For edge case: if user update IsControlled from false to true then add vocabs immediately:
                if (!this.state["Controlled?AfterAPI"] && this.state["Controlled?"]) { await this.updateMetadata(true); }
                this.props.editMetadataVocab(this.metadataId, vocabId, { currentValue: foundVocab.keyword, keyword: { value: val } });
            } else {
                //foundVocab is prob being modified at the same time by other ppl, return error here:

            }
        }
    }

    onDeleteVocab = async (vocabId) => {
        if (this.metadataId === -1) {
            // newly-deleted:
            var filteredVocabs = this.state.vocabularies.filter(vocab => vocab.index !== vocabId);
            var newChangeNotifier = !this.state.changeNotifier;

            this.setState({ vocabularies: filteredVocabs }, () => this.setState({ changeNotifier: newChangeNotifier }));
        }
        else {
            var foundVocab = this.state.vocabularies.find(vocab => vocab.index === vocabId);
            await this.props.deleteMetadataVocab(this.metadataId, vocabId, foundVocab.keyword);
            var newChangeNotifier = !this.state.changeNotifier;
            this.setState({ changeNotifier: newChangeNotifier });
        }
    }

    render() {
        const { payload: metadata } = this.props;
        let isUpdateMetadata = !!metadata;
        return (
            <div className="HubUserForm HubNodeGroupForm" ref={this.vocabFormRef}>
                <Overlay
                    show={this.state.showVocabForm}
                    target={this.state.vocabFormTarget}
                    placement="right"
                    container={this.vocabFormRef.current}
                    containerPadding={20}
                >
                    <VocabularyPopup
                        changeNotifier={this.state.changeNotifier}
                        formData={this.state.vocabularies}
                        isDateTime={this.state.dataType === UtilConstant.HUB_DATA_TYPE.DATETIME}
                        requiredDataType={this.state.dataType}
                        setAreDataOfRightType={this.setAreDataOfRightType}
                        dataType={this.state.dataType}
                        toggleManageVocabulariesForm={this.toggleManageVocabulariesForm}
                        onAddVocab={this.onAddVocab}
                        onEditVocab={this.onEditVocab}
                        onDeleteVocab={this.onDeleteVocab}
                        message={this.props.vocabMsg}
                        {...this.props} />
                </Overlay>
                <Form ref={this.form} className="needs-validation burli-metadata-form mt-3" onSubmit={this.submitHandler} noValidate >
                    <Row>
                        <Col size="5" className="HubNameRow">
                            {this.renderFieldName()}
                        </Col>

                        <Col size="4" className="HubNameRow">
                            { /* {this.state.showVocabForm ?
                                    <OverlayTrigger key="sysDefinedData" placement="bottom" overlay={<Tooltip className="sysVarToolTip">Cannot Edit While<br />Managing Vocabularies</Tooltip>}>
                                        {this.renderDataType()}
                                    </OverlayTrigger> : */ }
                            {
                                this.isSysDefinedVar ?
                                    <OverlayTrigger key="sysDefinedData" placement="bottom" overlay={<Tooltip className="sysVarToolTip">Built-in Data Field:<br />Data Type is Fixed</Tooltip>}>
                                        {this.renderDataType()}
                                    </OverlayTrigger>
                                    : this.renderDataType()}
                        </Col>
                        <Col size="3" className="HubNameRow">
                            {/* {this.state.showVocabForm ?
                                    <OverlayTrigger key="sysDefinedData" placement="bottom" overlay={<Tooltip className="sysVarToolTip">Cannot Edit While<br />Managing Vocabularies</Tooltip>}>
                                        {this.renderIsControlled()}
                                    </OverlayTrigger> :  */}
                            {this.isSysDefinedVar ?
                                <OverlayTrigger key="sysDefinedData" placement="bottom" overlay={<Tooltip className="sysVarToolTip">Built-in Data Field:<br />Is Controlled is Fixed</Tooltip>}>
                                    {this.renderIsControlled()}
                                </OverlayTrigger> :
                                this.state.vocabularies.length > 0 ?
                                    <OverlayTrigger key="sysDefinedData" placement="bottom" overlay={<Tooltip className="sysVarToolTip">Please Remove All<br /> Vocabularies Before<br />Modifying Controlled Settings</Tooltip>}>
                                        {this.renderIsControlled()}
                                    </OverlayTrigger>
                                    : this.renderIsControlled()}
                        </Col>
                    </Row>
                    <Row>
                        <Col size="12" className="descriptionCol">
                            {this.renderDescription()}
                        </Col>

                        {/* <Col size="6">
                                <Row className="TitleRow">
                                    <Col size="4">
                                        <h5 className={this.state.isFocusingMembersUI ? "focused" : ""}>VOCABULARIES</h5>
                                    </Col>
                                    <Col size="2">
                                    </Col>
                                    <Col size="6">
                                        <TableFilterSearch
                                            setIsFocusingMembersUI={this.setIsFocusingMembersUI}
                                            filterSearchText={this.state.filterSearchText}
                                            setFilterSearchText={this.setFilterSearchText}>
                                        </TableFilterSearch>
                                    </Col>
                                </Row>
                                <div
                                    onMouseEnter={() => this.setState({ isFocusingMembersUI: true })}
                                    onMouseLeave={() => this.setState({ isFocusingMembersUI: false })}>
                                    <Vocabulary
                                        changeNotifier={this.state.changeNotifier}
                                        isForm={true}
                                        formData={DataHandler.filterAvailableVocabs(this.state.vocabularies, this.state.filterSearchText, null)}
                                        onConfirmAddInputRow={this.onConfirmAddInputRow}
                                        onDeleteRow={this.onDeleteRow}
                                        changeNotifier={this.state.changeNotifier}
                                        isInputDisabled={!this.state["Controlled?"]}
                                        isDateTime={this.state.dataType === UtilConstant.HUB_DATA_TYPE.DATETIME}
                                        requiredDataType={this.state.dataType}
                                        setAreDataOfRightType={this.setAreDataOfRightType}
                                    >
                                    </Vocabulary>
                                </div>
                            </Col> */}
                    </Row>
                    {this.state.isStatusEnabled ? this.renderStatus() : <></>}
                    <Row className="SelectionStatus">
                        <Col size="12">
                            <div className="metadataFormButtonsContainer">
                                {!this.state.isRightDataType ?
                                    <>
                                        <OverlayTrigger key="vocabTypeMismatchTooltip_ManageVocabs" placement="bottom" overlay={
                                            <Tooltip className={this.state.isRightDataType ? "" : "wrongDataType"}>Please Fix Vocabulary<br />Type Mismatches</Tooltip>
                                        }>
                                            {this.renderManageVocabsButton()}
                                        </OverlayTrigger>
                                        <OverlayTrigger key="vocabTypeMismatchTooltip" placement="bottom" overlay={<Tooltip>Please Fix Vocabulary<br />Type Mismatches<br />Before Proceeding</Tooltip>}>
                                            <Button color="light" type="submit" size="sm" className={`disabled disabledWithTooltip`}>{isUpdateMetadata ? "Save and Close" : "Create and Close"}</Button>
                                        </OverlayTrigger>
                                    </>
                                    :
                                    !this.state["Controlled?"] ?
                                        <>
                                            <OverlayTrigger key="vocabTypeMismatchTooltip_ManageVocabs" placement="bottom" overlay={
                                                <Tooltip>You Cannot Manage<br />Uncontrolled Vocabularies</Tooltip>
                                            }>
                                                {this.renderManageVocabsButton()}
                                            </OverlayTrigger>
                                            < Button color="light" type="submit" size="sm" className={`disabledWithTooltip`}>{isUpdateMetadata ? "Save and Close" : "Create and Close"}</Button>
                                        </>
                                        :
                                        <>
                                            {this.renderManageVocabsButton()}
                                            < Button color="light" type="submit" size="sm">{isUpdateMetadata ? "Save and Close" : "Create and Close"}</Button>
                                        </>
                                }
                            </div>
                        </Col>
                    </Row>
                </Form >
            </div >
        );
    }
}

export default withRouter(connect(
    mapMetadataStateToProps,
    dispatch => bindActionCreators(mapDispatchToMetadataProps, dispatch)
)(MetadataForm));
import * as Logger from 'loglevel';
import * as ActionType from '../ActionType';
import authService from 'components/api-authorization/AuthorizeService';
import { UtilConstant } from 'hub-utilities'

const apiFetch = (method, nodeId, body) => ({
    type: ActionType.API_FETCH,
    payload: {
        url: `/api/Node/${nodeId}`,
        method: method,
        body: body,
        token: authService.getAccessToken(),
        responseType: UtilConstant.HUB_API_RESPONSE_TYPE.JSON,
        begin: updateNodeBegin,
        success: updateNodeSuccess,
        error: updateNodeFailure,
    }
});

const fetchNodesBegin = () => ({
    type: ActionType.FETCH_HUB_NODES_BEGIN
});

const fetchNodesSuccess = payload => ({
    type: ActionType.FETCH_HUB_NODES_SUCCESS,
    payload: payload
});

const fetchNodesFailure = error => ({
    type: ActionType.FETCH_HUB_NODES_FAILURE,
    payload: error
});

const updateNodeBegin = () => ({
    type: ActionType.UPDATE_HUB_NODES_BEGIN
});

const updateNodeSuccess = payload => ({
    type: ActionType.UPDATE_HUB_NODES_SUCCESS,
    payload: payload
});

const updateNodeFailure = error => ({
    type: ActionType.UPDATE_HUB_NODES_FAILURE,
    payload: error
});

export const fetchNodes = async (dispatch, getState) => {
    Logger.debug("test");
    const state = getState().hubConfig.nodeConfig;
    if (state.loadingNodes || state.error) {
        // Don't issue a duplicate request (we are loading or already have loaded the requested data)
        Logger.debug('already loaded or loading nodes');
        return;
    }
    dispatch(fetchNodesBegin());
    Logger.debug(`requesting loading hub nodes.`);
    const url = 'api/node';
    let token = await authService.getAccessTokenAsync();
    Logger.debug("Token is: " , token);
    const response = await fetch(url, {
        method: "GET",
        headers: {
            'Authorization': `Bearer ${token}`,
        },
    });
    if (response.ok) {
        Logger.debug(`nodes loaded`);
        let resp = await response.json();
        Logger.debug(resp.hubNodes)
        dispatch(fetchNodesSuccess(resp.hubNodes));
    }
    else {
        Logger.debug(`loading nodes failed, error=${response.error}`);
        dispatch(fetchNodesFailure(response.error));
    }
}

export const mapStateToProps = state => {

    return state.hubConfig.nodeConfig;
}
export const mapDispatchToProps = {
    requestNodes: () => async (dispatch, getState) => {
        await fetchNodes(dispatch, getState);
    },
    updateNode: (nodeId, body) => async (dispatch, getState) => {
        await dispatch(apiFetch(UtilConstant.HUB_API_METHOD.PUT, nodeId, body))
        fetchNodes(dispatch, getState);
    } 
}
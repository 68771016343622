import React from "react";
import { ResetPasswordFormEx } from 'hub-dashboard-framework'
import * as Logger from 'loglevel';
import hubApi from 'util/HubApi';


export default class NewPasswordForm extends React.Component {
    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.state = {
            password1: "",
            password2: "",
            formError: null,
            isStatusEnabled: false,
            spaceWarningShow: false
        };
        this.submitHandler = this.submitHandler.bind(this);
    }
    onChange = (event, isPassword) => {
        if (isPassword && event.target.value.includes(' ')) {
            this.setState({ spaceWarningShow: true });
        } else {
            this.setState({ [event.target.name]: event.target.value, spaceWarningShow: false });
        }
    }
    submitHandler(event) {
        Logger.debug('new password from submitHander, event=', event);
        event.preventDefault();
        if (!event.target.className.includes(" was-validated")) {
            event.target.className += " was-validated";
        }

        this.updatePassword();
    };

    updatePassword = async () => {
        const { payload: user } = this.props;
        var response = await hubApi.resetUserPassword(user, this.state.password1);
        if (response.ok) {
            Logger.debug(`user password reset.`);
            this.props.onFormSubmitted();
        }
        else {
            Logger.error(`user password reset failed, response=.`, response);
            let error = await response.json();
            this.setState({
                ...this.state,
                formError: `Failed: ${error.message}`,
                isStatusEnabled: true
            });
        }
    }
    /*
                                 validate
                                error="password must be at least 6 characters."
                                success="right"
                                required
                                pattern=".{6,}"
                                title="password must be at least 6 characters."
    */
    render() {
        return (
            <ResetPasswordFormEx
                password1={this.state.password1}
                password2={this.state.password2}
                formError={this.state.formError}
                onChange={this.onChange}
                onSubmit={this.updatePassword}
            />
        );
    }
}
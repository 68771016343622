import * as Logger from 'loglevel';
import * as ActionType from '../../ActionType';
import scnReducer from '../../scn/ScnSink';
import { DataTableConstant } from 'hub-dashboard-framework'
import * as HubConstant from 'util/HubConstant'
import update from 'immutability-helper'


const initialState = {
    error: null,
    listObjectType: 0,
    objectList: null,
    action: DataTableConstant.HUB_ACTION_STATE.NONE,
    hubNodeGroups: null,
    nodeGroupIdJustAdded: -1,
    success: null,
    hubNodeFetchCount: 0,
    bTriggerUpdate: false,
    objToUpdate: null
};


export const reducer = (state, action) => {
    if (!state) {
        state = initialState;
    }
    Logger.debug(`HubObjectContent reducer: action = ${action.type}`);
    switch (action.type) {
        case ActionType.FETCH_NODE_CONTENTS_BEGIN:
            return ({
                ...state,
                listObjectType: action.payload,
                objectList: [],
                error: null,
                action: DataTableConstant.HUB_ACTION_STATE.LOADING,
                bTriggerUpdate: false
            });
        case ActionType.FETCH_NODE_CONTENTS_SUCCESS:
            return (
                {
                    ...state,
                    objectList: action.payload.hubObjects,
                    error: null,
                    action: DataTableConstant.HUB_ACTION_STATE.INITIALIZE,
                    bTriggerUpdate: true
                });
        case ActionType.FETCH_METADATA_TO_NEWSITEM_SUCCESS:
            Logger.debug(action.payload)
            Logger.debug(state.objectList)
            Logger.debug(action.newsItemId) //additional param

            //append the NewsItem to existing objectList:
            var newObjList = Object.assign([], state.objectList); //dereference the prev objectList
            var newObjMetadata = newObjList.find(newsObj => newsObj.id === action.newsItemId)
            if (!!newObjMetadata)
                newObjMetadata.metadata = action.payload.metadata;
            //ALWAYS return a state, NEVER try to directly assign to state here (aka. NEVER mutate a state)
            //Or else, componentDidReceiveProps will not get called as no new state is returned here!!

            return (
                {
                    ...state,
                    objToUpdate: {
                        id: action.newsItemId, 
                        type: HubConstant.HUB_NEWSITEM_UPDATE_TYPE.SUBOBJECT_METADATA 
                    },
                    objectList: newObjList,
                    bTriggerUpdate: true
                });

        case ActionType.FETCH_NODE_CONTENTS_FAILURE:
            return (
                {
                    ...state,
                    objectList: [],
                    error: action.payload,
                    action: DataTableConstant.HUB_ACTION_STATE.NONE,
                    bTriggerUpdate: false
                });

        case ActionType.SET_HUB_NODE_FILTER_BEGIN:
            return ({
                ...state,
                objectList: [],
                error: null,
                action: DataTableConstant.HUB_ACTION_STATE.LOADING,
                bTriggerUpdate: false
            });
        case ActionType.SET_HUB_NODE_FILTER_SUCCESS:
            return (
                {
                    ...state,
                    objectList: action.payload.hubObjects,
                    error: null,
                    action: DataTableConstant.HUB_ACTION_STATE.FILTER,
                    bTriggerUpdate: true
                });
        case ActionType.SET_HUB_NODE_FILTER_FAILURE:
            return (
                {
                    ...state,
                    objectList: [],
                    error: action.payload,
                    action: DataTableConstant.HUB_ACTION_STATE.NONE,
                    bTriggerUpdate: false
                });

        case ActionType.GET_HUB_NODE_GROUP_BEGIN:
        case ActionType.GET_HUB_NODE_GROUP_NODES_BEGIN:
            Logger.debug(action)
            return ({
                ...state,
                error: null,
                ...!!action.isTable && action.isTable && { action: DataTableConstant.HUB_ACTION_STATE.LOADING },
                bTriggerUpdate: false
            });

        case ActionType.GET_HUB_NODE_GROUP_SUCCESS:
            Logger.debug(action.payload);
            return (
                {
                    ...state,
                    hubNodeGroups: action.payload.hubNodeGroups,
                    error: null,
                    //reset fetch count: 
                    hubNodeFetchCount: 0,
                    action: action.canAffectLoadingScreen != undefined && action.canAffectLoadingScreen ? DataTableConstant.HUB_ACTION_STATE.INITIALIZE : state.action,
                    bTriggerUpdate: true
                });
        case ActionType.GET_HUB_NODE_GROUP_NODES_SUCCESS:
            var hubNodeGrpIndex = state.hubNodeGroups.findIndex(nodeGroup => nodeGroup.groupId === action.nodeGroupId)

            if (hubNodeGrpIndex !== -1) {
                state.hubNodeGroups[hubNodeGrpIndex].hubNodes = action.payload.hubNodes;
                if (!!action.isTable && action.isTable && state.hubNodeGroups.length > state.hubNodeFetchCount) {
                    state.hubNodeFetchCount = state.hubNodeFetchCount + 1
                    state.bTriggerUpdate = true
                }

                Logger.debug(state.hubNodeFetchCount + 1);

                if (state.hubNodeGroups.length <= state.hubNodeFetchCount && !(!!state.error)) {
                    state.action = action.canAffectLoadingScreen != undefined && action.canAffectLoadingScreen ? DataTableConstant.HUB_ACTION_STATE.INITIALIZE : state.action;
                    //state.success = "Node Groups Updated Successfully";
                    state.bTriggerUpdate = true
                }

                return state;
            } else {
                Logger.debug("fail: ", state.hubNodeGroups);
                return state;
            }

        case ActionType.GET_HUB_NODE_GROUP_FAILURE:
        case ActionType.GET_HUB_NODE_GROUP_NODES_FAILURE:
            return (
                {
                    ...state,
                    objectList: [],
                    error: action.payload,
                    ...!!action.isTable && action.isTable && { action: DataTableConstant.HUB_ACTION_STATE.NONE },
                    bTriggerUpdate: false
                });

        case ActionType.ADD_HUB_NODE_GROUP_SUCCESS:
            return ({
                ...state,
                nodeGroupIdJustAdded: parseInt(action.payload.message),
                success: "Add Node Group Success",
                error: null,
                bTriggerUpdate: true
            });

        case ActionType.ADD_HUB_NODE_GROUP_FAILURE:
            return ({
                ...state,
                nodeGroupIdJustAdded: parseInt(action.payload.message),
                success: null,
                error: typeof action.payload == "object" ? JSON.stringify(action.payload) : action.payload,
                bTriggerUpdate: false
            });

        case ActionType.SET_HUB_NODE_GROUP_SUCCESS:
            return ({
                ...state,
                success: "Set Node Group Success",
                error: null,
                bTriggerUpdate: true
            });

        case ActionType.SET_HUB_NODE_GROUP_FAILURE:
            return ({
                ...state,
                success: null,
                error: typeof action.payload == "object" ? JSON.stringify(action.payload) : action.payload,
                bTriggerUpdate: false
            });

        case ActionType.DELETE_HUB_NODE_GROUP_SUCCESS:
            return ({
                ...state,
                nodeGroupIdJustAdded: parseInt(action.payload.message),
                success: "Delete Node Group Success",
                error: null,
                bTriggerUpdate: true
            });

        case ActionType.DELETE_HUB_NODE_GROUP_FAILURE:
            Logger.debug(action.payload);
            return ({
                ...state,
                ...!!action.isTable && action.isTable && { action: DataTableConstant.HUB_ACTION_STATE.INITIALIZE },
                success: null,
                error: typeof action.payload == "object" ? JSON.stringify(action.payload) : action.payload,
                bTriggerUpdate: false
            });

        case ActionType.EDIT_NODE_GROUP:
            return (
                {
                    ...state,
                    success: null,
                    error: null,
                    bTriggerUpdate: true
                });

        case ActionType.SCN_ADD_HUB_OBJECT:
            return scnReducer.onScnAddObject(state, action.payload);
        case ActionType.SCN_DELETE_HUB_OBJECT:
            state.action = DataTableConstant.HUB_ACTION_STATE.DELETE_ROW;
            return scnReducer.onScnDeleteObject(state, action.payload);
        case ActionType.SCN_DELETE_HUB_OBJECTS:
            state.action = DataTableConstant.HUB_ACTION_STATE.DELETE_ROW;
            return scnReducer.onScnDeleteObjects(state, action.payload);

        case ActionType.GET_NEWSITEM_SUBOBJECT_BEGINS:
            //append the NewsItem to existing objectList:
            return (update(state,
                {
                    objectList: {
                        [state.objectList.findIndex(newsObj => newsObj.id === action.newsItemId)]: {
                            subObjectState: { $set: HubConstant.HUB_SUBOBJECT_STATE.FETCHING }
                        }
                    },
                    objToUpdate: {
                        $set: {
                            id: action.newsItemId, 
                            type: HubConstant.HUB_NEWSITEM_UPDATE_TYPE.SUBOBJECT, 
                            state:HubConstant.HUB_SUBOBJECT_STATE.FETCHING },
                    },
                    action: { $set: DataTableConstant.HUB_ACTION_STATE.INITIALIZE },
                    bTriggerUpdate: { $set: true }
                })
            )

        case ActionType.GET_NEWSITEM_SUBOBJECT_SUCCESS:


            Logger.debug(action.payload)
            Logger.debug(state.objectList)
            Logger.debug('action.newsItemId: ', action.newsItemId) //additional param

            //append the NewsItem to existing objectList:
            /*var newObjList = Object.assign([], state.objectList); //dereference the prev objectList
            var newObjWithSubobject = newObjList.find(newsObj => newsObj.id === action.newsItemId)
            if (!!newObjWithSubobject)
                newObjWithSubobject.newsContent = action.payload.hubObject.newsContent;
                newObjWithSubobject.subObjectState = HubConstant.HUB_SUBOBJECT_STATE.FETCHED;
            //ALWAYS return a state, NEVER try to directly assign to state here (aka. NEVER mutate a state)
            //Or else, componentDidReceiveProps will not get called as no new state is returned here!!

            return (
                {
                    ...state,
                    objectList: newObjList,
                    action: DataTableConstant.HUB_ACTION_STATE.INITIALIZE
                });*/

                var t0 = performance.now()
            var newState = update(state,
                {
                    objectList: {
                        [state.objectList.findIndex(newsObj => newsObj.id === action.newsItemId)]: {
                            newsContent: { $set: action.payload.hubObject.newsContent },
                            subObjectState: { $set: HubConstant.HUB_SUBOBJECT_STATE.FETCHED }
                        }
                    },
                    objToUpdate: { $set: {
                        id: action.newsItemId,
                        type: HubConstant.HUB_NEWSITEM_UPDATE_TYPE.SUBOBJECT,
                        state: HubConstant.HUB_SUBOBJECT_STATE.FETCHED
                    }},
                    action: { $set: DataTableConstant.HUB_ACTION_STATE.INITIALIZE },
                    bTriggerUpdate: { $set: true }
                })
                var t1 = performance.now()
                console.log("Call to update immutability-helper took " + (t1 - t0) + " milliseconds.")

            return newState;

        case ActionType.GET_NEWSITEM_SUBOBJECT_FAILURE:
            //append the NewsItem to existing objectList:
            return (update(state,
                {
                    objectList: {
                        [state.objectList.findIndex(newsObj => newsObj.id === action.newsItemId)]: {
                            subObjectState: { $set: HubConstant.HUB_SUBOBJECT_STATE.FETCHED }
                        }
                    },
                    objToUpdate: { $set: {
                        id: action.newsItemId, 
                        type: HubConstant.HUB_NEWSITEM_UPDATE_TYPE.SUBOBJECT,
                        state: HubConstant.HUB_SUBOBJECT_STATE.FETCHED
                    }},
                    action: { $set: DataTableConstant.HUB_ACTION_STATE.INITIALIZE },
                    bTriggerUpdate: { $set: true }
                })
            )
        case ActionType.SET_UPDATE_TRIGGER:
            return ({
                ...state,
                bTriggerUpdate: action.bTriggerUpdate,
                objToUpdate: null
            });
        default:
            break;
    }
    return state;
}


import * as Logger from 'loglevel';
import * as hubConstant from "./HubConstant";
import authService from 'components/api-authorization/AuthorizeService';

export class HubApi {

    fetchMediaObj = async (id) => {
        Logger.debug(`fetching media ${id}`);
        let token = await authService.getAccessTokenAsync();
        const response = await fetch(`api/newsitem/${id}`, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });
        if (!response.ok)
        {
            Logger.error('fetching media failed, response=', response);
        }
    }

    previewMediaObj = async (id) => {
        Logger.debug(`previewing media ${id}`);
        let token = await authService.getAccessTokenAsync();
        const response = await fetch(`api/element/${id}/mediaobject`, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });
        if (!response.ok)
        {
            Logger.error('previewing media failed, response=', response);
        }
    }


    downloadMediaObj = async (id, name, isZip) => {
        if (isZip) name += ".zip"
        Logger.debug(`downloading newsitem ${id}, with name ${name}`);
        let token = await authService.getAccessTokenAsync();
        const response = await fetch(`api/newsitem/${id}?download=true`, {
            method: "GET",
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });
        if (!response.ok)
        {
            Logger.error('downloading newsitem failed, response=', response);
            return false;
        } else {
            var mediaBlob = await response.blob()
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(mediaBlob);
            link.download = name;

            document.body.appendChild(link);

            link.click();

            document.body.removeChild(link);
            return true;
        }
    }

    deleteStory = async (storyId) => {
        Logger.debug(`deleting story ${storyId}`);
        let token = await authService.getAccessTokenAsync();
        const response = await fetch(`/api/story/${storyId}`, {
            method: "DELETE",
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });
        if (!response.ok)
        {
            Logger.error('deleting story failed, response=', response);
        }
    }

    deleteElement = async (elementId) => {
        Logger.debug(`deleting element ${elementId}`);
        let token = await authService.getAccessTokenAsync();
        const response = await fetch(`/api/element/${elementId}`, {
            method: "DELETE",
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });
        if (!response.ok) {
            Logger.error('deleting element failed, response=', response);
        }
    }

    resetUserPassword = async (user, newPassword) => {
        var userId = user.userGuid;
        let token = await authService.getAccessTokenAsync();
        const response = await fetch(`/api/hubuser/${userId}/password?newPassword=${newPassword}`, {
            method: "PUT",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'text/json; charset=UTF-8'
            },
            body: `"${newPassword}"`
        });
        Logger.debug('reset password response:', response);
        return response;
    }

    createHubUser = async (nodeId, request) => {
        Logger.debug(`Create new user in node ${nodeId}, request=`, request);
        let token = await authService.getAccessTokenAsync();
        const response = await fetch(`/api/hubuser?hubNodeId=${nodeId}`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json; charset=UTF-8'
            },
            body: JSON.stringify(request),
        });
        Logger.debug(`Create new user response:`, response);
        return response;
    }

    updateHubUser = async (userId, request) => {
        Logger.debug(`Update user ${userId}, request=`, request);
        let token = await authService.getAccessTokenAsync();
        const response = await fetch(`/api/hubuser/${userId}`, {
            method: "PUT",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json; charset=UTF-8'
            },
            body: JSON.stringify(request),
        });
        Logger.debug(`Update user response:`, response);
        return response;
    }
    updateHubUserRole = async (userGuid, adminLevel) => {
        Logger.debug(`Update user ${userGuid}, adminLevel=`, adminLevel);
        let token = await authService.getAccessTokenAsync();
        const response = await fetch(`/api/hubuser/${userGuid}/adminlevel/${adminLevel}`, {
            method: "PUT",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json; charset=UTF-8'
            }
        });
        Logger.debug(`Update user response:`, response);
        return response;
    }

    createNodeGroup = async (request, nodeIdsToAdd) => {
        Logger.debug(`Create new nodeGroup with nodes to add ${nodeIdsToAdd}, request=`, request);
        let token = await authService.getAccessTokenAsync();
        // const response = await fetch(`/api/NodeGroup`, {
        //     method: "POST",
        //     headers: {
        //         'Authorization': `Bearer ${token}`,
        //         'Content-Type': 'application/json; charset=UTF-8'
        //     },
        //     body: JSON.stringify(request),
        // });
        const response2 = await fetch(`/api/NodeGroup/{id}/node/{nodeId}`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json; charset=UTF-8'
            },
            body: JSON.stringify(request),
        });
        Logger.debug(`Create new user response:`, response2);
        return response2;
    }

    updateNodeGroup = async (nodeGroupId, request, nodeIdsToAdd, nodeIdsToDelete) => {
        Logger.debug(`Update nodeGroup ${nodeGroupId} with nodes to add ${nodeIdsToAdd} and delete ${nodeIdsToDelete}, request=`, request);
        let token = await authService.getAccessTokenAsync();
        const response = await fetch(`/api/NodeGroup/${nodeGroupId}`, {
            method: "PUT",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json; charset=UTF-8'
            },
            body: JSON.stringify(request),
        });
        Logger.debug(`Update user response:`, response);
        return response;
    }


    updateHubUserState = async (userId, newState) => {
        Logger.debug(`Update user ${userId} state, new state=`, newState);
        let token = await authService.getAccessTokenAsync();
        const response = await fetch(`/api/hubuser/${userId}/state/${newState}`, {
            method: "PUT",
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        Logger.debug(`Update user response:`, response);
        return response;
    }

    disableHubUser = async (userId) => {
        await this.updateHubUserState(userId, hubConstant.HUB_USER_STATE_INACTIVE);
    }

    enableHubUser = async (userId) => {
        await this.updateHubUserState(userId, hubConstant.HUB_USER_STATE_ACTIVE);
    }

    updateMediaObjProperties = async (elementId, request) => {
        Logger.debug(`Update Media Object Properties for id=${elementId}, request=`, request);
        let token = await authService.getAccessTokenAsync();
        const response = await fetch(`/api/element/${elementId}/mediaobject`, {
            method: "PUT",
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json; charset=UTF-8'
            },
            body: JSON.stringify(request),
        });
        Logger.debug(`Update Media Object Properties response:`, response);
        return response;
    }
}

const hubApi = new HubApi();
export default hubApi;
import React from "react";
import { Row, Col, Button, Form } from 'react-bootstrap'
import * as Logger from 'loglevel';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { mapDispatchToMetadataProps } from 'store/actions/HubMetadataActions';
import { mapDispatchToHubObjectBoardProps } from 'store/actions/HubObjectBoardActions';

import Cookies from 'js-cookie';

//import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';

import MetadataPerNewsItem from "./MetadataPerNewsItem";

//const createSliderWithTooltip = Slider.createSliderWithTooltip;
//const Range = createSliderWithTooltip(Slider.Range);

// Metadata structure:
// "fieldId": 10006,
// "type": 0,
// "dataType": 3,
// "fieldName": "tester",
// "description": "testerr",
// "isControlledVocabulary": true,
// "vocabulary": [
//   {
//     "index": 1,
//     "keyword": "testerr"
//   }
// ]

class MetadataPerNewsItemForm extends React.Component {
    constructor(props) {
        const { payload: newsItem } = props;
        const newsItemId = !!newsItem ? newsItem.id : null;

        super(props);
        this.form = React.createRef();
        Logger.debug(newsItemId)
        //To prevent reference-type:
        Logger.debug(Cookies.get('numOfMetadataPerRow'));
        this.state = {

            metadata: null, //initialize in componentWillMount

            //numOfMetadataPerRow: !!Cookies.get('numOfMetadataPerRow') ? parseInt(Cookies.get('numOfMetadataPerRow')) : 2, //cookie used to store UI customization data
            numOfMetadataPerRow: 3,

            formError: undefined,
            isStatusEnabled: false,
            changeNotifier: false,
            isFocusingMembersUI: false,

            isCreatingMetadata: false,

            focusedFieldIndex: -1,
            addCounter: 1, //increment this val so each added val has a new and unique index
        };

        this.props.setformWidthMultiplier(this.state.numOfMetadataPerRow);

        this.newsItemId = !!newsItemId ? newsItemId : -1;
        Logger.debug(this.newsItemId);

        this.oldMetadataState = JSON.parse(JSON.stringify(this.state));
        Logger.debug("oldMetadataState: ", this.oldMetadataState);
    }

    async componentWillMount() {
        await this.initializeNewsItemMetadata();
    }

    async initializeNewsItemMetadata() {

        if (!!this.props.isIntegratedForm) { await this.props.initializeNewsItemMetadata(); } else {
            //need to fetch nodes, for the ones without group
            //fetch newsobject metadata if it's null:
            await this.props.getMetadata();
            //if (!(!!this.props.objectList.find(newsObj => newsObj.id == this.newsItemId).metadata)) {
            if (this.newsItemId > -1) await this.props.getNewsItemMetadata(this.newsItemId);
            //}

            var newMetadataList = [];
            this.props.metadata.forEach(metadata => {
                var metadataFieldInNewsObj = null;
                var newsItem = this.props.objectList.find(newsObj => newsObj.id === this.newsItemId);
                if (!!newsItem) metadataFieldInNewsObj = newsItem.metadata.find(newsObjMetadata => newsObjMetadata.fieldId === metadata.fieldId);

                if (!!metadataFieldInNewsObj) {
                    //if metadataField is found in objectList, use metadataFieldInNewsObj
                    Logger.debug(this.props.metadata);
                    var metadataFieldInNewsObjWithCompleteMetadata = JSON.parse(JSON.stringify(metadataFieldInNewsObj)); //remove object ref
                    if (metadata.isControlledVocabulary) metadataFieldInNewsObjWithCompleteMetadata["availableControlledVocabularies"] = metadata.vocabulary;
                    metadataFieldInNewsObjWithCompleteMetadata["isControlledVocabulary"] = metadata.isControlledVocabulary;
                    metadataFieldInNewsObjWithCompleteMetadata.values = metadataFieldInNewsObjWithCompleteMetadata.values.map(vocab => { return { ...vocab, isChecked: false } }); //for delete selection
                    newMetadataList.push(metadataFieldInNewsObjWithCompleteMetadata);
                } else {
                    newMetadataList.push({
                        fieldId: metadata.fieldId,
                        dataType: metadata.dataType,
                        fieldName: metadata.fieldName,
                        values: [],
                        ...metadata.isControlledVocabulary && { availableControlledVocabularies: metadata.vocabulary },
                        isControlledVocabulary: metadata.isControlledVocabulary
                    });
                }
            });
            Logger.debug(newMetadataList);
            var newChangeNotifier = !this.state.changeNotifier;
            this.setState({ metadata: newMetadataList, changeNotifier: newChangeNotifier });
        }
    }

    onSelectValue = (fieldId, valIndex, isSelected) => {
        if (!!this.props.isIntegratedForm) { this.props.onSelectValue(fieldId, valIndex, isSelected); } else {
            var newMetadata = null;
            Logger.debug(fieldId); Logger.debug(valIndex);
            if (isSelected !== undefined) {
                newMetadata = this.state.metadata;
                Logger.debug(newMetadata)
                newMetadata.find(dataField => dataField.fieldId === fieldId).values.find(val => val.index === valIndex).isChecked = isSelected;
                //newMetadata.forEach(dataField => dataField.values.forEach(val => {Logger.debug(val.isChecked);val.isChecked = isSelected}));
            } else {
                //Toggle on/off:
                var isChecked = this.state.metadata.find(dataField => dataField.fieldId === fieldId).values.find(val => val.index === valIndex).isChecked;
                Logger.debug(isChecked);
                isChecked = !isChecked;
                Logger.debug(this.state.metadata);

                newMetadata = this.state.metadata;
                newMetadata.find(dataField => dataField.fieldId === fieldId).values.find(val => val.index === valIndex).isChecked = isChecked;
            }

            var newChangeNotifier = !this.state.changeNotifier;
            this.setState({ changeNotifier: newChangeNotifier });
        }
    }

    onDeleteSelectedValues = () => {
        if (!!this.props.isIntegratedForm) { this.props.onDeleteSelectedValues() } else {
            this.state.metadata.forEach(dataField => {
                dataField.values = dataField.values.filter(val => !val.isChecked);
            });
            var newChangeNotifier = !this.state.changeNotifier;
            this.setState({ changeNotifier: newChangeNotifier });
        }
    }

    onDeleteSelectedVal = (fieldId, valIndex) => {
        if (!!this.props.isIntegratedForm) { this.props.onDeleteSelectedVal(fieldId, valIndex) } else {
            Logger.debug(fieldId);
            Logger.debug(valIndex);

            var foundVals = this.state.metadata.find(dataField => dataField.fieldId === fieldId).values;
            foundVals = foundVals.filter(val => val.index !== valIndex);

            //Assign to state:
            this.state.metadata.find(dataField => dataField.fieldId === fieldId).values = foundVals;

            Logger.debug(foundVals);

            var newChangeNotifier = !this.state.changeNotifier;
            this.setState({ changeNotifier: newChangeNotifier });
        }
    }

    onChange = (val, index) => {
        if (!!this.props.isIntegratedForm) { this.props.onChange(val, index) } else {
            Logger.debug(val);
            Logger.debug(index);
            var newMetadata = JSON.parse(JSON.stringify(this.state.metadata));
            newMetadata[index].fieldVal = val;
            Logger.debug(newMetadata);
            this.setState({ metadata: newMetadata });
        }
    }

    validateForm = () => (this.form.current.checkValidity());

    submitHandler = async (event) => {
        event.preventDefault();
        Logger.debug('Saving metadata, event=', event);
        if (!event.target.className.includes(" was-validated")) {
            event.target.className += " was-validated";
        }
        if (this.validateForm()) {
            await this.onUpdateNewsItemMetadata();
        }
    };

    async onUpdateNewsItemMetadata() {
        if (!!this.props.isIntegratedForm) { await this.props.onUpdateNewsItemMetadata() } else {
            var body = {
                metadata: this.state.metadata.filter(data => data.values.length > 0).map(data => {
                    return {
                        fieldId: data.fieldId,
                        values: data.values.map(value => value.value)
                    }
                })
            };
            await this.props.updateNewsItemMetadata(this.newsItemId, body);
            await this.props.getNewsItemMetadata(this.newsItemId);

            Logger.debug(this.props.error);
            if (!!this.props.success) {
                this.setState({ isStatusEnabled: true }, () => this.props.onFormClosed());
            } else if (!!this.props.error) {
                this.setState({ isStatusEnabled: true });
            }
        }
    }

    onLocalAddNewsItemMetadata = (id, value) => {
        if (!!this.props.isIntegratedForm) { this.props.onLocalAddNewsItemMetadata(id, value) } else {
            Logger.debug(value);
            Logger.debug(id);
            var newMetadata = this.state.metadata;
            newMetadata.forEach(dataVal => {
                Logger.debug(dataVal)
                if (id === dataVal.fieldId)
                    dataVal.values.push({ index: -this.state.addCounter, value: value, isChecked: false });
            });
            Logger.debug(newMetadata)
            var newAddCounter = this.state.addCounter + 1;
            this.setState({ metadata: newMetadata, addCounter: newAddCounter }, () => this.setState({ changeNotifier: !this.state.changeNotifier }));
        }
    }

    renderStatus() {
        if (!!this.props.error) {
            return <Row className="status"><Col xs="12"><p className="UpdateError">Error {this.props.error.status}: {this.props.error.statusText}</p></Col></Row>
        } else if (!!this.props.success) {
            return <Row className="status"><Col xs="12"><p className="Success">{this.props.success}</p></Col></Row>
        }
    }

    render() {
        const { payload: metadata } = this.props;
                let isUpdateMetadata = !!metadata.id;
        return (
            <div className="HubUserForm HubNodeGroupForm">
                    <Form ref={this.form} className="needs-validation burli-metadata-form mt-3" onSubmit={this.submitHandler} noValidate >
                        <Row>
                            <Col size="12" className="HubNameRow">
                                <MetadataPerNewsItem
                                    newsItemType={this.props.payload.type}
                                    isForm={true}
                                    formData={!!this.props.isIntegratedForm && !!this.props.integratedMetadata ? this.props.integratedMetadata.filter(mdata => mdata.fieldId !== 1) : this.state.metadata}
                                    changeNotifier={!!this.props.isIntegratedForm ? this.props.integratedChangeNotifier : this.state.changeNotifier}
                                    onLocalAddNewsItemMetadata={this.onLocalAddNewsItemMetadata}
                                    onSelectValue={this.onSelectValue}
                                    onDeleteSelectedValues={this.onDeleteSelectedValues}
                                    onDeleteSelectedVal={this.onDeleteSelectedVal}
                                    isNotificationAllowed={this.props.isNotificationAllowed}
                                    arrOfNewData={this.props.arrOfNewData}
                                >
                                </MetadataPerNewsItem>
                            </Col>
                        </Row>

                        {isUpdateMetadata ?
                            <>{this.state.isStatusEnabled ?
                                <>
                                    {this.renderStatus()}
                                </> : <></>}

                                <Row className="SelectionStatus">
                                    <Col size="12">
                                        <Button color="light" type="submit" size="sm" className={`float-right mr-0`}>{isUpdateMetadata ? "Save and Close" : "Create and Close"}</Button>
                                    </Col>
                                </Row>
                            </>
                            : <></>
                        }
                    </Form >
            </div >
        );
    }
}

// export default withRouter(connect(
//     mapMetadataStateToProps,
//     dispatch => bindActionCreators(mapDispatchToMetadataProps, dispatch)
// )(MetadataPerNewsItemForm));

const mapMetadataAndObjectBoardStatesToProps = (state) => {
    return {
        objectList: state.hubObjectContent.objectList,
        metadata: state.hubConfig.hubMetadata.metaData,
        success: state.hubConfig.hubMetadata.success,
        error: state.hubConfig.hubMetadata.error
    }
}

export default withRouter(connect(
    mapMetadataAndObjectBoardStatesToProps,
    dispatch => bindActionCreators({ ...mapDispatchToMetadataProps, ...mapDispatchToHubObjectBoardProps }, dispatch)
)(MetadataPerNewsItemForm));
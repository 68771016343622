import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Nav, Navbar, Row, Col } from 'react-bootstrap';
import { faBars, faBook, faFileAudio, faUsers, faCity, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { faChromecast } from '@fortawesome/free-brands-svg-icons';

import { Collapse } from 'react-bootstrap';

import { Link, withRouter } from "react-router-dom";

import { RotatingArrow } from 'hub-dashboard-framework'

import * as HubConstant from 'util/HubConstant';
import authService from 'components/api-authorization/AuthorizeService';
import { UtilConstant } from 'hub-utilities'
//Redux: 
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as HubLogoActions from 'store/actions/HubLogoActions';
import * as HubSettingsActions from 'store/actions/HubSettingsActions';

import 'styles/sections/SideNavigation.scss';

class SideNavigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sideItemExpanded: [true, true],
      mobileNavBarExpanded: false,
      mobileNavColor: "#FFFFFF"
    }
  }

  async componentWillMount() {
    await this.props.startLogoFetch();
    await this.props.getHubInfo();
  }

  toggle = (num) => {
    //Must use the set state function...
    var newArr = [];
    for (var i = 0; i < this.state.sideItemExpanded.length; i++) {
      if (i === num) {
        newArr.push(!this.state.sideItemExpanded[i]);
      }
      else {
        newArr.push(this.state.sideItemExpanded[i]);
      }
    }
    this.setState({ sideItemExpanded: newArr });
  }

  onCollapseChangeArrow(index) {
    // if (this.state.sideItemExpanded[index]) { return (<FontAwesomeIcon onAnimationEnd="" className="fa-arrows " icon={faCaretDown} />) };
    // return (<FontAwesomeIcon className="fa-arrows" icon={faCaretRight} />);
    return <RotatingArrow isRowExpanded={this.state.sideItemExpanded[index]} isInitiallyExpanded/>
  }

  setMobileNavBarExpanded = () => {
    this.setState({ mobileNavBarExpanded: !this.state.mobileNavBarExpanded });
    this.setMobileNavColor();
  }

  setMobileNavColor() {
    this.setState({ mobileNavColor: (this.state.mobileNavBarExpanded) ? "#FFFFFF" : "#ff8c00" })
  }

  setClassOnNavActive(navButton) {
    if (this.props.activeNav === navButton)
      return "sideNavActive";
    return "sideNavInactive";
  }

  generateNavLink(index, pluralName, urlDirectory, fa, isMultiline) {
      return (
         <div key={index} className={`sidebarWrapper ${(this.props.activePageName.plural === pluralName) ? " sidebarWrapperActive" : ""}`}>
             <Link to={urlDirectory}
                  className="subNav  padding-values">
                  {
                    (!isMultiline) ? <>{!!fa ? <FontAwesomeIcon className="fa-arrows" icon={fa}/> : <></>} {pluralName}</>
                    :
                    <>
                      <div className="multilineNav">
                        {!!fa ? <FontAwesomeIcon className="fa-arrows" icon={fa}/> : <></>}
                      </div>
                      <div className="multilineNavText">
                        {pluralName}
                      </div>
                    </>
                  }
             </Link>
         </div>
      );
  }

  generateMainNav(parentTitle, parentUrlDirectory, arrOfNavChildren, mainNavIndex) {
    return (
      <>
      <Nav.Link
      className={`
      ${(arrOfNavChildren.findIndex(childNav => childNav.pluralName === this.props.activePageName.plural) > -1
            && !this.state.sideItemExpanded[mainNavIndex])
            ? " sidebarWrapperParentActive" : ""}
      `}
        eventKey={`item${mainNavIndex}`} onClick={() => this.toggle(mainNavIndex)}>
        {this.onCollapseChangeArrow(mainNavIndex)}
      {parentTitle}
    </Nav.Link>
      <Collapse in={this.state.sideItemExpanded[mainNavIndex]}>
        <div>
          {arrOfNavChildren.map( (navVal, index) => {
            return this.generateNavLink(index, navVal.pluralName, `/${parentUrlDirectory}/${navVal.childUrlDirectory}`, navVal.fa, navVal.isMultiline)
          })}
        </div>
      </Collapse>
      </>
    )
  }


  render() {
    console.log("hubInfo: ", authService.getHubInfo())
    //Set classes below to slide in/slide out depending on the state for nav-bar animation
    const horizontalCollapseClasses = ['slideHorizontal'];
    if (this.state.mobileNavBarExpanded) {
      horizontalCollapseClasses.push('slideIn');
    } else {
      horizontalCollapseClasses.push('slideOut');
    }

    return (
      <div>
        <div onClick={this.setMobileNavBarExpanded}>
          <FontAwesomeIcon
            className="mobileNavActivator"
            icon={faBars}
            style={{ color: this.state.mobileNavColor }}></FontAwesomeIcon>
        </div>
        <div className={`SideNavbar ${horizontalCollapseClasses.join(' ')}`}>
          <div className="sideNavBackground"></div>
          <Navbar className={`shadow sideNavMain`}>
            <Nav className="flex-column">
              <Link to="/">
                {
                  (!!this.props.logoObj)
                    ?
                    <img className="imgCard" src={this.props.logoObj} alt="Logo"></img>
                    :
                    <img className="imgCard" src="/images/HubBrandingDefault-1.2-Grey.png" alt="Logo"></img>
                }
              </Link>
              {this.generateMainNav("Arrival Board", "arrivalboard",
                    [{pluralName:"Stories", childUrlDirectory:"story", fa:faBook, isMultiline:false}, 
                     {pluralName:"Media Library", childUrlDirectory:"media", fa:faFileAudio, isMultiline:false}], 0)}

              {                            
                authService.getUserType() !== UtilConstant.HUB_USER_TYPE.HUB_USER ?
                this.generateMainNav("Administration", "admin",
                    [{pluralName:"Users", childUrlDirectory:"users", fa:faUsers, isMultiline:false}, 
                     {pluralName:"Production Centres", childUrlDirectory:"pdcenter", fa:faCity, isMultiline:false},
                     authService.getUserType() === UtilConstant.HUB_USER_TYPE.HUB_ADMIN && {pluralName:"Hub", childUrlDirectory:"hub", fa:faChromecast, isMultiline:false}], 1) : null
              }
            </Nav>
          </Navbar>
          <div className="NavBarFooter">
            <Row>
              <Col xs="6">
                <Link
                  to="/admin/about">
                  <img className="imgCard" src="/images/burli_ID_colour.png" alt="Footer"></img>
                </Link>
              </Col>
              <Col xs="5">
                <h5>{
                !!this.props.hubInfo ? 
                  !!this.props.hubInfo.hubInfo.customerName ? 
                    this.props.hubInfo.hubInfo.customerName : 
                    this.props.hubInfo.appName : 
                !!authService.getHubInfo() ? 
                  !!authService.getHubInfo().hubInfo.customerName ? 
                    authService.getHubInfo().hubInfo.customerName : 
                    authService.getHubInfo().appName : 
                  "Burli NewsHub"
                  }
                  <br /><span className="hubSpecs">Version: &nbsp;{
                  (!!this.props.hubInfo) ? this.props.hubInfo.appVersion 
                  : (!!authService.getHubInfo()) ? authService.getHubInfo().appVersion
                  : ""}
                    <br />
                    <Link
                      to="/admin/about"
                      className="AboutButton"
                        /*onClick={() => this.props.handleSetActiveNav(NavButton.SUBMENU_ITEM_B)}*/>
                      <FontAwesomeIcon className="fa-arrows" icon={faInfoCircle} />
                        About
                    </Link>
                  </span></h5>
              </Col>
            </Row>
          </div>
        </div>
      </div>

    );
  }
}

const mapLogoAndSettingStatesToProps = (state) => {
  return {
      logoObj: state.hubConfig.hubLogo.logoObj,
      hubInfo: state.hubConfig.hubSettings.hubInfo
  }
}

export default withRouter(connect(
  mapLogoAndSettingStatesToProps,
  dispatch => bindActionCreators({...HubSettingsActions.mapSettingsDispatcherToProps, ...HubLogoActions.mapLogoDispatcherToProps}, dispatch)
)(SideNavigation));
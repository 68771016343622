import * as Logger from 'loglevel';
import * as ActionType from '../../ActionType';

// state and reducer here:

const initialState = {
    settings: null,
    error: null,
    setNameSuccess: null,
    isUpdatingLicense: false,
    updateLicensingSuccess: null,
    updateLicensingError: null,
    updateSettingsSuccess: null,
    setNameError: null,
    updateSettingsError: null,
    hubInfo: null,
    hubEndpoints: null,
    justFinishedReset: false
};

export const reducer = (state, action) => {
    if (!state) {
        state = initialState;
    }
    switch (action.type) {
        case ActionType.FETCH_SETTINGS_BEGIN:
        case ActionType.UPDATE_SETTINGS_BEGIN:
        case ActionType.SET_HUB_NAME_BEGIN:
            Logger.debug(`Logo reducer: action = ${action.type}`);
            return ({
                ...state,
                updateLicensingSuccess: null,
                updateLicensingFailure: null,
                updateSettingsSuccess: null,
                updateSettingsError: null,
                setNameSuccess: null,
                setNameError: null,
                error: null,
            });
        case ActionType.GET_HUB_INFO_BEGIN:
            Logger.debug("GETHUBINFO")
            Logger.debug(`Logo reducer: action = `, action);
            return ({
                ...state,
                isUpdatingLicense: !!action.additionalParams.isUpdatingLicense ? action.additionalParams.isUpdatingLicense : false,
                updateLicensingSuccess: null,
                updateLicensingFailure: null,
                updateSettingsSuccess: null,
                updateSettingsError: null,
                //setNameSuccess: null,
                //setNameError: null,
                error: null,
            });
        case ActionType.FETCH_SETTINGS_SUCCESS:
            Logger.debug(`Logo reducer: action = ${action.type}`);
            Logger.debug("Logo reducer: action =", action);
            return ({
                ...state,
                settings: {
                    purge: (!!action.payload.settings.maintenance.purge) ? action.payload.settings.maintenance.purge : state.settings.purge,
                    schedule: (!!action.payload.settings.maintenance.schedule) ? action.payload.settings.maintenance.schedule : state.settings.schedule
                },
                error: null,
                success: null
            });
        case ActionType.UPDATE_SETTINGS_SUCCESS:
            Logger.debug(`Logo reducer: action = ${action.type}`);
            Logger.debug("Logo reducer: action =", action);
            return ({
                ...state,
                error: null,
                setNameSuccess: null,
                updateSettingsSuccess: !action.isReset ? "Hub Settings Update Successful" : null,
                justFinishedReset: action.isReset,
                setNameError: null,
                updateSettingsError: null
            });

        case ActionType.SET_HUB_NAME_SUCCESS:
            Logger.debug(`Logo reducer: action = ${action.type}`);
            Logger.debug("Logo reducer: action =", action);
            return ({
                ...state,
                error: null,
                setNameSuccess: "Hub Name Update Successful",
                updateSettingsSuccess: null,
                setNameError: null,
                updateSettingsError: null
            });

        case ActionType.GET_HUB_INFO_SUCCESS:
            Logger.debug(`Logo reducer: action = ${action.type}`);
            Logger.debug("Logo reducer: action =", action);
            return ({
                ...state,
                isUpdatingLicense: false,
                error: null,
                updateLicensingSuccess: state.isUpdatingLicense ? "License Updated Successfully" : "",
                hubInfo: action.payload
            });

        case ActionType.GET_HUB_ENDPOINTS_SUCCESS:
            Logger.debug(`Logo reducer: action = ${action.type}`);
            Logger.debug("Logo reducer: action =", action);
            return ({
                ...state,
                error: null,
                hubEndpoints: action.payload.endPoints
            });

        case ActionType.FETCH_SETTINGS_FAILURE:
            Logger.debug(`Logo reducer: action = ${action.type}`);
            return (
                {
                    ...state,
                    success: null
                });

        case ActionType.UPDATE_SETTINGS_FAILURE:
            Logger.debug(`Logo reducer: action = ${action.type}`);
            return (
                {
                    ...state,
                    error: action.payload,
                    setNameSuccess: null,
                    updateSettingsSuccess: null,
                    setNameError: null,
                    updateSettingsError: action.payload

                });

        case ActionType.SET_HUB_NAME_FAILURE:
            Logger.debug(`Logo reducer: action = ${action.type}`);
            Logger.debug("Logo reducer: action =", action);
            return ({
                ...state,
                error: action.payload,
                setNameSuccess: null,
                updateSettingsSuccess: null,
                setNameError: action.payload,
                updateSettingsError: null
            });

        case ActionType.GET_HUB_INFO_FAILURE:
            Logger.debug(`Logo reducer: action = ${action.type}`);
            Logger.debug("Logo reducer: action =", action);
            return ({
                ...state,
                isUpdatingLicense: false,
                updateLicensingError: state.isUpdatingLicense ? action.error : "",
                error: action.payload,
            });



        case ActionType.EDIT_SETTINGS_INPUT:
            Logger.debug(`Logo reducer: action = ${action.type}`);
            return (
                {
                    ...state,
                    setNameSuccess: null,
                    updateSettingsSuccess: null,
                    setNameError: null,
                    updateSettingsError: null,
                    error: null
                });


        default:
            break;
    }
    return state;
}

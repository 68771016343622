import * as HubConstant from 'util/HubConstant'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import Utilities, { UtilConstant } from 'hub-utilities'
import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const renderHubNodeCount = (hubNode) => {
    var nodeCount = 0;
    hubNode.forEach((hubNodeElm) => {
        if (hubNodeElm.type === 2 || hubNodeElm.type === 3) {
            nodeCount += hubNodeElm.count;
        }
    })
    return nodeCount;
}

export const renderHubInfo = (hubInfoAry, actualData) => {
    console.log("Actaul data: ", actualData)
    if (!!actualData && Object.keys(actualData).length > 0 && actualData.hubInfo.hubId !== "Unknown") {
        return hubInfoAry.map((hubInfoData, index) => {
            switch (hubInfoData.type) {
                case HubConstant.HUB_INFO_TYPE.GENERAL:
                    return <p key={`hubInfoGeneral_${index}`} className="AboutInfo"><span><b>{hubInfoData.title}:</b>&nbsp;</span>
                        {actualData[hubInfoData.key]}
                    </p>
                case HubConstant.HUB_INFO_TYPE.HUB_INFO:
                    return <p key={`hubInfoInfo_${index}`} className="AboutInfo"><span><b>{hubInfoData.title}:</b>&nbsp;</span>
                        {hubInfoData.key === "createTime" ?
                            Utilities.formatObjectDate(actualData["hubInfo"][hubInfoData.key], UtilConstant.HUB_TIME_FORMAT.ONLY_DATE, true, false, false) :
                            hubInfoData.key === "expireTime" ?
                                <Fragment>
                                    <span style={
                                        Utilities.dateDiffInDays(new Date(actualData["hubInfo"][hubInfoData.key]), new Date()) < 14 ? { color: "red" } :
                                            Utilities.dateDiffInDays(new Date(actualData["hubInfo"][hubInfoData.key]), new Date()) < 30 ? { color: "orange" } : {}
                                    }>
                                        {Utilities.formatObjectDate(actualData["hubInfo"][hubInfoData.key], "MMM dd yyyy", true, true, false)} (UTC)&nbsp;
                                    </span>
                                    <OverlayTrigger
                                        key="tooltip_expireTime_top"
                                        placement="top"
                                        overlay={
                                            <Tooltip id={`tooltip-top`}>
                                                {Utilities.formatObjectDate(actualData["hubInfo"][hubInfoData.key], "h:mmTT MMM dd, yyyy zzz", true, false, false)}
                                            </Tooltip>
                                        }
                                    >
                                        <FontAwesomeIcon style={{fontSize:'18px', transform: 'translate(0, 10%)'}} icon={faInfoCircle} />
                                    </OverlayTrigger>
                                </Fragment> :
                                actualData["hubInfo"][hubInfoData.key]}
                    </p>
                case HubConstant.HUB_INFO_TYPE.ENVIRONMENT:
                    return <p key={`hubInfoEnv_${index}`} className="AboutInfo"><span><b>{hubInfoData.title}:</b>&nbsp;</span>{actualData["environment"][hubInfoData.key]}</p>
                case HubConstant.HUB_INFO_TYPE.RUNTIME:
                    return (
                        <p key={`hubInfoRuntime_${index}`} className="AboutInfo">
                            <span><b>{hubInfoData.title}:</b>&nbsp;</span>
                            {(!!hubInfoData.isStorageSize) ? Utilities.formatStorageSize(actualData["runtime"][hubInfoData.key]) : actualData["runtime"][hubInfoData.key]}
                        </p>
                    )
                case HubConstant.HUB_INFO_TYPE.NODES:
                    return (
                        <Fragment key={`hubInfoRuntimeNodes_${index}`}>
                            <p key={`hubInfoNodes_${index}`} className="AboutInfo">
                                <span><b>{hubInfoData.title}:</b>&nbsp;</span>
                                {renderHubNodeCount(actualData["hubInfo"]["nodes"])}
                            </p>
                        </Fragment>

                    )
                case HubConstant.HUB_INFO_TYPE.CUSTOMIZED: // {customized: {key: x, val: y}}
                    return <p key={`hubInfoGeneral_${index}`} className="AboutInfo"><span><b>{hubInfoData.title}:</b>&nbsp;</span>
                        <div style={{display: "inline-table"}}>
                            {!!hubInfoData.val ? hubInfoData.val.map(val => <p style={{marginBottom: 0}}>{val}</p>) : <></>}
                        </div>
                    </p>
                default:
                    return <Fragment key={`hubInfoNone_${index}`}></Fragment>
            }
        })
    }
}
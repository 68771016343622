import React, { Fragment } from 'react';
import * as Logger from 'loglevel';
import { DataTable, DataTableConstant, TableToolType, TableRowAction, CollapsibleRowType } from 'hub-dashboard-framework'
import authService from '../../../api-authorization/AuthorizeService';
import Utilities, { UtilConstant } from 'hub-utilities';
import PropTypes from "prop-types";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import HubApi from 'util/HubApi'

import { mapDispatchToHubObjectBoardProps } from 'store/actions/HubObjectBoardActions';
import { mapDispatchToMetadataProps } from 'store/actions/HubMetadataActions';
import { mapDispatchToProps } from 'store/actions/HubConfigActions';

import { Row, Col } from 'react-bootstrap';
import * as _ from 'lodash'

import * as HubConstant from "util/HubConstant";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCertificate, faPlaneArrival, faUserEdit, faCity, faStopwatch, faTags, faFileAudio, faExclamationTriangle, faListAlt } from '@fortawesome/free-solid-svg-icons';
import { extractNodesFromData, extractNodeGroups, extractCategories, onReceivePropsParseMetadata, navigateDataObj } from '../../libraries/DataHandler'

import 'styles/tables/NewsItem.scss'

class Media extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeNodeId: props.nodeId,
            searchText: "",
            fetchedData: [],
            activeNodeName: "All",
            urgentCheckboxState: DataTableConstant.HUB_CHECKBOX_STATE.EMPTY
        };
        this.headerConfig = {
            id: -1,
            type: HubConstant.HUB_OBJECT_ELEMENT,
            Title: { val: "null", canSort: true },
            Arrived: { val: "null", canSort: true, date: "", isInitiallySorted: true, headerIcon: faPlaneArrival, hasRowIcon: true },
            CreatedBy: { val: "null", canSort: false, headerIcon: faUserEdit },
            ProductionCentre: { val: "null", canSort: true, headerIcon: faCity },
            Duration: { val: "null", canSort: true, headerIcon: faStopwatch },
            Tags: { val: "null", canSort: false, headerIcon: faTags },
            Collapsible: null,
            rowActions: [
                { action: TableRowAction.EDIT_ROW, minAuthorityRequired: UtilConstant.HUB_USER_TYPE.HUB_NODE_ADMIN },
                { action: TableRowAction.DELETE_ROW, minAuthorityRequired: UtilConstant.HUB_USER_TYPE.HUB_NODE_ADMIN }
            ]
        };
    }

    async componentWillMount() {
        await authService.getAccessToken()
        await this.props.loadNodeContents(this.state.activeNodeId, this.props.objectType, this.state.searchText, DataTableConstant.HUB_ACTION_STATE.INITIALIZE);
        // //objectList is the redux state
        // this.setState({
        //     fetchedData: this.fetchData(this.props.objectList)
        // });

        await this.fetchFilterBarData();
    }

    async fetchFilterBarData() {
        await this.props.getNodeGroups(false, false);
        await this.props.getMetadata();
        await this.props.requestNodes();
    }

    componentDidMount() {
        this.props.setActivePageName({ singular: "Media Library", plural: "Media Library", icon: faFileAudio });
    }

    //To properly map the fetchedData variable to redux:
    componentWillReceiveProps(nextPropsFromRedux) {
        if (!_.isEqual(nextPropsFromRedux.objectList, this.props.objectList)) {
            this.setState({ fetchedData: this.fetchData(nextPropsFromRedux.objectList) })
        }
    }

    getNewsItemMetadata = async (dataObjId) => {
        await this.props.getNewsItemMetadata(dataObjId);
    }

    //TODO: Fix table border bug:
    fetchData(objects) {
        Logger.debug(`objects are`, objects);
        if (objects !== undefined && objects != null && objects.length > 0) {
            return objects.filter((o) => { return o.objectType.type === "Element"; }).map((o) => {
                return (
                    {
                        //Lower case keys won't get posted to table
                        id: o.id,
                        type: HubConstant.HUB_OBJECT_ELEMENT,
                        subObjectCount: o.subObjectCount,
                        isUrgent: Utilities.formatUrgency(o.priorityLevel) === 'Urgent',
                        Title: { val: o.name, canSort: true },
                        Arrived: {
                            val: Utilities.formatObjectDate(o.createInfo.time, UtilConstant.HUB_TIME_FORMAT.CANADIAN), canSort: true, date: o.createInfo.time, isInitiallySorted: true,
                            rowIcon: Math.round(Math.round((new Date() - new Date(o.createInfo.time)) / 1000) / 60) <= 9 ? <FontAwesomeIcon icon={faCertificate} /> : undefined,
                            hasRowIcon: true, headerIcon: faPlaneArrival
                        },
                        CreatedBy: { val: authService.getCreatorName(o.createInfo.id), canSort: false, headerIcon: faUserEdit },
                        ProductionCentre: { val: o.origin ? authService.getHubNodeName(o.origin.nodeId) : authService.getNodeNameByUserId(o.createInfo.id), canSort: true, headerIcon: faCity },
                        Duration: { val: Utilities.formatRunTime(o.runTime, false), canSort: true, headerIcon: faTags },
                        Tags: { val: o.keyword, canSort: false },
                        Collapsible: {
                            id: o.id,
                            numOfColsPerRow: 4,
                            data:
                                [
                                    { rowType: CollapsibleRowType.REGULAR, val: "Element Number: ", colsOccupied: 1, hideIfNull: o.id },
                                    { rowType: CollapsibleRowType.REGULAR, val: o.id, colsOccupied: 1, hideIfNull: o.id },
                                    { rowType: CollapsibleRowType.REGULAR, val: "Duration: ", colsOccupied: 1, hideIfNull: o.runTime },
                                    { rowType: CollapsibleRowType.REGULAR, val: Utilities.formatRunTime(o.runTime), colsOccupied: 1, hideIfNull: o.runTime },

                                    { rowType: CollapsibleRowType.REGULAR, val: "Urgency: ", colsOccupied: 1, hideIfNull: o.priorityLevel, isUrgency: true },
                                    { rowType: CollapsibleRowType.REGULAR, val: Utilities.formatUrgency(o.priorityLevel), colsOccupied: 1, hideIfNull: o.priorityLevel, isUrgency: true, isUrgencyVal: true },
                                    { rowType: CollapsibleRowType.REGULAR, metadataId: 1, isMetadataName: true, colsOccupied: 1, hideIfNull: o.id },
                                    { rowType: CollapsibleRowType.REGULAR, metadataId: 1, isMetadataVals: true, colsOccupied: 1, hideIfNull: o.id },

                                    { rowType: CollapsibleRowType.REGULAR, val: "Mime Type: ", colsOccupied: 1, hideIfNull: o.id },
                                    { rowType: CollapsibleRowType.REGULAR, fetchId: o.id, fetchObjKeys: navigateDataObj(o, ["newsContent", "type", "mimeType"]), colsOccupied: 3, hideIfNull: o.id },

                                    { rowType: CollapsibleRowType.PARAGRAPH, key: "Summary", val: o.summary, hideIfNull: o.summary },

                                    {
                                        rowType: CollapsibleRowType.WHOLE_COL_RIGHT,
                                        fetchId: o.id,
                                        elementId: navigateDataObj(o, ["objectId"]),
                                        contentType: navigateDataObj(o, ["newsContent", "type", "id"]),
                                        mediaFetchUrl: `/api/element/${navigateDataObj(o, ["id"])}/mediaobject?oauth_token=${authService._accessToken}`,
                                        elementName: navigateDataObj(o, ["name"]),
                                        mimeType: navigateDataObj(o, ["newsContent", "type", "mimeType"]),
                                        outQ: navigateDataObj(o, ["newsContent", "outQ"]),
                                        verbatim: navigateDataObj(o, ["newsContent", "verbatim"]),
                                        hideIfNull: o.id
                                    }
                                ],
                            state: !!o.subObjectState ? o.subObjectState : HubConstant.HUB_SUBOBJECT_STATE.IDLE, // just so you know, HUB_SUBOBJECT_STATE === HUB_COLLAPSIBLE_ROW_STATE
                            metadata: onReceivePropsParseMetadata(o, this.props.metadata),
                            isLoading: false, //for the loading icon
                            onPreviewMedia: (mediaId) => HubApi.previewMediaObj(!!mediaId ? mediaId : o.id),
                            onDownloadMedia: async (mediaId) => await HubApi.downloadMediaObj(!!mediaId ? mediaId : o.id, o.name),
                            fetchData: async () => await this.props.getNewsItemSubObject(o.id),
                            fetchNewsItemMetadata: async () => await this.props.getNewsItemMetadata(o.id)
                        },
                        rowActions: [
                            { action: TableRowAction.EDIT_ROW, form: HubConstant.HUB_UPDATE_NEWSITEM_METADATA_FORM, minAuthorityRequired: UtilConstant.HUB_USER_TYPE.HUB_NODE_ADMIN },
                            { action: TableRowAction.DELETE_ROW, minAuthorityRequired: UtilConstant.HUB_USER_TYPE.HUB_NODE_ADMIN }
                        ]

                    }
                );
            })
        } else {
            return [];
        }
    }

    onChangeFilterBar = (filters, requireAPIRefresh) => {
        Logger.debug(filters);
        var nodeIds = filters.find(filter => filter.title === "Production Centre").toFilter.map(filteredNode => filteredNode.id);
        var categories = {
            id: 1,
            name: filters.find(filter => filter.title === "Category").toFilter.map(filteredCat => filteredCat.name)
        }
        this.setState({
            urgentCheckboxState: filters.find(filter => filter.title === "Urgent").state
        })
        //TODO: (if more time: requireAPIRefresh only for api-required filters, not urgent/hasmedia)
        //if (!!requireAPIRefresh) this.props.setNodeFilter(nodeIds, HubConstant.HUB_OBJECT_STORY, categories);
        this.props.setNodeFilter(nodeIds, HubConstant.HUB_OBJECT_ELEMENT, categories);
    }

    displayDatatable() {
        return (
            <Row className="tableRow">
                <Col xs="12">
                    <DataTable canDeleteRow={true}
                        headerConfig={this.headerConfig}
                        data={this.state.fetchedData}
                        tools={[
                            { action: TableToolType.FULL_TEXT_SEARCH },
                            { action: TableToolType.SHOW_NUM_ROWS_DROPDOWN },
                            { action: TableToolType.FILTER_SEARCH },
                            { action: TableToolType.SYNC },
                            { action: TableToolType.ADD, form: HubConstant.HUB_ELEMENT_FORM },
                            { action: TableToolType.PAGE_DISPLAY },
                            { action: TableToolType.PAGINATION },
                            {
                                action: TableToolType.FILTER_BAR,
                                filters: [
                                    {
                                        type: DataTableConstant.HUB_FILTER_TYPE.MULTI_VAL_SELECTOR,
                                        title: "Production Centre",
                                        icon: faCity,
                                        availableFilterObjs: extractNodesFromData(this.props.nodes, this.props.hubNodeGroups),
                                        availableFilterGroups: extractNodeGroups(this.props.hubNodeGroups)
                                    },
                                    {
                                        type: DataTableConstant.HUB_FILTER_TYPE.MULTI_VAL_SELECTOR,
                                        title: "Category",
                                        icon: faListAlt,
                                        availableFilterObjs: extractCategories(this.props.metadata)
                                    },
                                    { type: DataTableConstant.HUB_FILTER_TYPE.CHECKBOX, title: "Urgent", icon: faExclamationTriangle }
                                ],
                                onChangeFilters: this.onChangeFilterBar
                            }
                        ]}
                        activeTableName={
                            { singular: `Media`, plural: `Media` }
                        }
                        dataAction={this.props.action}
                        isNotificationDisallowed={this.props.isNotificationAllowed}
                        authorizeService={authService}
                        {...this.props}></DataTable>
                </Col>
            </Row>
        )
    }

    render() {
        //DataTable happens during component will mount, make sure that data is fetched before table is rendered:
        return (
            <div className="Media">
                <Row className="structureRow">
                    <Col xs="12">
                        {(!!this.state.fetchedData) ? this.displayDatatable() : <Fragment key="0"></Fragment>}
                    </Col>
                </Row>
            </div>
        )
    }
}

Media.propTypes = {
    //nodes: PropTypes.arrayOf(PropTypes.object).isRequired,
    //columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    //objects: PropTypes.arrayOf(PropTypes.object).isRequired,
    objectType: PropTypes.number,
    nodeId: PropTypes.number,
    //onOpenForm: PropTypes.func.isRequired,
};


Media.defaultProps = {
    nodeId: 0
};


const mapHubObjectAndMetadataStatesToProps = (state) => {
    return {
        objectList: state.hubObjectContent.objectList,
        action: state.hubObjectContent.action,
        hubNodeGroups: state.hubObjectContent.hubNodeGroups,
        metadata: state.hubConfig.hubMetadata.metaData,
        nodes: state.hubConfig.nodeConfig.nodes
    }
}

export default withRouter(connect(
    mapHubObjectAndMetadataStatesToProps,
    dispatch => bindActionCreators({ ...mapDispatchToProps, ...mapDispatchToMetadataProps, ...mapDispatchToHubObjectBoardProps }, dispatch)
)(Media));